<template>
  <modal v-model:is-visible="isVisible">
    <template #header>
      {{ title }}
    </template>
    <template #body>
      {{ message }}
    </template>
    <template #footer>
      <atom-button @click="$emit('click-button')">
        {{ buttonLabel }}
      </atom-button>
    </template>
  </modal>
</template>

<script>
import i18n from "@/assets/i18n";

export default {
  name: "ErrorModal",
  props: {
    shows: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: i18n.global.tc("components.organisms.errorModal.modalTitle"),
    },
    message: {
      type: String,
      default: i18n.global.tc("components.organisms.errorModal.showMessage"),
    },
    buttonLabel: {
      type: String,
      default: i18n.global.tc(
        "components.organisms.errorModal.modalButtonLabel",
      ),
    },
  },
  emits: ["click-button", "update:shows", "close"],
  computed: {
    isVisible: {
      get() {
        return this.shows;
      },
      set(value) {
        this.$emit("update:shows", value);
        if (!value) {
          this.$emit("close", value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
