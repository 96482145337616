<template>
  <div v-if="isVisible" class="alert-board">
    <div class="alert-board__title">
      <span>
        <i class="icon__exclamation" />
        {{ title }}
      </span>
    </div>
    <div class="alert-board__body">
      {{ content }}
    </div>
    <div class="alert-board__close">
      <i class="icon__close" @click="onClickClose" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:is-visible"],
  methods: {
    onClickClose() {
      this.$emit("update:is-visible", false);
    },
  },
};
</script>
