export default {
  namespaced: true,
  state: {
    loadingCount: 0,
  },
  getters: {
    isLoading: (state) => {
      return state.loadingCount > 0;
    },
  },
  mutations: {
    increment: (state) => {
      state.loadingCount = state.loadingCount + 1;
    },
    decrement: (state) => {
      state.loadingCount = state.loadingCount - 1;
    },
  },
};
