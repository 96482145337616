export default {
  validate(value) {
    // emptyはエラーにする
    if (!value) {
      return false;
    }
    // 数値型の場合は文字列型に変換する
    let strValue = typeof value === "number" ? value.toString() : value;
    var regexp = new RegExp(/^[0-9]+$/);
    return regexp.test(strValue);
  },
};
