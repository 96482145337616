<template>
  <validation-wrapper
    v-slot="{ validationContext }"
    :has-focus="hasFocus"
    :rules="rules"
    :messages="messages"
    :name="placeholder || 'AtomSelect'"
    :value="modelValue"
  >
    <vue-select
      ref="vueSelect"
      :class="{
        'invalid-field':
          validationContext.errors.length > 0,
      }"
      :model-value="modelValue"
      :options="
        options.map((option) => {
          return { label: option.label, code: option.value };
        })
      "
      :reduce="(option) => option.code"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :searchable="searchable"
      style="width: 100%"
      @update:model-value="(e)=>{validationContext.handleChange(e);onInput(e);}"
      @focus="(e)=>{validationContext.handleChange(e);}"
      @search:focus="hasFocus = true"
      @search:blur="hasFocus = false"
    >
      <template #no-options>
        {{ $t("components.atoms.atomSelect.noOptions") }}
      </template>
    </vue-select>
  </validation-wrapper>
</template>

<script>
import every from "lodash/every";
import has from "lodash/has";
import VueSelect from "vue-select";

export default {
  name: "AtomSelect",
  components: { VueSelect },
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
      validator(value) {
        return every(
          value,
          (option) => has(option, "value") && has(option, "label"),
        );
      },
    },
    rules: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      hasFocus: false,
    };
  },
  mounted() {
    if (this.$refs.vueSelect.modelValue === undefined) {
      // props:modelValueで値が設定された場合、この時点で値が存在しているので、undefinedの場合のみ処理を行う。
      // 初期未選択でプルダウンを開いた場合、vee-validateのrequiedがOK扱いになるのを防ぐ為、クリアボタン処理実行
      this.$refs.vueSelect.clearSelection();
    }
  },
  methods: {
    onInput(e) {
      this.$emit("update:modelValue", e);
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/dist/vue-select.css";

.v-select {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #efefef;
  border-radius: 4px;
  font-size: 0.75rem;
  min-width: 200px;
  min-height: 32px;
}

.vs__dropdown-toggle {
  border: none;
  display: flex;
  padding-top: 3px;
  background: none;
  border-radius: 4px;
  white-space: normal;
}

.vs__dropdown-menu {
  top: calc(100% - 2px);
  font-size: 0.75rem;
  min-width: 200px;
}

.vs__dropdown-option {
  font-size: 0.75rem;
  white-space: unset;
  padding: 7px 6px 3px 22px;
}

.vs__search {
  color: #a2a2a2;
  font-size: 1em;
}

.vs__search:focus {
  font-size: 1em;
}

.vs__dropdown-option--highlight {
  background: #efefef;
  color: #5d5d5d;
}
</style>
