import Vue from "vue";
import { configure, defineRule, Form, Field, ErrorMessage } from "vee-validate";
import { localize, setLocale } from "@vee-validate/i18n";
import ja from "@vee-validate/i18n/dist/locale/ja.json";
import * as rules from "@vee-validate/rules";

import passwordCharacterTypes from "./validators/passwordCharacterTypes";
import email from "./validators/email";
import length from "./validators/length";
import numberIsSmaller from "./validators/numberIsSmaller";
import isSameHierarchyType from "./validators/isSameHierarchyType";
import match from "./validators/match";
import isDuplicateAnalyticHierarchyUnitCode from "./validators/isDuplicateAnalyticHierarchyUnitCode";
import isDuplicateOptionalItemUnitCode from "./validators/isDuplicateOptionalItemUnitCode";
import minv3 from "./validators/minv3";
import numericv3 from "./validators/numericv3";
import digitsv3 from "./validators/digitsv3";
import betweenv3 from "./validators/betweenv3";
import maxValuev3 from "./validators/maxValuev3";

// デフォルトのルールを登録
Object.keys(rules).forEach((rule) => {
  defineRule(rule, rules[rule]);
});
// 独自バリデーションを登録
defineRule("passwordCharacterTypes", passwordCharacterTypes.validate);
defineRule("email", email.validate);
defineRule("length", length.validate);
defineRule("numberIsSmaller", numberIsSmaller.validate);
defineRule("isSameHierarchyType", isSameHierarchyType.validate);
defineRule("match", match.validate);
defineRule(
  "isDuplicateAnalyticHierarchyUnitCode",
  isDuplicateAnalyticHierarchyUnitCode.validate,
);
defineRule(
  "isDuplicateOptionalItemUnitCode",
  isDuplicateOptionalItemUnitCode.validate,
);
// min挙動をveeValidate3と同挙動となる様に上書き
defineRule("min", minv3.validate);
defineRule("numeric", numericv3.validate);
defineRule("digits", digitsv3.validate);
defineRule("between", betweenv3.validate);
defineRule("max_value", maxValuev3.validate);

Vue.component("Field", Field)
Vue.component("VeeValidateForm", Form)

// 日本語
configure({
  // veeValidate3のValidationObserverに存在していたslot要素であるfailedRulesの代替手段としてエラーメッセージを出力。
  generateMessage:({ rule }) => { return rule.name; },
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
});
setLocale("ja");
