<template>
  <div v-if="isVisible" class="balloon">
    <div class="balloon__body">ストレスチェックは<br />お済みですか？</div>
    <div class="balloon__button">
      <a class="button--oval modal-action" href="#">START</a>
    </div>
    <div class="balloon__close">
      <i class="icon__close--green" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Balloon",
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
