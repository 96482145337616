import isEmpty from "lodash/isEmpty";

// 文字列長の範囲バリデーション
export default {
  params: ["min", "max"],
  message: "{min}以上{max}以下",
  validate(value, { min, max }) {
    if (isEmpty(value)) {
      return true;
    }
    const minValue = min || 1;
    const maxValue = max || Number.MAX_SAFE_INTEGER;
    return minValue <= value.length && value.length <= maxValue;
  },
};
