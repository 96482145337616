<template>
  <validation-wrapper
    v-slot="{ validationContext }"
    :has-focus="hasFocus"
    :rules="rules"
    :messages="messages"
    :name="placeholder || 'AtomInput'"
    :value="value"
  >
    <input
      :class="{
        'invalid-field': validationContext.errors.length > 0,
      }"
      :value="value"
      :type="type"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :autofocus="autoFocus"
      style="width: 100%; line-height: 15px"
      @input="(e)=>{validationContext.handleChange(e);onInput(e);}"
      @focus="(e)=>{validationContext.handleChange(e);onFocus(e);}"
      @touchstart="onFocus"
      @blur="hasFocus = false"
    />
  </validation-wrapper>
</template>

<script>
export default {
  name: "AtomInput",
  props: {
    value: {
      type: [String, Number, Date],
      default: undefined,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    rules: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Object,
      default: undefined,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input", "focusNotify"],
  data() {
    return {
      hasFocus: false,
    };
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
    onFocus(event) {
      this.hasFocus = true;
      if (this.readonly == true) {
        this.$emit("focusNotify");
        setTimeout(() => {
          var current = event.target;
          current.blur();
          current.focus();
        }, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
