<template>
  <div class="title--image">
    <i v-if="icon" :class="iconClass"></i>
    <slot />
  </div>
</template>

<script>
import includes from "lodash/includes";

export default {
  name: "StressCheckHeader",
  props: {
    icon: {
      type: String,
      default: undefined,
      validator(value) {
        return includes(["chart"], value);
      },
    },
  },
  computed: {
    iconClass() {
      const icon = this.icon;
      const classBase = `icon__${icon}`;
      switch (icon) {
        case "chart":
          return { [classBase]: true };
      }
      return {};
    },
  },
};
</script>
