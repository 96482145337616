<template>
  <div class="download-csv">
    <a v-if="!hidePersonalReportButton" @click="onClick">
      <i class="icon__download"></i>
      {{ buttonText }}
    </a>
    <completed-modal
      v-model:shows="showsCompletedModal"
      @click-button="onClickCompletedModalButton"
    >
      <template #title>
        <div></div>
      </template>
      <template #message>
        個人結果のPDFファイルをZIPファイルで一括ダウンロードします。<br />
        ※ZIPファイルの作成にはしばらく時間がかかります。<br />
        <br />
        作成するファイルの言語を選択してください。<br />
        <div id="download-setting-wrapper">
          <input
            id="language-ja"
            v-model="languageSetting"
            type="radio"
            value="ja"
          />
          <label for="language-ja"> 日本語 </label>
          <br />
          <input
            id="language-en"
            v-model="languageSetting"
            type="radio"
            value="en"
          />
          <label for="language-en"> 英語 </label>
        </div>
      </template>
      <template #button> OK </template>
    </completed-modal>
  </div>
</template>
<script>
export default {
  name: "PersonalReportButton",
  props: {
    buttonText: {
      type: String,
      default: "個人レポート一括ダウンロード",
    },
    encode: {
      type: String,
      default: "UTF8",
    },
    hidePersonalReportButton: {
      type: Boolean,
      default: true,
    },
    savePersonalReport: {
      type: Function,
      default: undefined,
    },
  },
  data() {
    return {
      showsCompletedModal: false,
      languageSetting: undefined,
    };
  },
  methods: {
    onClick() {
      this.languageSetting = "ja";
      this.showsCompletedModal = true;
    },
    async onClickCompletedModalButton() {
      await this.savePersonalReport(this.languageSetting);
      this.showsCompletedModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.button--circle {
  width: 175px;
}
a {
  text-decoration: none;
}
#download-setting-wrapper {
  margin: 1rem;
  line-height: 2;

  input[type="radio"] {
    margin-top: 0;
  }
  label {
    vertical-align: middle;
    font-weight: inherit;
    font-size: inherit;
  }
}
</style>
