<template>
  <ul>
    <li v-for="(option, index) in options" :key="index">
      <input
        :id="`${idPrefix}_${index}`"
        v-model="selectedValue"
        class="inline-input"
        type="radio"
        :value="option.value"
      />
      <label class="inline-label" :for="`${idPrefix}_${index}`">
        {{ option.label }}
      </label>
    </li>
  </ul>
</template>

<script>
import every from "lodash/every";
import has from "lodash/has";
import uniqueId from "lodash/uniqueId";

export default {
  name: "StressCheckButtons",
  props: {
    value: {
      type: Number,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      validator(value) {
        return every(
          value,
          (option) => has(option, "value") && has(option, "label"),
        );
      },
    },
  },
  emits: ["input"],
  data() {
    return {
      idPrefix: uniqueId("scb_"),
      selectedValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.selectedValue = newValue;
    },
    selectedValue(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-label {
  /* 通常時の明るさ(デフォルト値) */
  filter: brightness(1);
  transition: filter 0.3s;

  &:hover {
    /* マウスオーバー時に明るくする */
    filter: brightness(1.15);
  }

  &:focus {
    /* フォーカス時に明るくする */
    filter: brightness(1.15);
  }

  &:active {
    /* マウスダウン時に暗くする */
    filter: brightness(0.85);
  }
}
input[type="radio"].inline-input + .inline-label {
  min-width: 170px;
  margin: 0px 5px;
}
</style>
