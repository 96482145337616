<template>
  <section>
    <panel v-if="isHasPreviousReport">
      <!-- Btn load previous values -->
      <div id="load-previous-values-btn">
        <atom-button @click="showConfirmLoadModal = true">
          前回プロジェクト値で読み込み
        </atom-button>
      </div>

      <admin-project-report-setting-previous-load-messages-panel
        v-if="loadPreviousResponse !== undefined"
        class="mt-1"
        :messages="loadPreviousResponse.messages"
      />

      <modal v-model:is-visible="showConfirmLoadModal">
        <template #body>
          {{ loadPreviousModalText }}
          <br />よろしいですか？
        </template>
        <template #footer>
          <atom-button @click="loadPreviousValues">OK</atom-button>
          <atom-button @click="showConfirmLoadModal = false">
            キャンセル
          </atom-button>
        </template>
      </modal>
    </panel>

    <panel>
      <div id="downloadSetting-wrapper">
        <p>CSVファイル種別</p>
        <div>
          <input
            id="DOWNLOAD_ANALYSIS_HIERARCHY"
            v-model="downloadSettingType"
            type="radio"
            value="ANALYSIS_HIERARCHY"
          />
          <label for="DOWNLOAD_ANALYSIS_HIERARCHY">分析階層</label>
        </div>
        <atom-no-validate-select
          v-if="isShowDepthSelectBox"
          :model-value="downloadDepthOption"
          :clearable="false"
          :options="depthOptions"
          :disabled="
            downloadSettingType !== 'ANALYSIS_HIERARCHY' ||
            downloadSettingType === undefined
          "
          class="dept-select"
          @update:model-value="downloadDepthOption = $event"
        />
        <br />
        <div>
          <input
            id="DOWNLOAD_OPTIONAL"
            v-model="downloadSettingType"
            type="radio"
            value="SELECT_OPTION"
          />
          <label for="DOWNLOAD_OPTIONAL">
            {{ downloadUploadOptionalLabel }}
          </label>
        </div>
      </div>

      <!-- Btn group download Other Setting CSV -->
      <div id="download-btn-group">
        <atom-button
          :disabled="!downloadSettingType"
          @click="downloadCsv(false)"
        >
          CSVファイルダウンロード
        </atom-button>
      </div>

      <admin-project-report-setting-messages-panel
        v-if="downloadResponse !== undefined"
        class="mt-1"
        :title="downloadResponse.title"
        :messages="downloadResponse.messages"
        :type="downloadResponse.status"
      />
    </panel>

    <panel>
      <div id="uploadSetting-wrapper">
        <p>CSVファイル種別</p>
        <div>
          <input
            id="UPLOAD_ANALYSIS_HIERARCHY"
            v-model="uploadSettingType"
            type="radio"
            value="ANALYSIS_HIERARCHY"
          />
          <label for="UPLOAD_ANALYSIS_HIERARCHY">分析階層</label>
        </div>
        <atom-no-validate-select
          v-if="isShowDepthSelectBox"
          :model-value="uploadDepthOption"
          :clearable="false"
          :options="depthOptions"
          :disabled="uploadSettingType !== 'ANALYSIS_HIERARCHY'"
          class="dept-select"
          @update:model-value="uploadDepthOption = $event"
        />
        <br />
        <div>
          <input
            id="UPLOAD_OPTIONAL"
            v-model="uploadSettingType"
            type="radio"
            value="SELECT_OPTION"
          />
          <label for="UPLOAD_OPTIONAL">
            {{ downloadUploadOptionalLabel }}
          </label>
        </div>
      </div>

      <!-- Btn Choose File CSV -->
      <div id="uploadCSV-wrapper">
        <p>アップロードするファイルを選択してください。</p>
        <input
          ref="fileUpload"
          type="file"
          accept=".csv"
          @change="onChangeFile"
        />
      </div>

      <!-- Button Upload CSV -->
      <div id="upload-btn-group">
        <atom-button
          :disabled="!csvFile || !uploadSettingType"
          @click="showConfirmModal = true"
        >
          CSVファイルアップロード
        </atom-button>
      </div>

      <admin-project-report-setting-messages-panel
        v-if="uploadResponse !== undefined"
        class="mt-1"
        :title="uploadResponse.title"
        :messages="uploadResponse.messages"
        :type="uploadResponse.status"
      />

      <modal v-model:is-visible="showConfirmModal">
        <template #body>
          ファイルをアップロードします。
          <br />よろしいですか？
        </template>
        <template #footer>
          <atom-button @click="onClickUpload">OK</atom-button>
          <atom-button @click="showConfirmModal = false">
            キャンセル
          </atom-button>
        </template>
      </modal>
    </panel>
  </section>
</template>

<script>
import { readCsvFile } from "@/helpers/FileReadHelper";
import { saveAs } from "file-saver";
import { CsvDownloadHelper } from "@/helpers/CsvDownloadHelper";
import { mapGetters, mapState } from "vuex";

export default {
  name: "AdminProjectReportCSVDownloadUpload",
  data() {
    return {
      downloadSettingType: "",
      uploadSettingType: "",
      csvFile: undefined,
      showConfirmModal: false,
      showConfirmLoadModal: false,
      loadPreviousResponse: undefined,
      loadPreviousModalText: "前回プロジェクト値を読み込みます。",
      downloadUploadOptionalLabel: "任意項目・役職・事業場",
      downloadResponse: undefined,
      uploadResponse: undefined,
      depthOptions: [],
      downloadDepthOption: undefined,
      uploadDepthOption: undefined,
    };
  },
  computed: {
    ...mapGetters("fieldSelection", ["isHasPreviousReport"]),
    ...mapState("fieldSelection", ["report", "project"]),
    previousReportCode() {
      return this.report.previousReportCode;
    },
    isUpdating() {
      return this.report.isUpdateReport;
    },
    projectTreeStructures() {
      return this.project.treeStructures;
    },
    isShowDepthSelectBox() {
      return this.$route.name === "report_sort_order";
    },
  },
  watch: {
    projectTreeStructures() {
      this.depthOptions = [];
      this.downloadDepthOption = undefined;
      this.uploadDepthOption = undefined;
      if (this.$route.name === "report_sort_order") {
        this.depthOptions = this.buildDeptSelectOption();
        this.downloadDepthOption = 1;
        this.uploadDepthOption = 1;
      }
    },
  },
  created() {
    this.depthOptions = [];
    this.downloadDepthOption = undefined;
    this.uploadDepthOption = undefined;
    if (this.$route.name === "report_sort_order") {
      this.depthOptions = this.buildDeptSelectOption();
      this.downloadDepthOption = 1;
      this.uploadDepthOption = 1;
    } else if (this.$route.name === "report_comparison") {
      this.loadPreviousModalText =
        "現在の設定をクリアし前回プロジェクト値を読み込みます。";
      this.downloadUploadOptionalLabel = "任意項目・役職・事業場・追加設問";
    }
  },
  methods: {
    loadPreviousValues() {
      this.errorLoadMessages = [];
      this.showConfirmLoadModal = false;
      this.$api
        .post(
          `/admin/projects/${this.$route.params.projectCode}/${this.$route.name}/load_previous_values`,
        )
        .then((response) => {
          if (response && response.data.status !== "ERROR") {
            alert("前回プロジェクト値の読み込みが成功しました。");
          }

          this.loadPreviousResponse = response.data;
        })
        .catch(() => {
          alert("前回プロジェクト値の読み込みに失敗しました。");
        });
    },
    async downloadCsv(previous) {
      try {
        let params = {
          settingType: this.downloadSettingType,
          previous: previous,
        };

        if (
          this.isShowDepthSelectBox &&
          this.downloadSettingType === "ANALYSIS_HIERARCHY"
        ) {
          params.depth = this.downloadDepthOption;
        }

        const response = await this.$api.get(
          `/admin/projects/${this.$route.params.projectCode}/${this.$route.name}/download`,
          {
            params: params,
          },
        );
        let fileName, prefix;
        const date = new Date();
        const dateStr =
          date.getFullYear() +
          ("00" + (date.getMonth() + 1)).slice(-2) +
          ("00" + date.getDate()).slice(-2) +
          "_" +
          ("00" + date.getHours()).slice(-2) +
          ("00" + date.getMinutes()).slice(-2);

        if (this.$route.name) {
          if (this.$route.name === "report_unit_code") {
            prefix = "unitcode_";
          } else if (this.$route.name === "report_sort_order") {
            prefix = "sort_";
          } else if (this.$route.name === "report_english") {
            prefix = "english_";
          } else if (this.$route.name === "report_comparison") {
            prefix = "comparison_";
          }
        }
        if (prefix) {
          fileName =
            prefix +
            (this.downloadSettingType === "ANALYSIS_HIERARCHY"
              ? "organizasion_setting_" + dateStr + ".csv"
              : "other_setting_" + dateStr + ".csv");
        } else {
          fileName =
            this.downloadSettingType === "ANALYSIS_HIERARCHY"
              ? "分析階層.csv"
              : "任意項目・役職・事業場.csv";
        }

        const blob = CsvDownloadHelper.convertToBlobWithBom(response.data);
        saveAs(blob, fileName);
      } catch (error) {
        // 何もしない
      }
    },
    onClickUpload() {
      this.errorMessages = [];
      this.showConfirmModal = false;

      const formData = new FormData();
      formData.append("fileName", this.csvFile.name);
      formData.append("fileContent", this.csvFile);
      formData.append("settingType", this.uploadSettingType);

      if (
        this.isShowDepthSelectBox &&
        this.uploadSettingType === "ANALYSIS_HIERARCHY"
      ) {
        formData.append("depth", this.uploadDepthOption);
      }

      this.$api
        .post(
          `/admin/projects/${this.$route.params.projectCode}/${this.$route.name}/upload`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        )
        .then((response) => {
          if (response && response.data.status !== "ERROR") {
            alert("CSVアップロードが成功しました。");
          } else {
            alert("CSVアップロード中にエラーが発生しました。");
          }

          window.removeEventListener("beforeunload", this.onBeforeUnload);
          this.$store.dispatch("fieldSelection/updatingReport", false);
          this.uploadResponse = response.data;

          if (!this.uploadResponse.title) {
            this.uploadResponse.title = this.csvFile.name + "" + this.title;
          }
          this.csvFile = undefined;
          this.$refs.fileUpload.value = null;
        })
        .catch(() => {
          alert("ファイルのアップロードに失敗しました。");
          this.csvFile = undefined;
          this.$refs.fileUpload.value = null;
          window.removeEventListener("beforeunload", this.onBeforeUnload);
          this.$store.dispatch("fieldSelection/updatingReport", false);
        });
    },
    onChangeFile(event) {
      readCsvFile(event.target.files[0]).then((file) => {
        this.$store.dispatch("fieldSelection/updatingReport", true);
        window.addEventListener("beforeunload", this.onBeforeUnload);

        this.csvFile = file;
      });
    },
    onBeforeUnload(e) {
      if (!this.confirmLeave()) {
        e.preventDefault();
        e.returnValue =
          "CSVファイルアップロードボタンを押していない場合、変更が反映されませんがよろしいでしょうか。";
      }
    },
    confirmLeave() {
      return (
        this.isUpdating &&
        window.confirm(
          "CSVファイルアップロードボタンを押していない場合、変更が反映されませんがよろしいでしょうか。",
        )
      );
    },
    buildDeptSelectOption() {
      let options = this.projectTreeStructures.map((item, index) => {
        return {
          value: index + 1,
          label: item.Label ? item.Label : item.DefaultLabel,
        };
      }, []);
      return options;
    },
    selectUploadDeptOption(val) {
      this.uploadDepthOption = val;
    },
    selectDownloadDeptOption(val) {
      this.downloadDepthOption = val;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.fade-enter-active,
.fade-leave-active {
  transition: height 1s linear;
}

#downloadSetting-wrapper,
#uploadSetting-wrapper {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 1rem 0;
  padding: 0 1rem 1rem;

  input[type="radio"],
  label {
    vertical-align: middle;
  }

  > div {
    display: inline;
  }

  .dept-select {
    width: 200px !important;
    display: inline-flex;
  }
}

#load-previous-values-btn,
#download-btn-group,
#upload-btn-group {
  margin-top: 15px;

  button {
    width: calc((100% / 2) - 5px);

    &:first-child {
      margin-right: 10px;
    }
  }
}
</style>
