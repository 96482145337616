<template>
  <div class="board__button">
    <atom-button
      v-if="showLeft"
      :is-primary="false"
      icon="arrow"
      icon-position="left"
      @click="onClickLeft"
    >
      <slot name="left"></slot>
    </atom-button>
    <atom-button
      v-if="showRight"
      :is-submit="true"
      icon="arrow"
      icon-position="right"
      @click="onClickRight"
    >
      <slot name="right"></slot>
    </atom-button>
  </div>
</template>

<script>
export default {
  name: "StressCheckFooterButtons",
  props: {
    showLeft: {
      type: Boolean,
      default: false,
    },
    onClickLeft: {
      type: Function,
      default: undefined,
    },
    showRight: {
      type: Boolean,
      default: true,
    },
    onClickRight: {
      type: Function,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.button--circle,
.button--circle--white {
  width: 220px;
  height: auto;
  word-break: break-all;
  padding-left: 23px;
  padding-right: 23px;
}
@media only screen and (max-width: 767px) {
  .button--circle {
    font-size: 14px;
    font-size: 0.875rem;
  }

  .button--circle--white {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
</style>
