export default {
  namespaced: true,
  state: {
    project: {
      treeStructures: [],
    },
    report: {
      isUpdateReport: false,
      fieldSelected: undefined,
      previousReportCode: "",
    },
  },
  getters: {
    isSelectCSVFieldSelection: (state) => {
      return state.report.fieldSelected && state.report.fieldSelected === "CSV";
    },
    isSelectOtherFieldSelection: (state) => {
      return state.report.fieldSelected && state.report.fieldSelected !== "CSV";
    },
    isHasPreviousReport: (state) => {
      return !!state.report.previousReportCode;
    },
  },
  mutations: {
    setFieldSelection: (state, payload) => {
      state.report.fieldSelected = payload;
    },
    setIsUpdateReport: (state, payload) => {
      state.report.isUpdateReport = payload;
    },
    setPreviousReportCode: (state, payload) => {
      state.report.previousReportCode = payload;
    },
    setProjectTreeStructures: (state, payload) => {
      state.project.treeStructures = payload;
    },
  },
  actions: {
    selectFieldSelection: (context, fieldSelection) => {
      context.commit("setFieldSelection", fieldSelection);
    },
    updatingReport: (context, isUpdating) => {
      context.commit("setIsUpdateReport", isUpdating);
    },
    addPreviousReportCode: (context, previousReportCode) => {
      context.commit("setPreviousReportCode", previousReportCode);
    },
    addProjectTreeStructures: (context, projectTreeStructures) => {
      context.commit("setProjectTreeStructures", projectTreeStructures);
    },
  },
};
