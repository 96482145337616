<template>
  <span class="required">
    *{{ $t("components.molecules.requiredMark.required") }}
  </span>
</template>

<script>
export default {
  name: "RequiredMark",
};
</script>

<style lang="scss" scoped>
.required {
  font-size: 0.625rem;
  color: #ef5b5b;
  margin: 0.25rem;
  min-width: 30px;
  white-space: nowrap;
}
</style>
