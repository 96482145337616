const USE_GROUP_LOGIN = "useGroupLogin";
const USE_EAP_LOGIN = "useEapOnlyLogin";
const USE_SSO_LOGIN = "useSsoLogin";

/**
 * 団体ログイン経由でログインした際に呼び出す
 * LocalStorageにログイン経路を保持
 */
export function useAccountLogin() {
  localStorage.setItem(USE_GROUP_LOGIN, false);
  localStorage.setItem(USE_EAP_LOGIN, false);
}

/**
 * グループログイン経由でログインした際に呼び出す
 * LocalStorageにログイン経路を保持
 */
export function useGroupLogin() {
  localStorage.setItem(USE_GROUP_LOGIN, true);
  localStorage.setItem(USE_EAP_LOGIN, false);
}

/**
 * EAP専用ログイン経由でログインした際に呼び出す
 * LocalStorageにログイン経路を保持
 */
export function useEapOnlyLogin() {
  localStorage.setItem(USE_GROUP_LOGIN, false);
  localStorage.setItem(USE_EAP_LOGIN, true);
}

/**
 * SSO専用ログイン経由でログインした際に呼び出す
 * LocalStorageにログイン経路を保持
 */
export function useSsoLogin() {
  localStorage.setItem(USE_SSO_LOGIN, true);
}

/**
 * SSO以外でユーザーログインした際に呼び出す
 * LocalStorageのSSOログイン経路を破棄
 */
export function setFalseSsoLogin() {
  localStorage.setItem(USE_SSO_LOGIN, false);
}

/**
 * LocalStorageからログイン経路を取得し、グループログインかを判定
 */
export function isUseGroupLogin() {
  return localStorage.getItem(USE_GROUP_LOGIN) === "true";
}

/**
 * LocalStorageからログイン経路を取得し、EAP専用ログインかを判定
 */
export function isUseEapOnlyLogin() {
  return localStorage.getItem(USE_EAP_LOGIN) === "true";
}

/**
 * LocalStorageからログイン経路を取得し、SSO専用ログインかを判定
 */
export function isUseSsoLogin() {
  return localStorage.getItem(USE_SSO_LOGIN) === "true";
}
