<template>
  <button
    :disabled="disabled"
    :class="buttonClass"
    :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
    :type="isSubmit ? 'submit' : 'button'"
    @click="$emit('click')"
    @click.stop="$emit('click-stop', $event)"
  >
    <slot />
    <i v-if="icon" :class="iconClass" />
  </button>
</template>

<script>
import includes from "lodash/includes";

export default {
  name: "AtomButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "circle",
      validator(value) {
        return includes(["circle", "oval", "square"], value);
      },
    },
    icon: {
      type: String,
      default: undefined,
      validator(value) {
        return includes(["arrow", "external"], value);
      },
    },
    iconPosition: {
      type: String,
      default: "right",
      validator(value) {
        return includes(["right", "left"], value);
      },
    },
    isSubmit: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click", "click-stop"],
  computed: {
    buttonClass() {
      const classBase = `button--${this.type}`;
      if (this.disabled) {
        return { [`${classBase}--gray`]: true };
      } else if (!this.isPrimary) {
        return { [`${classBase}--white`]: true };
      }
      if (this.isDanger) {
        return { [`${classBase} danger`]: true };
      }
      return { [classBase]: true };
    },
    iconClass() {
      const icon = this.icon;
      const classBase = `icon__${icon}`;
      switch (icon) {
        case "arrow":
          switch (this.iconPosition) {
            case "right":
              if (!this.isPrimary) {
                return { [`${classBase}-right--green`]: true };
              }
              return { [`${classBase}-right`]: true };
            case "left":
              if (!this.isPrimary) {
                return { [`${classBase}-left--green`]: true };
              }
              return { [`${classBase}-left`]: true };
          }
          break;
        case "external":
          if (!this.isPrimary) {
            return { [classBase]: true };
          }
          return { [`${classBase}--white`]: true };
      }
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 767px) {
  .button--circle {
    font-size: 1em;
  }
}
</style>
