<template>
  <validation-wrapper
    v-slot="{ validationContext }"
    :has-focus="hasFocus"
    :rules="rules"
    :messages="messages"
    :name="placeholder || 'AtomTextarea'"
    :value="value"
  >
    <div style="line-height: 1">
      <textarea
        v-model="text"
        :class="{
          'invalid-field': validationContext.errors.length > 0,
        }"
        :cols="cols"
        :rows="rows"
        :disabled="disabled"
        :placeholder="placeholder"
        style="height: 100%; width: 100%"
        @focus="(e)=>{validationContext.handleChange(e);hasFocus = true;}"
        @input="(e)=>{validationContext.handleChange(e);}"
        @blur="hasFocus = false"
      />
    </div>
  </validation-wrapper>
</template>

<script>
export default {
  name: "AtomTextarea",
  props: {
    value: {
      type: String,
      default: undefined,
    },
    cols: {
      type: Number,
      default: 30,
    },
    rows: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    rules: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Object,
      default: undefined,
    },
  },
  emits: ["input"],
  data() {
    return {
      text: this.value,
      hasFocus: false,
    };
  },
  watch: {
    value(newValue) {
      this.text = newValue;
    },
    text(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
