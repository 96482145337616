// Email形式のバリデーター(SalesForceが許可するメールアドレスに準拠)
export default {
  validate(value) {
    if (value == null) {
      // NULLの場合、バリデート対象外
      return true;
    }

    // メールアドレス形式チェック
    return /^[a-zA-Z0-9.!#$%&'*/=?^_+\-`{|}~]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]+$/g.test(
      value,
    );
  },
};
