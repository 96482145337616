<template>
  <div class="mt30 textarea">{{ message }}</div>
</template>

<script>
import { isUseEapOnlyLogin, isUseGroupLogin } from "@/helpers/LoginRoute";
export default {
  name: "Templates",
  props: {
    enableOnlyEapWhenGroupLogin: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      message: undefined,
    };
  },
  async created() {
    try {
      const endpoint =
        isUseEapOnlyLogin() ||
        (isUseGroupLogin() && !this.enableOnlyEapWhenGroupLogin)
          ? "account/public/login-message/eap-only"
          : "account/public/login-message";
      const response = await this.$api.get(endpoint);
      this.message = response.data.message;
    } catch (error) {
      // 何もしない
    }
  },
};
</script>

<style scoped>
.textarea {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.7;
  font-size: 14px;
}
</style>
