<template>
  <div
    :class="{ progressbar: !isLarge, 'progressbar--large': isLarge }"
    :data-progress="value"
  >
    <div class="progressbar-line" :style="{ width: value + '%' }" />
  </div>
</template>

<script>
export default {
  name: "AtomProgessBar",
  props: {
    value: {
      type: Number,
      required: true,
      validator(value) {
        return 0 <= value && value <= 100;
      },
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
