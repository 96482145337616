<template>
  <li
    ref="dropdown"
    class="dropdown"
    :class="{ dropup: top }"
    @mouseleave="mouseLeave"
    @mouseover="mouseOver"
    @mouseenter="mouseEnter"
    @click="toggleMenu"
  >
    <slot></slot>
    <transition :name="transition">
      <div
        v-show="value"
        ref="dropdownMenu"
        class="dropdown-menu child__menu"
        :class="{ show: value, 'dropdown-menu-right': right }"
        :style="styles"
        @mouseleave="startTimer"
        @mouseenter="stopTimer"
        @click.stop="clickDropdownMenu"
      >
        <slot name="dropdown"></slot>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  name: "DropdownMenu",
  props: {
    value: Boolean,
    right: Boolean,
    hover: Boolean,
    hoverTime: {
      type: Number,
      default: 0,
    },
    hoverTimeout: {
      type: Number,
      default: 100,
    },
    interactive: {
      //whether should stay open until clicked outside
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: "",
    },
    menuState: {
      type: String,
      default: undefined,
    },
  },
  emits: ["input"],
  data() {
    return {
      hovering: false,
      top: false,
      hoverOpenTimer: null,
      styles: {
        top: 0,
        bottom: "auto",
      },
      isRendered: false,
      screenClick: null,
    };
  },
  watch: {
    value(v) {
      if (v) {
        let vm = this;
        vm.screenClick = null;
        this.$nextTick(() => {
          vm.resetPositionMenu();
        });
      }
    },
    interactive: {
      handler(value) {
        if (typeof document === "object") {
          value
            ? document.body.addEventListener("click", this.closeMenu)
            : document.body.removeEventListener("click", this.closeMenu);
        }
      },
      immediate: true,
    },
    menuState(v) {
      if (v) {
        this.isRendered = false;
      }
    },
  },
  updated() {
    let vm = this;
    if (vm.value && vm.menuState && !vm.isRendered) {
      vm.$nextTick(() => {
        vm.resetPositionMenu();
      });
    }
  },
  unmounted() {
    document.body.removeEventListener("click", this.closeMenu);
  },
  methods: {
    mouseEnter() {
      this.stopTimer();
      if (this.hover && this.hoverTime > 0 && !this.value) {
        this.hoverOpenTimer = setTimeout(() => {
          this.$emit("input", true);
          //disable for a moment
          this.hovering = true;
          setTimeout(() => {
            this.hovering = false;
          }, this.hoverTimeout);
        }, this.hoverTime);
      }

      if (this.hover && !this.value && this.hoverTime === 0) {
        this.hovering = true;
        setTimeout(() => {
          this.hovering = false;
        }, this.hoverTimeout);
        this.$emit("input", true);
      }
    },
    mouseLeave() {
      if (!this.hoverTimer) {
        //left the link and no time active
        this.startTimer();
      }

      if (this.hoverTime > 0 && this.hover) {
        clearTimeout(this.hoverOpenTimer);
      }
    },
    mouseOver() {
      this.stopTimer();
    },
    closeMenu($event) {
      if (!$event || !this.$el.contains($event.target)) {
        if (this.value) {
          this.$emit("input", false);
        }
      }
    },
    toggleMenu() {
      if (this.hovering) {
        return;
      }
      if (this.value && this.hover) {
        return;
      }
      this.$emit("input", !this.value);
    },
    stopTimer() {
      clearTimeout(this.hoverTimer);
      this.hoverTimer = null;
    },
    startTimer() {
      if (!this.interactive)
        this.hoverTimer = setTimeout(this.closeMenu, this.hoverTimeout);
    },
    clickDropdownMenu(event) {
      this.screenClick = event.clientY;
    },
    resetPositionMenu() {
      let vm = this;
      setTimeout(() => {
        let window_height =
          window.innerHeight > document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.innerHeight;
        let menu = vm.$refs.dropdown.getBoundingClientRect();
        let rect = vm.$refs.dropdownMenu.getBoundingClientRect();
        let expectedTop = 0;
        const currentHeight = rect.height;
        const menuTop = menu.top + 2;
        const expectedRectHeight = window_height - menuTop - 10;
        if (currentHeight > expectedRectHeight) {
          // expectedTop = different of currentHeight and expectedRectHeight
          expectedTop = expectedRectHeight - currentHeight;
        }
        if (
          !vm.screenClick ||
          (menuTop + expectedTop < vm.screenClick &&
            rect.height > window_height - rect.top - 10)
        ) {
          vm.styles = {
            top: expectedTop + "px",
            bottom: "auto",
          };
        }
        vm.isRendered = true;
      }, 200);
    },
  },
};
</script>
<style>
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: normal;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 100%;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  max-height: 72vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
</style>
