<template>
  <modal v-model:is-visible="isVisible">
    <template #header>
      <slot v-if="$slots.title" name="title" />
      <template v-else>
        {{ title }}
      </template>
    </template>
    <template #body>
      <slot />
    </template>
    <template #footer>
      <atom-button @click="$emit('click-ok-button')">
        {{ okButtonLabel }}
      </atom-button>
      <atom-button
        v-if="cancelButtonLabel"
        :is-primary="false"
        @click="$emit('click-cancel-button')"
      >
        {{ cancelButtonLabel }}
      </atom-button>
    </template>
  </modal>
</template>

<script>
import i18n from "@/assets/i18n";

export default {
  name: "ConfirmModal",
  props: {
    shows: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: i18n.global.tc("components.organisms.confirmModal.modalTitle"),
    },
    okButtonLabel: {
      type: String,
      default: i18n.global.tc(
        "components.organisms.confirmModal.modalOkButtonLabel",
      ),
    },
    cancelButtonLabel: {
      type: String,
      default: i18n.global.tc(
        "components.organisms.confirmModal.modalCancelButtonLabel",
      ),
    },
  },
  emits: ["click-ok-button", "click-cancel-button", "update:shows", "close"],
  computed: {
    isVisible: {
      get() {
        return this.shows;
      },
      set(value) {
        this.$emit("update:shows", value);
        if (!value) {
          this.$emit("click-cancel-button");
          this.$emit("close", value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
