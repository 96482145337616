import { createI18n } from "vue-i18n";
import messages from "./messages";

const defaultLocale = (() => {
  switch (
    (
      window.navigator.userLanguage ||
      window.navigator.language ||
      window.navigator.browserLanguage
    ).substr(0, 2)
  ) {
    case "ja":
      return "ja";
    case "en":
      return "en";
    case "zh":
      return "zh";
    default:
      return "ja";
  }
})();
const i18n = createI18n({
  locale: defaultLocale,
  fallbackLocale: "ja",
  messages,
});

export default i18n;
