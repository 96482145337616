<template>
  <div class="board">
    <h2 class="heading--tertiary">{{ title }}</h2>
    <h2 v-if="question" class="heading--tertiary">{{ question }}</h2>
    <div class="board__body">
      <slot />
    </div>
    <div v-if="hasFooter" class="border--light" />
    <slot v-if="hasFooter" name="footer" />
  </div>
</template>

<script>
export default {
  name: "Board",
  props: {
    title: {
      type: String,
      required: true,
    },
    hasFooter: {
      type: Boolean,
      default: false,
    },
    question: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.board__body {
  font-size: 0.75rem;
}
</style>
