<template>
  <footer class="user-footer">
    <ul class="footer-link">
      <!-- 動作環境について -->
      <li class="footer-link__item">
        <a
          href="https://www.peacemind.co.jp/service/system-requirements"
          target="_blank"
        >
          {{ $t("components.organisms.userFooter.environment") }}
        </a>
      </li>
      <!-- 個人情報の取扱いについて -->
      <li class="footer-link__item">
        <a
          v-show="$i18n.locale == `ja`"
          id="privacyPolicy"
          href="https://www.peacemind.co.jp/privacy_policy"
          target="_blank"
        >
          {{ $t("components.organisms.userFooter.personalInformation") }}
        </a>
        <a
          v-show="$i18n.locale == `en`"
          id="privacyPolicy"
          href="https://www.peacemind.co.jp/en/privacy_policy"
          target="_blank"
        >
          {{ $t("components.organisms.userFooter.personalInformation") }}
        </a>
        <a
          v-show="$i18n.locale == `zh`"
          id="privacyPolicy"
          href="https://www.peacemind.co.jp/en/privacy_policy"
          target="_blank"
        >
          {{ $t("components.organisms.userFooter.personalInformation") }}
        </a>
      </li>
      <!-- 情報セキュリティ基本方針 -->
      <li class="footer-link__item">
        <a
          href="https://www.peacemind.co.jp/company/security_policy"
          target="_blank"
        >
          {{ $t("components.organisms.userFooter.informationSecurity") }}
        </a>
      </li>
      <!-- 利用規約 -->
      <li class="footer-link__item">
        <router-link
          :to="{ path: '/static/terms_of_use_' + locale }"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ $t("components.organisms.userFooter.termsOfUse") }}
        </router-link>
      </li>
    </ul>
    <ul class="footer-link">
      <!-- 運営者 -->
      <li
        v-if="$store.getters['auth/isAccountLoggedIn']"
        class="footer-link__item"
      >
        <a href="https://www.peacemind.co.jp/" target="_blank">
          {{ $t("components.organisms.userFooter.administrator") }}
        </a>
      </li>
    </ul>
    <div class="copyright">
      <small> &copy; PEACEMIND Inc. All rights reserved. </small>
    </div>
  </footer>
</template>

<script>
export default {
  name: "UserFooter",
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss" scoped></style>
