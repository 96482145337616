import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowUp,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faTimes,
  faCaretDown,
  faCaretUp,
  faAngleUp,
  faAngleDown,
  faPlus,
  faUnlockAlt,
  faTrashAlt,
  faArrowsAltV,
  faBook,
  faNewspaper,
  faTasks,
  faChild,
  faInfoCircle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faArrowDown,
  faArrowUp,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faTimes,
  faCaretDown,
  faCaretUp,
  faAngleUp,
  faAngleDown,
  faPlus,
  faUnlockAlt,
  faTrashAlt,
  faArrowsAltV,
  faBook,
  faNewspaper,
  faTasks,
  faChild,
  faInfoCircle,
  faExclamationCircle,
);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);
