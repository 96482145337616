<template>
  <header class="user-header">
    <div class="user-header__text">
      ストレスチェックシステム&emsp;マークシート管理
    </div>
    <div class="user-info">
      <div style="text-align: right">
        <atom-button class="button--circle-text2" @click="onClickLogout">
          ログアウト
        </atom-button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "MarkSheetHeader",
  computed: {
    ...mapState("auth", ["loginUser", "loading"]),
  },
  methods: {
    onClickLogout() {
      const accountHash = this.loginUser.accountHash;
      this.$api
        .post("logout")
        .then(() => this.$router.push(`/logout/${accountHash}`));
    },
  },
};
</script>
