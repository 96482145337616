<template>
  <div>
    <user-breadcrumb :hierarchy="hierarchy" />
    <div class="widget">
      <!-- タイトル -->
      <div v-if="contents.headerName" class="title--image">
        {{ contents.headerName.base }}
      </div>
      <div class="board large">
        <!-- コンテンツヘッダ -->
        <span
          v-if="contents.headerBody"
          class="contents-body"
          v-html="contents.headerBody.base"
        ></span>
        <!-- コンテンツボディセレクト -->
        <div v-if="isVisibleContentsSelect" class="contents-select">
          <atom-select
            :model-value="input.contentsId"
            :options="contentsSelectOptions"
            @update:model-value="reloadContents"
          >
          </atom-select>
        </div>
        <hr class="contents-separator" />
        <!-- コンテンツボディ -->
        <div class="column_header">
          <span
            v-if="contents.contentsTitle"
            v-html="contents.contentsTitle"
          ></span>
        </div>
        <span
          v-if="contents.contentsBody"
          class="contents-body"
          v-html="contents.contentsBody.base"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";

export default {
  name: "Contents",
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      input: {
        contentsId: null,
      },
      contentsHeaderCode: undefined,
      contents: {
        headerName: undefined,
        headerBody: undefined,
        contentsTitle: undefined,
        contentsBody: undefined,
      },
      contentsSelectOptionsData: [],
      contentsSelectOptions: [],
      isVisibleContentsSelect: undefined,
      hierarchy: [
        {
          display: undefined,
        },
      ],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.setContentsForLocale();
      this.setContentsSelectOptions();
    },
    $route(to) {
      // 同一ルートでparamだけ変更された場合
      this.contentsHeaderCode = to.params.contentsHeaderCode;
      this.input.contentsId = null;
      this.initialize();
    },
  },
  async created() {
    this.contentsHeaderCode = this.$route.params.contentsHeaderCode;
    this.isVisibleContentsSelect = this.contentsHeaderCode !== "SUP003";
    this.initialize();
  },
  methods: {
    initialize() {
      Promise.all([this.searchAPI(), this.fetchSelectOptions()]).then(
        ([searched, selectOptions]) => {
          this.contents = searched.data;
          this.input.contentsId = searched.data.id;
          this.contentsSelectOptionsData = selectOptions.data;
          this.setContentsForLocale();
          this.setContentsSelectOptions();
          this.addColumnViewHistory();
        },
      );
    },
    searchAPI() {
      return this.$api
        .get("contents", {
          params: {
            category: this.category,
            contentsHeaderCode: this.contentsHeaderCode,
            id: this.input.contentsId,
          },
        })
        .catch((e) => alert(e.response.data.message));
    },
    reloadContents(event) {
      this.input.contentsId = event;
      this.searchAPI().then((response) => {
        this.contents = response.data;
        this.setContentsForLocale();
        this.addColumnViewHistory();
      });
    },
    fetchSelectOptions() {
      return this.$api
        .get("contents/select_option", {
          params: {
            contentsHeaderCode: this.contentsHeaderCode,
          },
        })
        .catch((e) => alert(e.response.data.message));
    },
    addColumnViewHistory() {
      const contentsDetail = this.contentsSelectOptionsData.filter(
        (option) => option.id == this.contents.id,
      )[0];
      this.$api.post("contents/add_column_view_history", {
        category: this.category,
        contentsHeaderCode: this.contentsHeaderCode,
        contentsNo: contentsDetail.contentsNo,
      });
    },
    setContentsSelectOptions() {
      this.contentsSelectOptions = map(this.contentsSelectOptionsData, (e) => {
        let title = "";
        switch (this.$i18n.locale) {
          case "ja":
            title = e.title;
            break;
          case "en":
            title = e.titleEn;
            break;
          default:
            title = e.title;
            break;
        }
        let label = "--";
        if (!isEmpty(title)) {
          label =
            this.category == "TOPICS"
              ? `No.${e.contentsNo} (${e.issueYear}/${e.issueMonth}) ${title}`
              : `No.${e.contentsNo} ${title}`;
        }
        return {
          label: label,
          value: e.id,
        };
      });
    },
    setContentsForLocale() {
      let locale = "";
      switch (this.$i18n.locale) {
        case "ja":
          locale = "ja";
          this.contents.contentsTitle = this.contentsSelectOptionsData.filter(
            (arr) => arr.id == this.contents.id,
          )[0].title;
          break;
        case "en":
          locale = "en";
          this.contents.contentsTitle = this.contentsSelectOptionsData.filter(
            (arr) => arr.id == this.contents.id,
          )[0].titleEn;
          break;
        default:
          locale = "ja";
          this.contents.contentsTitle = this.contentsSelectOptionsData.filter(
            (arr) => arr.id == this.contents.id,
          )[0].title;
          break;
      }
      this.$set(
        this.contents.headerName,
        "base",
        this.contents.headerName[locale],
      );
      this.$set(
        this.contents.headerBody,
        "base",
        this.contents.headerBody[locale],
      );
      this.$set(
        this.contents.contentsBody,
        "base",
        this.contents.contentsBody[locale],
      );
      this.$set(this.hierarchy, 0, {
        display: this.contents.headerName[locale],
      });

      if (
        isEmpty(this.contents.contentsTitle) ||
        isEmpty(this.contents.contentsBody.base)
      ) {
        // 表示情報がnullの場合は"Under Construction"を表示する
        this.contents.contentsTitle = "Under Construction";
        this.$set(this.contents.contentsBody, "base", "");
      }
    },
  },
};
</script>
<style lang="scss">
.contents-body p {
  margin: 13px auto 13px auto;
}
</style>
<style lang="scss" scoped>
.contents-select {
  width: 50%;
  margin: 0 auto;
}
.contents-separator {
  width: 100%;
}
::v-deep() {
  .vs__search {
    height: 0px;
  }
}
.column_header {
  font-size: 1.8rem;
  font-weight: bold;
  color: #017441;
  margin-bottom: 0.5em;
}
</style>
