export default {
  components: {
    atoms: {
      atomSelect: {
        noOptions: "没有适用的选项",
      },
    },
    molecules: {
      requiredMark: {
        required: "必填选项",
      },
    },
    organisms: {
      completedModal: {
        backToIndex: "返回列表",
        modalTitle: "完成",
        modalMessage: "已完成",
      },
      confirmModal: {
        modalTitle: "确认",
        modalOkButtonLabel: "是",
        modalCancelButtonLabel: "否",
      },
      errorModal: {
        modalTitle: "错误",
        modalButtonLabel: "返回列表",
        showMessage: "发生错误",
      },
      privacyMark: {
        privacyMark: "PEACEMIND已获得隐私权标章。",
        isms: "PEACEMIND在银座总部的员工支援咨询部、SC运营部、信息系统部（信息安全委员会）取得了信息安全管理体系（ISO/IEC27001）认证。",
        ssl: "发送的信息将由名为SSL的安全系统进行加密和保护。",
      },
      userFooter: {
        environment: "关于运行环境",
        personalInformation: "关于个人信息的处理",
        informationSecurity: "信息安全基本方针",
        termsOfUse: "使用条款",
        dataUtilizationAgreement: "同意数据利用",
        administrator: "运营者",
        inquiry: "联系我们",
      },
      userHeader: {
        memberToRegister: "注册会员",
        login: "登录",
        changeProfile: "更改个人资料",
        changePassword: "账户安全",
        registerSecretQuestion: "设置安全问题",
        logout: "退出",
      },
      userNavigation: {
        eap: "心理咨询",
        cunselingApply: "咨询申请",
        counselingHistory: "咨询历史",
        stressCheck: "压力状况",
        stressCheckTestHistory: "检测结果历史",
        languageSetting: "语言设置",
        selfCareCheck: "自我治疗检测",
        webLearning: "网络学习",
        userGuide: "用户向导",
        howToUseThisPlatform: "使用指南",
        faq: "常见问题",
        admin: "管理页面",
      },
    },
  },
  plugins: {
    veeValidate: {
      required: "必填项目",
      requiredSelect: "请选择",
      min: "至少在{min}个字符以上",
      max: "请不要超过{max}个字符",
      digits: "请输入{digits}个字符",
      email: "必须为邮件地址格式",
      regex: "请输入半角字母、数字、符号",
      passwordCharacterTypes:
        '请使用大写英文字母、小写英文字母、数字及符号（连字符"-"，下划线"_" 只有）中至少3种以上的字符',
      numeric: "仅数值",
      integer: "请输入半角数字",
      max_value: "上限为{maxValue}",
      between: "从{min}至{max}",
      numberIsSmaller: "请输入文字数不超过{number}",
      match: "请与{match}保持一致",
    },
  },
  views: {
    login: {
      account: {
        groupLogin: "团体登录",
        groupId: "团体ID",
        loginFailure: "登录验证失败。",
        login: "登录",
      },
      user: {
        login: "登录",
        useService: "利用服务",
        stressCheck: {
          name: "压力状况检测",
          loginId: "个人ID",
        },
        group: {
          name: "心理咨询",
          loginId: "邮件地址",
        },
        eap: {
          name: "心理咨询",
          loginId: "邮件地址",
        },
        useServiceAnnotation:
          "*如果您同意链接压力状况检测信息，无论您登录哪一方，都可以同时享用两方的服务。",
        password: "密码",
        passwordReset: "如果您忘记密码，请点击这里",
        userSelectModal: {
          modalTitle: "用户选择",
          modalDescription: "选择你想登录的用户。",
          modalLoginId: "个人ID",
          modalFullName: "身份",
        },
        noUserModal: {
          modalTitle: "错误",
          modalDescription: "未找到用户。请联系你公司的管理员。",
          modalButtonLabel: "关闭",
        },
        timeoutModal: {
          modalTitle: "错误",
          modalDescription: "发生超时。请再试一次。",
          modalButtonLabel: "关闭",
        },
      },
    },
    passwordReminder: {
      passwordReminder: {
        completeModal: {
          messageRow: {
            1: "密码重置的URL已发送至您输入的邮件地址。",
            2: "请访问邮件中的URL并重置密码。",
            3: "此外，URL的有效期限为发行开始后的24小时之内。",
          },
          okButton: "OK",
        },
        title: "密码提醒",
        useService: "利用服务",
        stressCheck: {
          name: "压力状况检测",
          loginId: "个人ID",
        },
        eap: {
          name: "EAP",
          loginId: "邮件地址",
        },
        invalidLoginId: "由于个人ID不同，因此无法验证。",
        notExistsSecretQuestionAnswer: {
          messageRow: {
            1: "因初始密码设置尚未完成，因此无法使用此功能。",
            2: "请使用您所属的公司、团体通过通知、邮件等向您提供的初始密码进行登录。",
          },
        },
        invalidMailAddress: "与注册的邮件地址不同。",
        descriptionStressCheck: "*请使用您所属的公司、团体提供的个人ID。",
        descriptionEap: "*请输入您注册EAP服务时设置的邮件地址。",
        okButton: "OK",
      },
      passwordReminderEmail: {
        completeModal: {
          messageRow: {
            1: "密码重置的URL已发送至您输入的邮件地址。",
            2: "请访问邮件中的URL并重置密码。",
            3: "此外，URL的有效期限为发行开始后的24小时之内。",
          },
          okButton: "OK",
        },
        title: "输入邮件地址",
        mailAddress: "邮件地址",
        invalidMailAddress: "与注册的邮件地址不同。",
        description: "※请输入公司、团体提供的邮件地址。",
        okButton: "OK",
      },
      passwordReminderPassword: {
        completeModal: {
          message: "密码已更改。",
          loginButton: "OK",
        },
        title: "设置密码",
        newPassword: "新密码",
        description: {
          messageRow: {
            1: "密码至少应为12个字符，并使用以下4组中的3种字符。",
            2: "1) 大写字母（A到Z）",
            3: "2) 小写字母（a到z）",
            4: "3) 数字（0-9）",
            5: '4) 符号（连字符"-"，下划线"_" 只有）',
          },
        },
        updateButton: "OK",
        invalidCharactorType:
          '请使用大写英文字母、小写英文字母、数字及符号（连字符"-"，下划线"_" 只有）中至少3种以上的字符',
        invalidLengthPassword: "填写内容至少12个字，并且不超过255个字。",
        differentPassword: "请设置一个与“现密码”不同的密码。",
      },
      passwordReminderSecretQuestion: {
        title: "密保问题答案",
        secretQuestion: "密保问题",
        secretQuestionAnswer: {
          row: {
            1: "密保问题答案",
            2: "",
          },
          placeholder: "密保问题答案",
        },
        invalidAnswer: "密保问题答案不正确。",
        okButton: "OK",
      },
    },
    user: {
      account: {
        password: {
          completeModal: {
            title: "密码更新完成",
            message: "已完成",
            okButton: "OK",
          },
          title: "修改密码",
          currentPassword: "当前密码",
          newPassword: "新密码",
          invalidCharactorType:
            '请使用大写英文字母、小写英文字母、数字及符号（连字符"-"，下划线"_" 只有）中至少3种以上的字符',
          invalidLengthPassword: "填写内容至少12个字，并且不超过255个字。",
          invalidCurrentPassword: "输入的当前密码不正确。",
          differentPassword: "请设置一个与“现密码”不同的密码。",
          okButton: "OK",
          description: {
            messageRow: {
              1: "密码至少应为12个字符，并使用以下4组中的3种字符。",
              2: "1) 大写字母（A到Z）",
              3: "2) 小写字母（a到z）",
              4: "3) 数字（0-9）",
              5: '4) 符号（连字符"-"，下划线"_" 只有）',
            },
          },
        },
        twoFactorAuthentication: {
          title: "双因素认证",
          reset: "重新设置",
          sms: {
            statement: {
              1: "启用了短信双因素认证。",
            },
          },
          totp: {
            statement: {
              1: "使用认证应用程序的双因素认证是有效的。",
              2: "如果你无法访问你的智能手机或其他安装了认证应用程序的设备，你可以使用恢复代码来认证。",
              3: "请将此文本代码保存在一个安全的地方。",
            },
            openCodeLink: "显示恢复代码",
            used: "二手",
          },
        },
      },
      secretQuestion: {
        title: "设置密保问题",
        secretQuestion: "密保问题问题",
        answer: "回答",
        secretQuestionAnswer: "密保问题答案",
        okButton: "OK",
        description: {
          messageRow: {
            1: "※当您忘记登录密码时，可以使用密保问题找回密码。",
            2: "如果您多次设置密保问题的答案，则会应用您最后一个登录的问题。",
            3: "另外，在正常使用的情况下，不会向您询问密保问题，将通过输入您的个人ID和密码来进行身份验证。",
          },
        },
        completeModal: {
          title: "更新完成",
          message: "已完成",
          okButton: "OK",
        },
      },
      userTop: {
        troubleUp: "您现在有什么烦恼吗？",
        troubleUnder: "",
        consultationApplication: "心理咨询申请请点击这里",
        noticeList: "通知列表",
      },
    },
    logout: {
      logoutMessage: "已退出登录。",
      sendTopPageButton: "前往业务合作伙伴主页",
    },
    qa: {
      modal: {
        title: "常见问题",
      },
      title: "常见问题",
      category: "类别",
      keyword: "关键词",
      searchButton: "搜索",
      searchCancelButton: "検索解除",
      stressCheck: "压力状况检测",
      eap: "EAP",
      hierarchy: {
        display: "常见问题",
      },
    },
  },
};
