<template>
  <div
    :class="{
      navigation: !enableAccordion,
      'user-navigation': enableAccordion,
    }"
  >
    <ul>
      <li :class="{ 'is-active': isOpenAccordion.stressCheck }">
        <span
          @click="isOpenAccordion.stressCheck = !isOpenAccordion.stressCheck"
        >
          <i class="icon__chart"></i>
          ストレスチェック
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.stressCheck,
          }"
        >
          <admin-navigation-project-link
            v-for="(project, index) in admin.projects.currentOrFutureProjects"
            :key="`currentOrFutureProject_${index}`"
            :project="project"
            :use-dropdown="!enableAccordion"
            @on-click-user-info="$emit('onClickUserInfo')"
          />
        </ul>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible':
              isOpenAccordion.stressCheck && isOpenPastProjects,
          }"
        >
          <li
            :class="{
              'is-active': isOpenAccordion.stressCheck && isOpenPastProjects,
            }"
          >
            <span @click="isOpenPastProjects = !isOpenPastProjects">
              <i>
                <font-awesome-icon
                  :icon="isOpenPastProjects ? 'chevron-down' : 'chevron-up'"
                />
              </i>
              過去のプロジェクト
            </span>
          </li>
        </ul>
        <ul v-if="isOpenPastProjects">
          <admin-navigation-project-link
            v-for="(project, index) in admin.projects.pastProjects"
            :key="`pastProject_${index}`"
            :project="project"
            :use-dropdown="!enableAccordion"
            @on-click-user-info="$emit('onClickUserInfo')"
          />
        </ul>
      </li>
      <li :class="{ 'is-active': isOpenAccordion.siteManagement }">
        <span
          @click="
            isOpenAccordion.siteManagement = !isOpenAccordion.siteManagement
          "
        >
          <i class="icon__megaphone"></i>
          サイト管理
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.siteManagement,
          }"
        >
          <li @click="$emit('onClickUserInfo')">
            <router-link to="/admin/notification">
              <i class="icon__note"></i>
              お知らせ
            </router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="hasPmRole"
        :class="{ 'is-active': isOpenAccordion.orgManagement }"
      >
        <span
          @click="
            isOpenAccordion.orgManagement = !isOpenAccordion.orgManagement
          "
        >
          <i class="icon__gear"></i>
          組織管理
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.orgManagement,
          }"
        >
          <li v-if="hasPmRole" @click="$emit('onClickUserInfo')">
            <router-link to="/admin/users">
              <i class="icon__note"></i>
              従業員管理
            </router-link>
          </li>
          <li v-if="hasPmRole" @click="$emit('onClickUserInfo')">
            <router-link to="/admin/send-mail-history">
              <i class="icon__note"></i>
              メール送信履歴
            </router-link>
          </li>
          <li v-if="hasPmRole" @click="$emit('onClickUserInfo')">
            <router-link to="/admin/password_bulk_reset">
              <i class="icon__note"></i>
              ストレスチェック対象者パスワード一括リセット
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link to="/">
          <i class="icon__note" />
          ユーザ画面
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import some from "lodash/some";
import has from "lodash/has";
import union from "lodash/union";

export default {
  name: "AdminNavigation",
  props: {
    enableAccordion: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClickUserInfo"],
  data() {
    return {
      isOpenAccordion: {
        stressCheck: false,
        siteManagement: false,
        orgManagement: false,
      },
      isOpenPastProjects: false,
    };
  },
  computed: {
    ...mapState("navigation", ["admin"]),
    hasPmRole() {
      return some(
        union(
          this.admin.projects.currentOrFutureProjects,
          this.admin.projects.pastProjects,
        ),
        (p) => has(p, "roleCode") && p.roleCode === "PM",
      );
    },
  },
  mounted() {
    this.$store.dispatch("navigation/admin");
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  position: relative;
  visibility: visible;
  display: block;
  overflow-y: hidden;
  max-height: 0;
}

.accordion-visible {
  max-height: 100%;
}
.navigation ::v-deep(ul) > li > ul > li.dropdown {
  .slide-enter-active {
    transition: all 0.1s ease-in;
  }

  .slide-leave-active {
    transition: all 0.1s cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 500px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  .dropdown-menu.show {
    width: 400px;
    ul {
      list-style: none !important;
      position: relative;
      margin: 0;
      padding: 0;
      li {
        span,
        a {
          display: block;
          margin: 0 -6px;
          padding: 14px 4px 14px 28px;
          line-height: 1;
          text-decoration: none;
          font-weight: 700;
          font-size: 14px;
          font-size: 0.875rem;
          color: #00c389;
        }
      }
    }
  }
}
</style>
