<template>
  <div class="switch">
    <input v-model="checked" type="checkbox" class="checkbox" />
    <div class="knobs" />
    <div class="layer" />
  </div>
</template>

<script>
export default {
  name: "AtomSwitch",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    value(newValue) {
      this.checked = newValue;
    },
    checked(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>
