// パスワード用文字種組合バリデーション
export default {
  validate(value) {
    if (value == null) {
      // NULLの場合、バリデート対象外
      return true;
    }
    if (/[^A-Za-z0-9_-]+/g.test(value)) {
      // 非許可文字を含んでいる場合
      return false;
    }
    // 文字種組合チェック
    return (
      [/[A-Z]+/g, /[a-z]+/g, /[0-9]+/g, /[_-]+/g].filter((regexp) =>
        regexp.test(value),
      ).length >= 3
    );
  },
};
