<template>
  <Field
    v-slot="{ errors, value: val, handleChange, handleReset }"
    :name="vid || name"
    :rules="rules"
    :label="name"
  >
    <slot
      :errors="errors"
      :value="val"
      :handle-change="handleChange"
      :handle-reset="handleReset"
    />
    <a @click="handleChange(value);" ref="validatonKicker" hidden />
  </Field>
</template>

<script>
export default {
  name: 'ValidationProvider',
  props: {
    rules: {
      type: [Object, String, Function],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    vid: {
      type: String,
    },
    value: {
      type: [String, Number, Date],
      default: undefined,
    },
    isMountedValidation: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal){
        // null/undefinedからnull/undefinedの変更時は値の変化なしと判定してチェックしない
        if (
          (newVal === null || newVal === undefined) &&
          (oldVal === null || oldVal === undefined)
        ) return;

        // slot変数に直接アクセスする方法が見つからなかったため、
        // アンカータグのクリックイベントを経由してchangeHandleを呼び出す。
        this.$refs.validatonKicker.click();
      },
    },
  },
  mounted() {
    // v-ifなどで動的にコンポーネントが追加された時にバリデーションを実行させたいため、
    // propからmounted時のバリデーション実施をコントロールできる様にする
    if (this.isMountedValidation) {
      this.$refs.validatonKicker.click();
    }
  },
}
</script>
