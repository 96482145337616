<template>
  <div class="app">
    <loading-mask :is-visible="isLoading" />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { isPublicPage, isUserPage } from "@/helpers/PageAuthority";

export default {
  name: "App",
  data: () => ({}),
  computed: {
    ...mapState("auth", ["loginUser", "loading", "transited"]),
    ...mapGetters("loading", ["isLoading"]),
    ...mapGetters("auth", ["isAccountLoggedIn", "isUserLoggedIn"]),
  },
  async beforeCreate() {
    // browser reload 時などのセッションチェック
    this.$store.dispatch("auth/location", location);
    try {
      // ログインが必要なページの場合のみ、login情報を再取得して、そのlogin情報を元にリダイレクトなどの処理を行う
      if (!isPublicPage(location.pathname)) {
        await this.$store.dispatch("auth/load");
        // user login が必要なページだけどログインしていない場合
        if (isUserPage(location.pathname) && !this.loginUser.id) {
          return this.$router.push("/login");
        }
        // login情報を元に必要に応じてリダイレクト
        this.redirectIfNecessary(this.loginUser);
      }
    } catch (error) {
      const payload = error.response.data;
      if (!isPublicPage(location.pathname)) {
        const accountHash = sessionStorage.getItem("account");
        if (accountHash) {
          this.$router.push(`/logout/${accountHash}`);
        } else if (payload.loginPage) {
          this.$router.push(payload.loginPage);
        }
      }
    }
  },
  beforeUpdate() {
    // route が変更された時のセッションチェック
    this.$store.dispatch("auth/location", location);
    if (!this.transited) {
      // ログインが必要なページの場合のみ、login情報を再取得して、そのlogin情報を元にリダイレクトなどの処理を行う
      if (!isPublicPage(location.pathname)) {
        this.$store
          .dispatch("auth/load")
          .then(() => {
            // user login が必要なページだけどログインしていない場合
            if (isUserPage(location.pathname) && !this.loginUser.id) {
              return this.$router.push("/login");
            }
            // login情報を元に必要に応じてリダイレクト
            this.redirectIfNecessary(this.loginUser);
          })
          .catch((error) => {
            const payload = error.response.data;
            if (!isPublicPage(location.pathname)) {
              const accountHash = sessionStorage.getItem("account");
              if (accountHash) {
                this.$router.push(`/logout/${accountHash}`);
              } else if (payload.loginPage) {
                this.$router.push(payload.loginPage);
              }
            }
          });
      }
    }
  },
  methods: {
    redirectIfNecessary(loginUser) {
      // マークシート業者の場合
      if (loginUser.hasMarkSheet) {
        return this.$router.replace(
          location.pathname.indexOf("mark_sheet") !== -1
            ? location.pathname
            : "/mark_sheet/list",
          () => {},
          () => {},
        );
      }
      // SCログインの場合
      if (loginUser.scUserTable) {
        if (!loginUser.id) {
          return;
        }
        if (!loginUser.language) {
          // 言語設定
          return this.$router.replace(
            "/user/language/edit",
            () => {},
            () => {},
          );
        } else if (!loginUser.hasSecretQuestion) {
          // 秘密の質問設定
          return this.$router.replace(
            {
              name: "secret_question_edit",
              params: { loginId: loginUser.username },
            },
            () => {},
            () => {},
          );
        } else if (!loginUser.passwordFirstUpdated) {
          // パスワード変更
          return this.$router.replace(
            "/user/password/edit",
            () => {},
            () => {},
          );
        }
      }
      // EAPログインの場合
      if (loginUser.eapUserTable) {
        if (!loginUser.mailAddressAuthentication) {
          // メールアドレス認証
          // ToDo: 画面分割後に登録予定
          return;
        } else if (/\/two_factor_authentication/g.test(this.$route.path)) {
          // 2FAの登録中は遷移を行わない
          return;
        } else if (!loginUser.twoFactorAuthentication) {
          // 2FA認証
          return this.$router.replace(
            "/user/two_factor_authentication",
            () => {},
            () => {},
          );
        } else if (
          loginUser.scConnectionAgreement == null &&
          loginUser.scContentsEnable &&
          loginUser.eapContentsEnable &&
          !/^\/password_reminder\/.*/.test(location.pathname)
        ) {
          // SC情報連携未設定、且つ、SC/EAP共に利用可能な場合、SC情報連携画面に飛ばす
          // ただしSC情報連携画面からパスワードリマインダ画面を別タブで開く場合があるので、その場合だけはスルーさせる
          return this.$router.replace(
            "/user/stress_check_connection",
            () => {},
            () => {},
          );
        } else if (/^\/password_reminder\/.*/.test(location.pathname)) {
          // SC情報連携の登録中は遷移を行わない
          return;
        } else if (
          loginUser.oldEapUserId != null &&
          !loginUser.migrationUserInfoRegister
        ) {
          // 旧EAPシステムの移行ユーザーで、ユーザー情報が不足している
          return this.$router.replace(`/user/edit/${loginUser.eapUserId}`);
        }
      }
    },
  },
};
</script>
