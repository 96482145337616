import api from "@/api";

export default {
  namespaced: true,
  state: {
    admin: {
      projects: {
        currentOrFutureProjects: [],
        pastProjects: [],
      },
    },
  },
  mutations: {
    setAdminProjects: (state, payload) => {
      state.admin.projects = payload;
    },
  },
  actions: {
    admin: (context) => {
      api
        .get("/admin")
        .then((response) => context.commit("setAdminProjects", response.data));
    },
  },
};
