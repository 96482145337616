import Encoding from "encoding-japanese";

export function readCsvFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const encode = Encoding.detect(new Uint8Array(e.target.result));
      if (encode === "UTF8" || encode === "SJIS" || encode === "ASCII") {
        resolve(file);
      } else {
        alert(
          "文字コードがUTF8、SHIFT-JIS、ASCII以外のCSVファイルはアップロードできません。",
        );
        resolve(undefined);
      }
    };
    reader.onerror = (error) => reject(error);
  });
}
export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => resolve(e.target.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });
}
