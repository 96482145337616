<template>
  <div>
    <form @submit.prevent="$emit('click-search-button')">
      <slot />
      <div class="board__button">
        <atom-button :is-submit="true"> 検索する </atom-button>
        <atom-button
          v-if="showClearBtn"
          :is-primary="false"
          :is-submit="false"
          @click="$emit('click-reset-form-button')"
        >
          クリア
        </atom-button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SearchCondition",
  props: {
    showClearBtn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click-search-button", "click-reset-form-button"],
};
</script>

<style lang="scss" scoped>
:slotted(.column-1of3 > div) {
  display: flex;
  align-items: center;
}
:slotted(.column-1of3 label) {
  min-width: 99px;
}
:slotted(.column-1of3 > *:first-of-type) {
  min-width: 320px;
}
:slotted(.column-1of3 > div > div) {
  min-width: 210px;
}
:slotted(.board.large .column-1of3 > div > input[type="checkbox"]) {
  min-width: 18px;
  margin: 0 auto 0 0;
}
.board.large .board__button > button:not(:first-child) {
  margin-left: 5px;
}
</style>
