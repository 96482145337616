<template>
  <validation-wrapper
    v-slot="{ validationContext }"
    :has-focus="hasFocus"
    :rules="rules"
    :messages="messages"
    :name="placeholder || 'AtomDateTimePicker'"
    :value="modelValue"
    :is-mounted-validation="isMountedValidation"
  >
    <span
      :class="{
        'invalid-datepicker-filed': validationContext.errors.length > 0,
        'is-showing-in-bottom': isShowingInBottom
      }"
      @click="(e)=>{
        // veeValidate4 の影響でcomponentの外からmodelValueで値を与えた際、
        // clickイベントでfocusされてしまいカレンダーが開いてしまうため、イベント発生時にblurで抑止する。
        if(this.isModelChangedFromOutside){
          e.target.blur();
          this.isModelChangedFromOutside = false;
        }
      }"
      ref="datepickerSpan"
    >
      <Datepicker
        :class="{
          'disabled-style': disabled
        }"
        :model-value="modelValue"
        :upper-limit="upperLimit"
        :lower-limit="lowerLimit"
        :starting-view-date="startingViewDate"
        :disabled-dates="disabledDates"
        :disabled-time="disabledTime"
        :starting-view="startingView"
        :minimum-view="minimumView"
        :day-picker-heading-format="dayPickerHeadingFormat"
        :day-format="dayFormat"
        :weekday-format="weekdayFormat"
        :input-format="inputFormat"
        :locale="language"
        :disabled="disabled"
        :typeable="typeable"
        :week-starts-on="weekStartsOn"
        :clearable="clearable"
        :allow-outside-interval="allowOutsideInterval"
        style="width: 100%"
        @update:model-value="
          (e) => {
            validationContext.handleChange(e);
            onInput(e);
          }
        "
        @opened="reCalcration();validationContext.handleChange(modelValue);hasFocus = true;"
        @closed="hasFocus = false;"
        @search:focus="hasFocus = true;"
        @search:blur="hasFocus = false;"
        :placeholder="disabled?'':'日付を選択してください'"
      />
    </span>
  </validation-wrapper>
</template>

<script>
import includes from "lodash/includes";
import Datepicker from "vue3-datepicker";
import { ja, enUS, zhCN } from "date-fns/locale";

export default {
  name: "AtomDateTimePicker",
  components: {
    Datepicker,
  },
  props: {
    modelValue: {
      type: Date,
      default: undefined,
    },
    // 選択可能な日付の上限
    upperLimit: {
      type: Date,
      default: undefined,
    },
    // 選択可能な日付の下限
    lowerLimit: {
      type: Date,
      default: undefined,
    },
    // 日時が未設定状態での初期値
    startingViewDate: {
      type: Date,
      default: () => new Date(new Date().setHours(0, 0, 0, 0)),
    },
    // 選択できない日付({ dates: Date[], predicate: (target: Date) => boolean })
    disabledDates: {
      type: Object,
      default: undefined,
    },
    // 選択できない時刻({ dates: Date[], predicate: (target: Date) => boolean })
    disabledTime: {
      type: Object,
      default: undefined,
    },
    // 最初にどの単位でビューを開くか(year, month, day)
    startingView: {
      type: String,
      default: "day",
    },
    // どの単位までビューを開くか(year, month, day, time)
    minimumView: {
      type: String,
      default: "time",
    },
    // ビュー見出しの書式設定
    dayPickerHeadingFormat: {
      type: String,
      default: "yyyy LLLL",
    },
    // dayビューでの各日付の書式設定
    dayFormat: {
      type: String,
      default: "dd",
    },
    // 曜日の書式設定
    weekdayFormat: {
      type: String,
      default: "EE",
    },
    inputFormat: {
      type: String,
      default: "yyyy/MM/dd HH:mm",
    },
    // 言語(ja, enUS, zhCN)
    locale: {
      type: String,
      default: "ja",
      validator(value) {
        return includes(["ja", "enUS", "zhCN"], value);
      },
    },
    // 無効フラグ
    disabled: {
      type: Boolean,
      default: false,
    },
    // ユーザーの手動入力を許可
    typeable: {
      type: Boolean,
      default: false,
    },
    // 週を開始する曜日(0:日 〜 6:土)
    weekStartsOn: {
      type: Number,
      default: 0,
    },
    // クリアボタンを表示するか
    clearable: {
      type: Boolean,
      default: true,
    },
    // 表示月の範囲外を選択できるか(翌月の1日など)
    allowOutsideInterval: {
      type: Boolean,
      default: false,
    },
    // 範囲を選択する際(from ~ to)にto側ならtrueを設定
    searchTo: {
      type: Boolean,
      default: false,
    },
    // プレースホルダ
    placeholder: {
      type: String,
      default: undefined,
    },
    // 規則(必須等)
    rules: {
      type: Object,
      default: undefined,
    },
    // 規則違反時に表示する文言
    messages: {
      type: Object,
      default: undefined,
    },
    isMountedValidation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      hasFocus: false,
      languages: {
        ja,
        enUS,
        zhCN,
      },
      isModelChangedFromOutside: false,
      isShowingInBottom: false
    };
  },
  computed: {
    language() {
      return this.languages[this.locale];
    },
  },
  methods: {
    onInput(e) {
      if (this.searchTo && e instanceof Date) {
        // TOの検索条件として使用される場合、指定日時に「59秒999ミリ秒」を加算して検索する必要あり。
        // 値がクリアされた時を考慮して、型チェック
        e.setSeconds(59, 999);
      }
      this.$emit("update:modelValue", e);
    },
    async reCalcration() {
      await this.$nextTick();
      const { top } = this.$refs.datepickerSpan.getBoundingClientRect();
      // ポップアップするカレンダーの高さ約300pxを基準にして下部に表示すべきか判定する
      this.isShowingInBottom = parseInt(top,10) < 300;
    },
  },
  watch: {
    modelValue(){
      // 非活性時の値変更検知は不要のためdisabledフラグを考慮
      if(!this.disabled){
        // componentの外からmodelValueが変わったことをフラグで管理
        this.isModelChangedFromOutside = true;
      }
    }
  }
};
</script>
<style lang="scss">
.v3dp__popout {
  color: #555 !important;
  bottom: 38px;
  top: auto !important;
  z-index:101 !important;
}

.is-showing-in-bottom .v3dp__popout {
  color: #555 !important;
  bottom: auto;
  top: 100% !important;
}

.v3dp__divider {
  visibility: hidden;
}
.v3dp__clearable {
  position: absolute !important;
  top: 5px;
  right: 0;
  left: auto !important;
}
.v3dp__clearable i {
  font-style: normal;
  visibility: hidden;
}
.v3dp__clearable i:before {
  visibility: visible;
  content: "×";
}
.v3dp__input_wrapper {
  position: relative;
  width: 210px;
}
.v3dp__input_wrapper input {
  width: 100%;
}
.disabled-style input[type="text"] {
  background-color: #f3f3f3;
  cursor: not-allowed;
}

.v3dp__popout-day,.v3dp__popout-time {
  button[disabled] {
    pointer-events: none;
  }

  .v3dp__elements {
    cursor: not-allowed;
  }

  .v3dp__elements button:not([disabled]) {
    cursor: auto;
  }
}

.invalid-datepicker-filed input[type="text"] {
  border-color: red !important;
  border-radius: 4px !important;
  border-width: 1px;
  border-style: solid;
}

</style>
