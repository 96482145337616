<template>
  <validation-observer v-slot="{ invalid }" tag="div">
    <modal v-model:is-visible="isVisible">
      <template #body>
        <div class="new-password-container"></div>
        <div class="new-password-input">
          <span>
            新しいパスワード:
            <atom-input
              v-model="inputPassword"
              :rules="{
                required: true,
                min: 12,
                max: 255,
                passwordCharacterTypes: true,
              }"
              :messages="{
                required: '必須項目',
                min: '12文字以上にしてください',
                max: '255文字以下にしてください',
                passwordCharacterTypes:
                  '半角英大文字、英小文字、数字、記号（ハイフン&quot;-&quot;、アンダーバー&quot;_&quot;のみ)のうち3種類以上を利用してください',
              }"
            ></atom-input>
          </span>
          <br />
          <span class="title-reset-pw"
            >「保存」ボタンを押下後はパスワードを確認することができません。必ずお控えください。</span
          >
        </div>
      </template>
      <template #footer>
        <atom-button
          :disabled="invalid"
          @click="$emit('click-save-button', inputPassword)"
        >
          保存
        </atom-button>
        <atom-button :is-primary="false" @click="$emit('click-cancel-button')">
          キャンセル
        </atom-button>
      </template>
    </modal>
  </validation-observer>
</template>

<script>
export default {
  props: {
    shows: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["click-save-button", "click-cancel-button", "update:shows", "close"],
  data: () => {
    return {
      inputPassword: undefined,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.shows;
      },
      set(value) {
        this.$emit("update:shows", value);
        if (!value) {
          this.$emit("close", value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.new-password-container {
  height: 120px;
}
.new-password-input {
  position: absolute;
  top: 50px;
  max-width: 400px;
}
.title-reset-pw {
  color: red;
}
</style>
