<template>
  <validation-observer v-slot="{ invalid }">
    <div class="grid-1of1">
      <div class="board large">
        <h2 class="heading--tertiary">{{ title }}送信</h2>
        <slot name="nativeBody" />
        <div>
          <label>
            件名<required-mark />
            <!-- dummyのinputタグで対象のv-modelをバイドしないとveeValidateが正常動作しないため追加 -->
            <input type="text" v-model="input.subject" hidden/>
            <atom-input
              v-model="input.subject"
              placeholder="件名"
              :disabled="disableSubject"
              :rules="{ required: true, max: 255 }"
              :messages="{
                required: '必須',
                max: '255文字以下',
              }"
            />
          </label>
        </div>
        <div>
          <label>
            本文<required-mark />
            <!-- dummyのinputタグで対象のv-modelをバイドしないとveeValidateが正常動作しないため追加 -->
            <input type="text" v-model="input.body" hidden />
            <atom-textarea
              v-model="input.body"
              placeholder="本文"
              :disabled="disableBody"
              :rules="{ required: true }"
              :messages="{
                required: '必須',
              }"
            />
          </label>
        </div>
        <div>
          <label class="send-start-date__label">送信開始日時</label>
          <div class="send-start-date__input">
            <input
              id="send-now"
              v-model="input.sendNow"
              type="checkbox"
              :disabled="disableSendNow"
              @change="onChangeSendNow"
            />
            <label for="send-now">今すぐ</label>
          </div>
        </div>
        <div class="column-1of1">
          <div>
            <label>
              日時
              <atom-date-time-picker
                :model-value="input.sendDateTime"
                :starting-view-date="defaultValue"
                :disabled="input.sendNow"
                :disabled-dates="{ predicate: notBeforeToday }"
                :disabled-time="{ predicate: notBeforeTime }"
                :rules="requiredDateTime"
                :messages="{
                  required: '必須',
                }"
                @update:model-value="input.sendDateTime = $event"
              />
            </label>
          </div>
        </div>
        <p class="notice-message">
          指定された送信時刻からメール送信を開始しますが、弊社メールサーバのメール送信件数が多い場合、
          <br />
          メール送信完了まで時間がかかる場合がございます。
        </p>
        <div class="board__button">
          <atom-button v-show="doCancel" @click="onClickSendCancel">
            送信キャンセル
          </atom-button>
          <atom-button
            :disabled="invalid && necessaryValidation"
            @click="onClickNext"
          >
            次へ→
          </atom-button>
        </div>
      </div>
    </div>

    <modal
      v-model:is-visible="displayConfirmModal"
      modal-window-style="width: 80vw;"
    >
      <template #header> 登録内容の確認 </template>
      <template #body>
        <slot name="modalNativeBody" />
        <h3>件名</h3>
        <span class="is-size-7 modal-text">{{ input.subject }}</span>
        <h3>本文</h3>
        <span class="is-size-7 mail-body">{{ input.body }}</span>
        <br />
        <br />
        <p>
          <span class="is-size-7 modal-text">URL: {{ loginUrl }}</span>
        </p>
        <p>
          <span class="is-size-7">個人ID / Individual ID: ◯◯◯◯</span>
        </p>
        <p v-show="needPassword">
          <span class="is-size-7">パスワード / Password: ＊＊＊＊＊＊＊＊</span>
        </p>
        <p>
          <span class="is-size-7 mail-body">{{ mailSignature }}</span>
        </p>
        <br />
        <h3>送信開始日時</h3>
        <p>
          <span class="is-size-7">{{ modalSendReservedAt }}</span>
        </p>
      </template>
      <template #footer>
        <atom-button @click="onClickCreate">
          {{ confirmButtonLabel }}
        </atom-button>
        <atom-button @click="onClickCreateCancel">キャンセル</atom-button>
      </template>
    </modal>

    <modal v-model:is-visible="displayCompleteModal">
      <template #header> 登録完了 </template>
      <template #body> 登録が完了しました。 </template>
      <template #footer>
        <atom-button @click="onClickComplete">OK</atom-button>
      </template>
    </modal>

    <modal v-model:is-visible="displayCancelModal">
      <template #body> 送信をキャンセルしてもよろしいですか？ </template>
      <template #footer>
        <atom-button @click="onClickDelete">はい</atom-button>
        <atom-button @click="onClickDeleteCancel">いいえ</atom-button>
      </template>
    </modal>
  </validation-observer>
</template>

<script>
import format from "date-fns/format";
import times from "lodash/times";
export default {
  name: "MailSendMain",
  props: {
    title: {
      type: String,
      default: undefined,
    },
    defaultSubject: {
      type: String,
      default: undefined,
    },
    defaultBody: {
      type: String,
      default: undefined,
    },
    defaultSendNow: {
      type: Number,
      default: undefined,
    },
    defaultSendDateTime: {
      type: Date,
      default: undefined,
    },
    disableSubject: {
      type: Boolean,
      default: false,
    },
    disableBody: {
      type: Boolean,
      default: false,
    },
    disableSendNow: {
      type: Boolean,
      default: false,
    },
    loginUrl: {
      type: String,
      default: undefined,
    },
    needPassword: {
      type: Boolean,
      default: undefined,
    },
    mailSignature: {
      type: String,
      default: undefined,
    },
    showConfirmModal: {
      type: Boolean,
      default: undefined,
    },
    showCompleteModal: {
      type: Boolean,
      default: undefined,
    },
    showCancelModal: {
      type: Boolean,
      default: undefined,
    },
    confirmButtonLabel: {
      type: String,
      default: "OK",
    },
    doCancel: {
      type: Boolean,
      default: false,
    },
    necessaryValidation: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["click-create", "click-delete"],
  data: function () {
    return {
      input: {
        subject: undefined,
        body: undefined,
        sendNow: false,
        sendDateTime: undefined,
      },
      displayConfirmModal: false,
      displayCompleteModal: false,
      displayCancelModal: false,
      defaultValue: new Date(),
      times: times,
    };
  },
  computed: {
    sendReservedAt: function () {
      return this.input.sendNow ? null : this.input.sendDateTime;
    },
    modalSendReservedAt: function () {
      return this.input.sendNow
        ? "今すぐ"
        : format(this.input.sendDateTime, "yyyy/MM/dd HH:mm");
    },
    requiredDateTime: function () {
      return this.input.sendNow ? { required: false } : { required: true };
    },
  },
  watch: {
    defaultSubject: function () {
      this.input.subject = this.defaultSubject;
    },
    defaultBody: function () {
      this.input.body = this.defaultBody;
    },
    defaultSendNow: function () {
      this.input.sendNow = !!+this.defaultSendNow;
    },
    defaultSendDateTime: function () {
      this.input.sendDateTime = this.defaultSendDateTime;
    },
    showConfirmModal: function () {
      this.displayConfirmModal = this.showConfirmModal;
    },
    showCompleteModal: function () {
      this.displayCompleteModal = this.showCompleteModal;
    },
    showCancelModal: function () {
      this.displayCancelModal = this.showCancelModal;
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date().setHours(0, 0, 0, 0);
    },
    notBeforeTime(date) {
      var today = new Date();
      today.setMinutes(today.getMinutes() - 1);
      return date <= today;
    },
    onChangeSendNow() {
      if (this.input.sendNow) {
        this.input.sendDateTime = undefined;
      }
    },
    onClickNext() {
      this.displayConfirmModal = true;
    },
    onClickCreate() {
      this.$emit("click-create", this.input, this.sendReservedAt);
    },
    onClickCreateCancel() {
      this.displayConfirmModal = false;
    },
    onClickComplete() {
      this.displayCompleteModal = false;
    },
    onClickSendCancel() {
      this.displayCancelModal = true;
    },
    onClickDeleteCancel() {
      this.displayCancelModal = false;
    },
    onClickDelete() {
      this.$emit("click-delete");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-text {
  white-space: nowrap;
}
.mail-body {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.send-start-date__label {
  display: block;
}
.send-start-date__input {
  display: block;
  margin-top: 10px;
  margin-left: 20px;
}
.send-start-date__input label {
  margin-left: 5px;
}
.notice-message {
  margin-left: 20px;
  font-size: 0.875rem;
}
.board__button > *:not(:first-of-type) {
  margin-left: 1rem;
}
</style>
