<template>
  <div class="description" v-html="content"></div>
</template>

<script>
export default {
  name: "AtomSfRichText",
  props: {
    content: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.description ::v-deep(p) {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.5;
  color: black;
  font-feature-settings: normal;
}
</style>
