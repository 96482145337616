import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/views/user/Index.vue"),
      children: [
        {
          path: "login",
          component: () => import("@/views/login/User.vue"),
        },
        {
          path: "login/:hashedCode([a-z0-9]{128})",
          name: "user_hashed_code_login",
          component: () => import("@/views/login/UserHashedCode.vue"),
        },
        // EAP専用ハッシュログインは今後使う可能性があるが、2022年時点で使用予定がないためWBC-1607対応時に蓋閉じ。
        // {
        //   path: "login/eap/:eapLoginOnlyHashedCode([a-z0-9]{128})",
        //   name: "eap_login_only_hashed_code_login",
        //   component: () => import("@/views/login/EapLoginOnlyHashedCode.vue")
        // },
        {
          path: "/",
          component: () => import("@/views/user/UserTop.vue"),
        },
        {
          path: "password_reminder",
          component: () => import("@/views/password_reminder/Index.vue"),
          children: [
            {
              path: "new",
              name: "password_reminder_new",
              component: () =>
                import("@/views/password_reminder/PasswordReminder.vue"),
            },
            {
              path: ":loginId/email/new",
              component: () =>
                import("@/views/password_reminder/PasswordReminderEmail.vue"),
              props: true,
            },
            {
              path: ":loginId/secret_question/new",
              component: () =>
                import(
                  "@/views/password_reminder/PasswordReminderSecretQuestion.vue"
                ),
              props: true,
            },
            {
              path: ":passwordReminderToken/:useServiceLabel/password/edit",
              component: () =>
                import(
                  "@/views/password_reminder/PasswordReminderPassword.vue"
                ),
              props: true,
            },
          ],
        },
        {
          path: "user/mail_address_authentication",
          name: "mailAddressAuthentication",
          component: () => import("@/views/user/MailAddressAuthentication.vue"),
        },
        {
          path: "user/mail_address_authentication_complete",
          name: "mailAddressAuthenticationComplete",
          component: () =>
            import("@/views/user/MailAddressAuthenticationComplete.vue"),
        },
        {
          path: "user/two_factor_authentication",
          name: "twoFactorAuthentication",
          component: () =>
            import("@/views/user/TwoFactorAuthenticationMethod.vue"),
        },
        {
          path: "user/two_factor_authentication_sms",
          name: "twoFactorAuthenticationSms",
          component: () =>
            import("@/views/user/twoFactorAuthenticationSms.vue"),
        },
        {
          path: "user/two_factor_authentication_totp",
          name: "twoFactorAuthenticationTotp",
          component: () =>
            import("@/views/user/TwoFactorAuthenticationTotp.vue"),
        },
        {
          path: "user/two_factor_authentication_complete_sms",
          name: "twoFactorAuthenticationComplete",
          component: () =>
            import("@/views/user/TwoFactorAuthenticationComplete.vue"),
        },
        {
          path: "user/two_factor_authentication_totp_complete",
          name: "twoFactorAuthenticationTotpComplete",
          component: () =>
            import("@/views/user/TwoFactorAuthenticationTotpComplete.vue"),
        },
        {
          path: "user/stress_check_connection",
          name: "stressCheckConnection",
          component: () => import("@/views/user/StressCheckConnection.vue"),
        },
        {
          path: "user/stress_check_connection_complete",
          name: "stressCheckConnectionComplete",
          component: () =>
            import("@/views/user/StressCheckConnectionComplete.vue"),
        },
        {
          path: "user/edit",
          name: "userEdit",
          component: () => import("@/views/user/Edit.vue"),
        },
        {
          path: "user/edit/:eapUserId",
          name: "migrationUserEdit",
          component: () => import("@/views/user/Edit.vue"),
        },
        {
          path: "user/edit/complete",
          name: "userEditComplete",
          component: () => import("@/views/user/EditComplete.vue"),
        },
        {
          path: "user/secret_question/edit",
          name: "secret_question_edit",
          component: () => import("@/views/user/SecretQuestion.vue"),
        },
        {
          path: "user/password/edit",
          component: () => import("@/views/user/Password.vue"),
        },
        {
          path: "user/mail_address/change",
          component: () => import("@/views/user/MailAddressChange.vue"),
        },
        {
          path: "user/mail_address/change/:mailAddressAuthenticationToken([a-z0-9]{128})",
          component: () => import("@/views/user/MailAddressChangeComplete.vue"),
        },
        {
          path: "user/mail_receiving/change",
          component: () => import("@/views/user/MailReceivingChange.vue"),
        },
        {
          path: "user/language/edit",
          component: () => import("@/views/user/Language.vue"),
        },
        {
          path: "user_guide",
          component: () => import("@/views/UserGuide.vue"),
        },
        {
          path: "qa",
          component: () => import("@/views/Qa.vue"),
        },
        {
          path: "counseling/apply/counseling_type",
          component: () =>
            import("@/views/counseling/apply/CounselingType.vue"),
        },
        {
          path: "counseling/apply/:serviceModuleCode/channel",
          component: () => import("@/views/counseling/apply/Channel.vue"),
        },
        {
          path: "counseling/apply/:serviceModuleCode/:channelCode/application",
          name: "counselingApplication",
          component: () => import("@/views/counseling/apply/Application.vue"),
        },
        {
          path: "counseling/apply/:serviceModuleCode/:channelCode/application_confirm",
          name: "counselingApplicationConfirm",
          component: () =>
            import("@/views/counseling/apply/ApplicationConfirm.vue"),
        },
        {
          path: "counseling/apply/application_complete",
          name: "counselingApplicationComplete",
          component: () =>
            import("@/views/counseling/apply/ApplicationComplete.vue"),
        },
        {
          path: "counseling/online/list",
          name: "onlineCounselingList",
          component: () => import("@/views/counseling/online/List.vue"),
        },
        {
          path: "counseling/online/new",
          name: "onlineCounselingNew",
          component: () => import("@/views/counseling/online/New.vue"),
        },
        {
          path: "counseling/online/detail/:connectId",
          name: "onlineCounselingDetail",
          component: () => import("@/views/counseling/online/Detail.vue"),
        },
        {
          path: "counseling/online/course",
          name: "onlineCounselingCourse",
          component: () => import("@/views/counseling/online/Course.vue"),
        },
        {
          path: "counseling/online/guide",
          name: "onlineCounselingGuide",
          component: () => import("@/views/counseling/online/Guide.vue"),
        },
        {
          path: "counseling/history",
          name: "appliedCounselings",
          component: () => import("@/views/counseling/history/Index.vue"),
        },
        {
          path: "counseling/history/application/:caseId",
          name: "applicationDetail",
          component: () =>
            import("@/views/counseling/history/ApplicationDetail.vue"),
        },
        {
          path: "counseling/history/counseling",
          name: "counselingDetail",
          component: () => import("@/views/counseling/history/Detail.vue"),
        },
        {
          path: "/eap_self_care/self_check",
          name: "selfCheck",
          component: () => import("@/views/eap_self_care/self_check/Home.vue"),
        },
        {
          path: "/eap_self_care/self_check/mental_health/basicInformation",
          name: "basicInformationForMentalHealthCheck",
          component: () =>
            import(
              "@/views/eap_self_care/self_check/mental_health/BasicInformation.vue"
            ),
        },
        {
          path: "/eap_self_care/self_check/mental_health/survey",
          name: "SurveyAboutMentalHealthCheck",
          component: () =>
            import("@/views/eap_self_care/self_check/mental_health/Survey.vue"),
        },
        {
          path: "/eap_self_care/self_check/mental_health/result/:resultId",
          name: "ResultOfMentalHealthCheck",
          component: () =>
            import("@/views/eap_self_care/self_check/mental_health/Result.vue"),
        },
        {
          path: "/eap_self_care/self_check/mental_health/history",
          name: "historyOfMentalHealthCheck",
          component: () =>
            import(
              "@/views/eap_self_care/self_check/mental_health/History.vue"
            ),
        },
        {
          path: "projects",
          component: () => import("@/views/projects/stress_checks/Index.vue"),
          children: [
            {
              name: "exam_intention",
              path: ":projectCode/stress_checks/:stressCheckId/exam_intention/new",
              component: () =>
                import("@/views/projects/stress_checks/ExamIntention.vue"),
            },
            {
              name: "answers_new",
              path: ":projectCode/stress_checks/:stressCheckId/answers/new",
              component: () =>
                import("@/views/projects/stress_checks/Answers.vue"),
            },
          ],
        },
        {
          path: "not_published",
          component: () => import("@/views/NotPublished.vue"),
        },
        {
          path: "stress_checks",
          component: () => import("@/views/stress_checks/History.vue"),
          props: true,
        },
        {
          path: "/stress_checks/:stressCheckId",
          name: "stress_check_result",
          component: () => import("@/views/stress_checks/StressCheck.vue"),
        },
        {
          path: "monthly_topics/:contentsHeaderCode",
          name: "monthly_topics",
          component: () => import("@/views/contents/MonthlyTopics.vue"),
        },
        {
          path: "my_supplement/:contentsHeaderCode",
          name: "my_supplement",
          component: () => import("@/views/contents/MySupplement.vue"),
        },
        {
          path: "workplace_supplement/:contentsHeaderCode",
          name: "workplace_supplement",
          component: () => import("@/views/contents/WorkplaceSupplement.vue"),
        },
      ],
    },
    {
      path: "/user/new",
      component: () => import("@/views/user/new/Index.vue"),
      children: [
        {
          path: ":mailAddressAuthenticationToken",
          name: "newUser",
          component: () => import("@/views/user/new/New.vue"),
          props: true,
        },
        {
          path: "confirm",
          name: "newUserConfirm",
          component: () => import("@/views/user/new/NewConfirm.vue"),
        },
        {
          path: "complete",
          name: "newUserComplete",
          component: () => import("@/views/user/new/NewComplete.vue"),
        },
      ],
    },
    {
      path: "/admin",
      component: () => import("@/views/admin/Index.vue"),
      children: [
        {
          path: "",
          name: "admin_home",
          component: () => import("@/views/admin/Home.vue"),
          meta: {},
        },
        {
          path: "users",
          component: () => import("@/views/admin/users/Index.vue"),
          children: [
            {
              path: "",
              name: "admin_users",
              meta: {},
              component: () => import("@/views/admin/users/Users.vue"),
            },
            {
              path: ":userId/edit",
              name: "admin_user_edit",
              component: () => import("@/views/admin/users/UserEdit.vue"),
              meta: {},
            },
          ],
        },
        {
          path: "projects/:projectCode/users",
          component: () => import("@/views/admin/projects/Index.vue"),
          children: [
            {
              path: "",
              name: "projects_users",
              meta: {},
              component: () => import("@/views/admin/projects/Users.vue"),
            },
            {
              path: "bulk_new",
              component: () =>
                import("@/views/admin/projects/users/BulkNew.vue"),
            },
            {
              path: ":userId/edit",
              name: "projects_user_edit",
              component: () =>
                import("@/views/admin/projects/users/UserEdit.vue"),
            },
          ],
        },
        {
          path: "projects/:projectCode/email/new",
          component: () => import("@/views/admin/MailSendAll.vue"),
        },
        {
          path: "projects/:projectCode/individual_email",
          name: "individual_email",
          component: () => import("@/views/admin/MailTargetSearch.vue"),
        },
        {
          path: "projects/:projectCode/individual_email/new",
          name: "individual_email_new",
          component: () => import("@/views/admin/MailSendIndividual.vue"),
        },
        {
          path: "/admin/projects/:projectCode/reserved_emails",
          name: "reserved_emails",
          component: () => import("@/views/admin/ReservedMail.vue"),
        },
        {
          path: "/admin/projects/:projectCode/email_queues/:id/edit",
          name: "email_queues_edit_all",
          component: () => import("@/views/admin/MailEditAll.vue"),
        },
        {
          path: "/admin/projects/:projectCode/email_queues/:id/complete",
          name: "email_queues_complete",
          component: () => import("@/views/admin/MailComplete.vue"),
        },
        {
          path: "/admin/projects/:projectCode/individual_email_queues/:id/edit",
          name: "email_queues_edit_individual",
          component: () => import("@/views/admin/MailEditIndividual.vue"),
        },
        {
          path: "/admin/projects/:projectCode/users_by_anonymous_result",
          component: () => import("@/views/admin/AnonymousResult.vue"),
        },
        {
          path: "projects/:projectCode/setting",
          component: () => import("@/views/admin/projects/setting/Index.vue"),
          children: [
            {
              path: "email_splitting/edit",
              component: () =>
                import("@/views/admin/projects/setting/EmailSplittingEdit.vue"),
            },
          ],
        },
        {
          path: "projects/:projectCode/users_for_interview_necessity_decision",
          component: () =>
            import(
              "@/views/admin/projects/UsersForInterviewNecessityDecision.vue"
            ),
        },
        {
          path: "projects/:projectCode/users_for_interview_necessity_decision/email_new",
          name: "interview_necessity_decision_email_new",
          component: () =>
            import("@/views/admin/MailSendInterviewNecessity.vue"),
        },
        {
          path: "projects/:projectCode/users_for_interview_necessity_decision/complete",
          name: "users_for_interview_necessity_decision_complete",
          component: () =>
            import(
              "@/views/admin/projects/UsersForInterviewNecessityDecisionComplete.vue"
            ),
        },
        {
          path: "projects/:projectCode/admin_users",
          component: () =>
            import("@/views/admin/projects/admin_users/Index.vue"),
          children: [
            {
              path: "",
              name: "projects_admin_users",
              meta: {},
              component: () =>
                import("@/views/admin/projects/admin_users/AdminUsers.vue"),
            },
            {
              path: "new",
              name: "projects_admin_users_new",
              meta: {},
              component: () =>
                import("@/views/admin/projects/admin_users/AdminUserNew.vue"),
            },
            {
              path: ":userId/edit",
              name: "projects_admin_users_edit",
              meta: {},
              component: () =>
                import("@/views/admin/projects/admin_users/AdminUserEdit.vue"),
            },
          ],
        },
        {
          path: "projects/:projectCode/demouser",
          component: () =>
            import("@/views/admin/projects/demo_users/Index.vue"),
          children: [
            {
              path: "",
              name: "projects_demo_users",
              component: () =>
                import("@/views/admin/projects/demo_users/DemoUserNew.vue"),
            },
            {
              path: "complete",
              name: "projects_demo_users_complete",
              component: () =>
                import(
                  "@/views/admin/projects/demo_users/DemoUserComplete.vue"
                ),
            },
            {
              path: "delete/complete",
              name: "projects_demo_users_delete_complete",
              component: () =>
                import(
                  "@/views/admin/projects/demo_users/DemoUserDeleteComplete.vue"
                ),
            },
          ],
        },
        {
          path: "projects/:projectCode/project_labels/edit",
          name: "project_labels_edit",
          component: () => import("@/views/admin/LabelsEdit.vue"),
          meta: {},
        },
        {
          path: "projects/:projectCode/report",
          name: "report_report_setting",
          component: () => import("@/views/admin/projects/report/Index.vue"),
          children: [
            {
              path: "unit_code",
              name: "report_unit_code",
              component: () =>
                import("@/views/admin/projects/report/UnitCode.vue"),
            },
            {
              path: "sort_order",
              name: "report_sort_order",
              component: () =>
                import("@/views/admin/projects/report/SortOrder.vue"),
            },
            {
              path: "english",
              name: "report_english",
              component: () =>
                import("@/views/admin/projects/report/English.vue"),
            },
            {
              path: "comparison",
              name: "report_comparison",
              component: () =>
                import("@/views/admin/projects/report/Comparison.vue"),
            },
          ],
        },
        {
          path: "projects/:projectCode/report/download",
          name: "project_report_download",
          component: () => import("@/views/admin/projects/report/Download.vue"),
        },
        {
          path: "projects/:projectCode/report/cross_analytics",
          name: "project_report_cross_tab",
          component: () => import("@/views/admin/projects/report/CrossTab.vue"),
        },
        {
          path: "notification",
          component: () => import("@/views/admin/notification/Index.vue"),
          children: [
            {
              path: "",
              name: "notification_list",
              component: () => import("@/views/admin/notification/List.vue"),
            },
            {
              path: "new",
              name: "notification_new",
              component: () => import("@/views/admin/notification/New.vue"),
            },
            {
              path: ":notificationId/edit",
              name: "notification_edit",
              component: () => import("@/views/admin/notification/Edit.vue"),
            },
          ],
        },
        {
          path: "projects/:projectCode/anonymous_project_analytic_hierarchies",
          component: () =>
            import(
              "@/views/admin/projects/anonymous_project_analytic_hierarchies/Index.vue"
            ),
          children: [
            {
              path: "bulk_new",
              meta: {},
              component: () =>
                import(
                  "@/views/admin/projects/anonymous_project_analytic_hierarchies/Upload.vue"
                ),
            },
            {
              path: "reorder",
              meta: {},
              component: () =>
                import(
                  "@/views/admin/projects/anonymous_project_analytic_hierarchies/ReOrder.vue"
                ),
            },
            {
              path: "",
              meta: {},
              component: () =>
                import(
                  "@/views/admin/projects/anonymous_project_analytic_hierarchies/List.vue"
                ),
            },
          ],
        },
        {
          name: "stress_checks",
          path: "projects/:projectCode/stress_checks",
          component: () => import("@/views/admin/projects/StressChecks.vue"),
        },
        {
          path: "send-mail-history",
          name: "send_mail_history",
          component: () => import("@/views/admin/SendGridEvent.vue"),
        },
        {
          path: "password_bulk_reset",
          component: () => import("@/views/admin/PasswordBulkReset.vue"),
        },
      ],
    },
    {
      path: "/admin/stress_checks/:stressCheckId",
      name: "admin_stress_check_result",
      component: () => import("@/views/admin/StressCheck.vue"),
    },
    {
      path: "/inquiry",
      component: () => import("@/views/inquiry/Index.vue"),
      children: [
        {
          path: "new",
          name: "inquiry_new",
          component: () => import("@/views/inquiry/New.vue"),
          props: true,
        },
        {
          path: "confirm",
          name: "inquiry_confirm",
          component: () => import("@/views/inquiry/Confirm.vue"),
          props: true,
        },
        {
          path: "complete",
          component: () => import("@/views/inquiry/Complete.vue"),
        },
      ],
    },
    {
      path: "/wos_questionnaire",
      component: () => import("@/views/wos_questionnaire/Index.vue"),
      children: [
        {
          path: ":period/:hashedCode([a-z0-9]{128})",
          name: "wos_questionnaire_check",
          component: () => import("@/views/wos_questionnaire/CheckUrl.vue"),
        },
        {
          path: "input",
          name: "wos_questionnaire_input",
          component: () => import("@/views/wos_questionnaire/Input.vue"),
        },
        {
          path: "input_send",
          name: "wos_questionnaire_input_send",
          component: () => import("@/views/wos_questionnaire/InputSend.vue"),
        },
        {
          path: "complete",
          name: "wos_questionnaire_complete",
          component: () => import("@/views/wos_questionnaire/Complete.vue"),
        },
        {
          path: "period_end",
          name: "wos_questionnare_period_end",
          component: () => import("@/views/wos_questionnaire/PeriodEnd.vue"),
        },
        {
          path: "answered",
          name: "wos_questionnaire_answered",
          component: () => import("@/views/wos_questionnaire/Answered.vue"),
        },
      ],
    },
    {
      path: "/wos_mail_stop",
      component: () => import("@/views/wos_mail_stop/Index.vue"),
      children: [
        {
          path: ":hashedCode([a-z0-9]{128})",
          name: "wos_mail_stop_input",
          component: () => import("@/views/wos_mail_stop/Input.vue"),
        },
        {
          path: "complete",
          name: "wos_mail_stop_complete",
          component: () => import("@/views/wos_mail_stop/Complete.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/Index.vue"),
      children: [
        {
          path: "account/login",
          component: () => import("@/views/login/Account.vue"),
        },
        {
          path: "account/login/eap",
          name: "eap_login_only_group_login",
          component: () => import("@/views/login/EapLoginOnlyAccountLogin.vue"),
        },
        {
          path: ":hashedCode([a-z0-9]{128})",
          name: "account_hashed_code_login",
          component: () => import("@/views/login/AccountHashedCode.vue"),
        },
        {
          path: "group/login",
          name: "group_login",
          component: () => import("@/views/login/Group.vue"),
        },
        {
          path: "group/suppliers",
          name: "group_suppliers",
          component: () => import("@/views/login/Suppliers.vue"),
        },
        {
          path: "logout/:hashedCode([a-z0-9]{128})",
          name: "logout",
          component: () => import("@/views/Logout.vue"),
        },
        {
          path: "/personal_mail_address/complete/:language/:mailAddressAuthenticationToken([a-z0-9]{128})",
          component: () =>
            import("@/views/login/PersonalMailAddressRegisterComplete.vue"),
        },
      ],
    },
    {
      path: "/static",
      component: () => import("@/views/static/Index.vue"),
      children: [
        {
          path: "data_utilization_agreement_en",
          component: () =>
            import("@/views/static/DataUtilizationAgreementEn.vue"),
        },
        {
          path: "data_utilization_agreement_ja",
          component: () =>
            import("@/views/static/DataUtilizationAgreementJa.vue"),
        },
        {
          path: "terms_of_use_en",
          component: () => import("@/views/static/TermsOfUseEn.vue"),
        },
        {
          path: "terms_of_use_ja",
          component: () => import("@/views/static/TermsOfUseJa.vue"),
        },
        {
          path: "terms_of_use_zh",
          component: () => import("@/views/static/TermsOfUseZh.vue"),
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/Error.vue"),
    },
    {
      path: "/unauthorized",
      name: "unauthorized",
      component: () => import("@/views/Unauthorized.vue"),
    },
    {
      path: "/forbidden",
      name: "forbidden",
      component: () => import("@/views/Forbidden.vue"),
    },
    {
      path: "/conflict",
      component: () => import("@/views/Conflict.vue"),
    },
    {
      path: "/not_found",
      component: () => import("@/views/NotFound.vue"),
    },
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/NotFound.vue"),
    },
    {
      path: "/mark_sheet",
      component: () => import("@/views/mark_sheet/Index.vue"),
      children: [
        {
          path: "list",
          component: () => import("@/views/mark_sheet/List.vue"),
        },
        {
          path: "upload_download",
          name: "uploadDownload",
          component: () => import("@/views/mark_sheet/UploadDownload.vue"),
        },
      ],
    },
  ],
});

export default router
