<template>
  <header class="header" :class="{ 'is-active': isActiveUserInfo }">
    <router-link class="header__logo" to="/admin">
      SERVICE LOGO by PEACEMIND
    </router-link>
    <span class="header__sub">管理者専用システム</span>
    <div
      v-if="isAccountLoggedIn"
      class="user-info"
      :class="{ 'is-active': isActiveUserInfo }"
    >
      <div ref="userInfoName" class="user-info__name" @click="onClickUserInfo">
        <span v-if="isUserLoggedIn">{{ userName }} 様</span>
        <span v-else>未ログイン</span>
      </div>
      <div class="user-info__nav">
        <admin-navigation
          v-if="isNarrowLayout"
          ref="navigation"
          :enable-accordion="true"
          @on-click-user-info="onClickUserInfo()"
        />
        <div v-if="isUserLoggedIn" class="user-menu">
          <div class="user-menu__client-name">{{ userName }} 様</div>
          <ul class="user-menu-list">
            <li v-if="!(isUseSsoLogin && !loginUser.hasPmRole)">
              <router-link to="/user/password/edit">
                <div>アカウントセキュリティ<br />Account security</div>
              </router-link>
            </li>
            <li v-if="!(isUseSsoLogin && !loginUser.hasPmRole)">
              <router-link to="/user/secret_question/edit">
                <div>秘密の質問設定<br />Register secret question</div>
              </router-link>
            </li>
          </ul>
          <atom-button class="button--circle-text2" @click="onClickLogout">
            ログアウト<br />Logout
          </atom-button>
        </div>
        <div v-else class="user-menu">
          <atom-button class="button--circle-text2" @click="onClickLogin">
            ログイン<br />Login
          </atom-button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { hasAncestorNode } from "@/helpers/DOMHelper";
import { isUseSsoLogin } from "@/helpers/LoginRoute";
import { isNarrowLayout } from "@/helpers/DeviceHelper";

export default {
  name: "AdminHeader",
  data() {
    return {
      isActiveUserInfo: false,
      isNarrowLayout: false,
    };
  },
  computed: {
    ...mapState("auth", ["loginUser", "loading"]),
    ...mapGetters("auth", ["isAccountLoggedIn", "isUserLoggedIn"]),
    userName() {
      return this.isUserLoggedIn ? this.loginUser.displayName : "-";
    },
    isUseSsoLogin() {
      return isUseSsoLogin();
    },
  },
  mounted() {
    this.onWindowResize();
    document.addEventListener("click", this.onClickDocument, true);
    window.addEventListener("resize", this.onWindowResize);
    // 管理者画面はロケールを日本語に切替
    this.$i18n.userHeaderLocale = this.$i18n.locale;
    this.$i18n.locale = "ja";
  },
  unmounted() {
    document.removeEventListener("click", this.onClickDocument, true);
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onClickLogin() {
      this.$router.push("/login");
    },
    onClickUserInfo() {
      this.isActiveUserInfo = !this.isActiveUserInfo;
    },
    onClickLogout() {
      const accountHash = this.loginUser.accountHash;
      this.$api
        .post("logout")
        .then(() => this.$router.push(`/logout/${accountHash}`));
    },
    onClickDocument(event) {
      if (
        hasAncestorNode(event.target, this.$refs.userInfoName) ||
        (this.$refs.navigation &&
          hasAncestorNode(event.target, this.$refs.navigation.$el))
      ) {
        return;
      }
      this.isActiveUserInfo = false;
    },
    onWindowResize() {
      this.isNarrowLayout = isNarrowLayout();
    },
  },
};
</script>
<style lang="scss" scoped>
.button--circle-text2 {
  width: 150px;
  height: 35px;
  line-height: 100%;
}
</style>
