<template>
  <div class="mark">
    <div class="mark__item">
      <div class="mark_container">
        <a
          href="https://privacymark.jp/"
          target="_blank"
          rel="noopener noreferrer"
          class="pmark_link"
        >
          <img
            v-if="$i18n.locale == 'ja'"
            src="@/assets/img/pc/pmark_jp.png"
            alt=""
          />
          <img
            v-if="$i18n.locale != 'ja'"
            src="@/assets/img/pc/pmark_en.png"
            alt=""
          />
        </a>
      </div>
      <p>{{ $t("components.organisms.privacyMark.privacyMark") }}</p>
    </div>
    <div class="mark__item">
      <div class="mark_container">
        <img src="@/assets/img/pc/isms.svg" alt="" />
      </div>
      <p>
        {{ $t("components.organisms.privacyMark.isms") }}
      </p>
    </div>
    <div class="mark__item">
      <div class="mark_container">
        <img src="@/assets/img/pc/ssl.svg" alt="" />
      </div>
      <p>
        {{ $t("components.organisms.privacyMark.ssl") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyMark",
};
</script>

<style lang="scss" scoped>
.mark {
  margin-top: 64px !important;
}
.mark_container {
  width: 80px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mark__item a,
.mark__item img {
  width: auto;
  height: 100%;
}
.mark__item p {
  font-family: "Noto Sans JP", "Lato", sans-serif;
  font-size: 12px !important;
  line-height: 24px;
  color: #808080;
  margin-top: 16px;
}
.pmark_link::after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mark.far {
    margin-top: 36px !important;
  }
  .mark__item {
    align-items: center !important;
    gap: 16px;
  }
  .mark__item p {
    margin-top: 0;
  }
}
</style>
