import eol from "eol";

export default {
  functional: true,
  name: "NewlineText",
  props: {
    tag: {
      type: String,
      default: "p",
    },
    text: {
      type: String,
      default: undefined,
    },
    className: {
      type: String,
      required: false,
    },
  },
  render(createElement, context) {
    return createElement(
      context.props.tag,
      {
        class: context.props.className,
      },
      eol.split(context.props.text || "").reduce((accumulator, string) => {
        if (!Array.isArray(accumulator)) {
          return [accumulator, createElement("br"), string];
        }
        return accumulator.concat([createElement("br"), string]);
      }),
    );
  },
};
