<template>
  <section>
    <ul class="mt20">
      <li
        v-for="(notification, index) in notifications"
        :key="index"
        class="mt10"
      >
        <div class="textarea">{{ notification.body }}</div>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Notifications",
  data: () => {
    return {
      notifications: [],
    };
  },
  async created() {
    this.getNotifiations();
  },
  methods: {
    async getNotifiations() {
      const response = await this.$api.get("notifications");
      this.notifications = response.data;
    },
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
.textarea {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.7;
  font-size: 14px;
}
</style>
