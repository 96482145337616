<template>
  <validation-observer v-slot="{ invalid }" tag="div">
    <modal v-model:is-visible="isVisible">
      <template #body>
        <div
          v-html="
            replaceLineFeedCode(message.personalMailAddressRegisterStatement)
          "
        />
        <div class="registration">
          <form @submit.prevent="onSubmit">
            <dl>
              <div v-if="isRegister">
                <dt>
                  {{ message.personalMailAddressRegisterMailAddress }}
                </dt>
                <dd>
                  <atom-input
                    v-model="inputMailAddress"
                    name="inputMailAddress"
                    :placeholder="
                      message.personalMailAddressRegisterMailAddressPlaceholder
                    "
                    :rules="{ required: true, max: 255, email: true }"
                    :messages="{
                      required:
                        message.personalMailAddressRegisterVeeValidateRequired,
                      max: message.personalMailAddressRegisterVeeValidateMax,
                      email:
                        message.personalMailAddressRegisterVeeValidateEmail,
                    }"
                  />
                </dd>
                <dt>
                  {{ message.personalMailAddressRegisterConfirmMailAddress }}
                </dt>
                <dd>
                  <atom-input
                    v-model="inputMailAddressConfirm"
                    name="inputMailAddressConfirm"
                    :placeholder="
                      message.personalMailAddressRegisterMailAddressPlaceholder
                    "
                    :rules="{
                      required: true,
                      max: 255,
                      email: true,
                      match: inputMailAddress,
                    }"
                    :messages="{
                      required:
                        message.personalMailAddressRegisterVeeValidateRequired,
                      max: message.personalMailAddressRegisterVeeValidateMax,
                      email:
                        message.personalMailAddressRegisterVeeValidateEmail,
                      match:
                        message.personalMailAddressRegisterVeeValidateMatch,
                    }"
                  />
                </dd>
              </div>
              <div v-else>
                <dt>
                  {{ message.personalMailAddressChangeMailAddress }}
                </dt>
                <dd>
                  {{ personalMailAddress }}
                  <span @click="onClickDelete">
                    <a href="#">{{
                      message.personalMailAddressChangeDelete
                    }}</a>
                  </span>
                </dd>
              </div>
              <dt>
                {{ message.personalMailAddressRegisterBelongMailAddressLabel }}
              </dt>
              <dd>
                <div
                  v-if="
                    belongMailAddress != null && belongMailAddress != undefined
                  "
                >
                  {{ belongMailAddress }}
                </div>
                <div v-else>
                  {{
                    message.personalMailAddressRegisterBelongMailAddressUnregistered
                  }}
                </div>
              </dd>
            </dl>
            <div v-show="error.invalid" class="error-message">
              {{ message.personalMailAddressRegisterAddressError }}
            </div>
          </form>
        </div>
        <description>
          {{ message.personalMailAddressRegisterDescription }}
        </description>
      </template>
      <template #footer>
        <atom-button
          v-if="isRegister"
          :disabled="invalid"
          @click="onClickRegisterButton"
        >
          {{ message.personalMailAddressRegisterButtonRegister }}
        </atom-button>
        <atom-button
          v-if="isRegister"
          :is-primary="false"
          @click="onClickCancelButton"
        >
          {{ message.personalMailAddressRegisterButtonCancel }}
        </atom-button>
        <atom-button
          v-if="!isRegister"
          @click="onClickCloseButton"
        >
          {{ message.personalMailAddressChangeButtonClose }}
        </atom-button>
      </template>
    </modal>
  </validation-observer>
</template>

<script>
export default {
  props: {
    shows: {
      type: Boolean,
      required: true,
    },
    belongMailAddress: {
      type: String,
      default: undefined,
    },
    isRegister: {
      type: Boolean,
      default: true,
    },
    personalMailAddress: {
      type: String,
      default: undefined,
    },
    message: {
      type: {},
      default: undefined,
    },
  },
  emits: [
    "update:shows",
    "close",
    "click-register-button",
    "click-cancel-button",
    "click-delete",
    "click-close-button",
  ],
  data: () => {
    return {
      error: {
        invalid: false,
      },
      inputMailAddress: undefined,
      inputMailAddressConfirm: undefined,
    };
  },
  computed: {
    isVisible: {
      get() {
        return this.shows;
      },
      set(value) {
        this.$emit("update:shows", value);
        if (!value) {
          this.$emit("close", value);
          this.parameterReset();
        }
      },
    },
  },
  methods: {
    onSubmit() {
      this.error.invalid = false;
    },
    onClickRegisterButton() {
      this.$emit("click-register-button", this.inputMailAddress);
      this.parameterReset();
    },
    onClickCancelButton() {
      this.$emit("click-cancel-button");
      this.parameterReset();
    },
    onClickDelete() {
      this.$emit("click-delete");
    },
    onClickCloseButton() {
      this.$emit("click-close-button");
    },
    parameterReset() {
      this.inputMailAddress = undefined;
      this.inputMailAddressConfirm = undefined;
    },
    replaceLineFeedCode(targetData) {
      if (targetData) {
        return targetData.replace(/\n/g, "<br>");
      }
      return targetData;
    },
  },
};
</script>

<style lang="scss" scoped>
.registration form dl dd {
  margin: 0 0 12px 0;
  padding: 0;
  span {
    float: right;
    a {
      text-decoration: none;
    }
  }
}
</style>
