import { createStore } from 'vuex'

const debug = process.env.NODE_ENV !== "production";

import auth from "./auth";
import loading from "./loading";
import navigation from "./navigation";
import search from "./search";
import fieldSelection from "./fieldSelection";

export default createStore({
  modules: {
    auth,
    loading,
    navigation,
    search,
    fieldSelection,
  },
  strict: debug,
});
