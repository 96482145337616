export default {
  components: {
    atoms: {
      atomSelect: {
        noOptions: "No options available",
      },
    },
    molecules: {
      dataTable: {
        sampleSelect: "Display Records",
        outofNumberTotalBefore: "",
        outofNumberTotalAfter: "records in total",
        perPageOptions: "Records",
      },
      requiredMark: {
        required: "Required",
      },
    },
    organisms: {
      completedModal: {
        backToIndex: "Back to list",
        modalTitle: "Completed",
        modalMessage: "Completed.",
      },
      confirmModal: {
        modalTitle: "Confirm",
        modalOkButtonLabel: "YES",
        modalCancelButtonLabel: "NO",
      },
      errorModal: {
        modalTitle: "Error",
        modalButtonLabel: "Back to list",
        showMessage: " : error",
      },
      privacyMark: {
        privacyMark: "PEACEMIND Inc. obtains Privacy Mark.",
        isms: "PEACEMIND Inc. has obtained Information Security Management System (ISO/IEC27001) certification for its Employee Consulting and Assistance Department, SC Operation Promotion Department, and Information Systems Department (Information Security Committee) at the Ginza Headquarters.",
        ssl: "Information in transmission is encrypted and protected by Secure Sockets Layer (SSL).",
      },
      userFooter: {
        environment: "System Requirements",
        personalInformation: "Privacy Policy",
        informationSecurity: "Security Policy",
        termsOfUse: "Terms of Use",
        dataUtilizationAgreement: "Consent of data utilization",
        administrator: "Company",
        inquiry: "Contact",
      },
      userHeader: {
        memberToRegister: "Registration",
        login: "Log In",
        changeProfile: "Edit profile",
        changePassword: "Account security",
        changeMailAddress: "Change email address",
        registerSecretQuestion: "Set Secret Question",
        changeMailRecieving: "Mail Receiving Settings",
        logout: "Log Out",
      },
      userNavigation: {
        eap: "Counseling",
        cunselingApply: "Apply counseling",
        counselingHistory: "History of counseling",
        stressCheck: "Stress-Check",
        stressCheckTestHistory: "History of Stress-Check result",
        languageSetting: "Language setting",
        selfCareCheck: "Self-care Check",
        webLearning: "Web Learning (Japanese only)",
        column: "Columns",
        monthlyTopics: "Monthly Topics",
        mySupplement: "Tips for Self-care",
        workplaceSupplement: "Tips for Healthy Workplace",
        userGuide: "User's Guide",
        howToUseThisPlatform: "Terms of Use",
        faq: "FAQ",
        admin: "To administrator screen",
      },
    },
  },
  plugins: {
    veeValidate: {
      required: "Required combination",
      requiredSelect: "Select the required fields/sections",
      min: "More than {min} characters",
      max: "Less than {max} characters",
      digits: "Please enter {digits} characters",
      email: "Email address format is required",
      regex: "Please type using half-width characters.",
      passwordCharacterTypes:
        'Please use at least three of the following: uppercase letters, lowercase letters, numbers, and symbols（hyphen"-", underscore"_" only）.',
      numeric: "Number Only",
      integer: "Half-width number Only",
      max_value: "Less than {maxValue} characters",
      between: "Characters must be between {min} and {max}.",
      numberIsSmaller: "More than {number} characters",
      match: "{match} must be matched.",
    },
  },
  views: {
    login: {
      account: {
        groupLogin: "Company Login",
        groupId: "Company ID",
        password: "Password",
        loginFailure: "Login failed.",
        login: "Login",
      },
      group: {
        login: {
          groupLogin: "Group Enterprise Login",
          groupId: "GroupEnterpriseID",
          loginFailure: "Login information was incorrect.",
          login: "Login",
        },
        suppliers: {
          title: "Select your organization",
          groupId: "GroupEnterpriseID:",
        },
      },
      entrance: {
        honorificTitle: "Co., Ltd.",
        departmentSelect: "Please select your affiliation.",
      },
      user: {
        login: "Login",
        useService: "Service options",
        stressCheck: {
          name: "Stress Check",
          loginId: "Individual ID",
        },
        group: {
          name: "Counseling",
          loginId: "Mail address",
        },
        eap: {
          name: "Counseling",
          loginId: "Email",
        },
        useServiceAnnotation:
          "If you have agreed to the Stress Check information linkage, you can use both services regardless of which one you log in with.",
        password: "Password",
        passwordReset: "Password Reminder",
        userSelectModal: {
          modalTitle: "Select user",
          modalDescription: "Please select a user wish to log in as.",
          modalLoginId: "Individual ID",
          modalFullName: "Name",
        },
        noUserModal: {
          modalTitle: "Error",
          modalDescription:
            "User not found. Please contact your company's system administrator.",
          modalButtonLabel: "Close",
        },
        timeoutModal: {
          modalTitle: "Error",
          modalDescription: "Timeout occurred. Please try again.",
          modalButtonLabel: "Close",
        },
      },
    },
    counselings: {
      index: {
        title: "Counseling history",
        hierarchy: "Counseling history",
        onlineCounselingLink: {
          1: "Click ",
          2: "here",
          3: " for online counseling history",
        },
      },
      history: {
        title: "Counseling history",
        description:
          "All counseling sessions finished and scheduled are shown below.",
        table: {
          sessionDate: "Date of implementation",
          counseling: "Counseling",
          counselingDetail: "Counseling Details",
          canceled: "(Canceled)",
        },
      },
      detail: {
        title: "Counseling Details",
        hierarchy: "Counseling Details",
        counselingDetail: "Counseling Details",
        serviceClassification: "Service Category",
        channel: "Counseling channel",
        counselingDate: "Implementation Date",
        notImplemented: "Adjusting the schedule",
        reverseButton: "Back",
      },
      applicationList: {
        title: "Application history",
        description: "All applications via Web is shown below.",
        table: {
          counselingApplyDate: "Date of application",
          counseling: "Counseling",
          counselingDetail: "Counseling Details",
        },
        reverseButton: "TOP",
      },
      applicationDetail: {
        title: "Application details",
        hierarchy: "Application details",
        applicationDetail: "Application detailes",
        serviceClassification: "Service Category",
        channel: "Counseling channel",
        counselingApplyDate: "Application Date",
        fullName: "Full name",
        nameFurigana: "Name furigana",
        birthday: "Date of birth",
        sex: "Gender",
        phoneNumber: "Telephone number",
        mailAddress: "Email",
        employmentStatus: "Employment status",
        prefectures: "Prefecture you live in",
        trigger: "Reason for using the service",
        triggerDetail: "Reason for using the service (details)",
        desiredDateTime: "Preferred day and time",
        monday: "Monday",
        tuseday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        desiredPlace: "Preferred location",
        desiredOtherPlace: "Other desired places of implementation:",
        counselingContent: "Contents of concerns",
        outpatientTakeMedication: "Hospital visits and medication",
        reverseButton: "Back",
      },
      apply: {
        hierarchy: "Service application",
        counselingType: {
          hierarchy: "Service category",
          title: "Please select service category.",
          services: "Availablity of our service",
          counselingCategory: "Counseling",
          counselingCategoryStatement: "Conunseling/Consultation service",
          coachingCategory: "Wellness Coaching",
          coachingCategoryStatement:
            "For who would like to work on lifestyle changes such as quitting smoking or dieting.",
          noChoiceStatement:
            "You can’t apply because the number of consultations has reached the upper limit.",
          servicesStatement:
            "It is possible to use our service within the session times limitation.",
          counselingType: "Category",
          maxCounselingCount: "Sessiton times limitation",
          remainingSessionCount: "Available Session times",
          remainingCount: "Available Session times",
          voluntaryCounseling: "Self-referral Counseling",
          voluntaryCounselingStatement:
            "Click the above button for applying for consultation about your own concerns",
          encouragedCounseling: "Referral Counseling",
          encouragedCounselingStatement:
            "Click the above button for the counseling with referrals from supervisors or HR",
          managementCounseling: "Management Consultation",
          managementCounselingStatement:
            "For HR or Manager or Health Care Staff who would like to apply consultation about member/employee",
          wellnessCoachingVoluntary: "Self-referral Counseling",
          wellnessCoachingVoluntaryStatement:
            "For who would like to use counseling for your own concerns.",
          wellnessCoachingEncouraged: "Referral Counseling",
          wellnessCoachingEncouragedStatement:
            "Click the above button for the counseling with referrals from supervisors or HR",
          onlineCounseling: "Online Counseling",
          onlineCounselingStatement:
            "For who would like to use counseling by text",
          counselingNow: "If you want to use counseling service right now",
          directPhoneCounseling: "Hotline： ",
          directPhoneCounselingNote:
            `※ You are able to use mental health consultations for up to 30 minutes per session.<br />
            ※ You cannot specify a certain counselor, or switch from the counselor you have been connected to.<br />
            ※ The service is designed for one-time consultations, and it is not a platform for multiple consultations on the same issue.<br />
            ※ If you wish to use the service beyond the above specifications, recommendations or restrictions may be provided by mental health professionals based on their assessment.<br />
            ※ If the consultation line is busy, we may suggest scheduling a follow-up call or ask you to call back later. Thank you for your understanding.`,
          telephoneCounselingTime:
            "Mon - Sat：9:00 - 21:00（Except New Year holidays and public holiday）",
          telephoneCounselingTimeWith24Option:
            "Available 24 hours a day, 365 days a year",
          workLifeCategory: "Work-life Support",
          workLifeCounseling: "Work-life Support： ",
          workLifeStatement: {
            1: "Work Life Support is to provide appropriate and usable information on employee's work & life issues (elder care, child care, other matters in daily living) to meet individual needs promptly with fair point of view. Please feel free to access to find it useful for saving your private life.",
            2: "",
          },
          childCareInfo: {
            title: "●Child Care",
            statement: {
              1: "- To become a parent",
              2: "- Child raising and care",
              3: "- Parenting",
              4: "- Kids' health and disease",
              5: "- Kids who needs special care",
              6: "- Kids education",
            },
          },
          elderCareInfo: {
            title: "●Elder Care",
            statement: {
              1: "- Care for adults and elders",
              2: "- Care for the handicapped",
              3: "- Support for active seniors",
              4: "- Medical care for elders",
              5: "- Non-medical care for elders",
            },
          },
          dailyLivingInfo: {
            title: "●Daily Living",
            statement: {
              1: "- Local community",
              2: "- Housing",
              3: "- Medical facilities",
              4: "- Entertainment",
            },
          },
          workLifeNotes: {
            title: "<Notes>",
            statement: {
              1: "・Users information will not be disclosed to any third party without your permission. The confidentiality of users information will be strictly maintained, so please feel free to use the service.",
              2: `・This service is an information provision service provided as support for private issues (e.g. child care, nursing care, dailyl life). No action shall be taken that conflicts with the Medical Practitioners Act, Public Health Nurses, Midwives and Nurses Act, other applicable laws and regulations, or the guidance, guidelines and interpretations of the competent government agencies.`,
              3: "・The information provided to the users includes the name of the resource in question, contact information, and the type and price of the service provided.",
              4: "・Fees for the use of referred external services are to be borne by the user.",
            },
          },
          count: "sessions",
          endless: "Unlimited access",
        },
        channel: {
          apply: "Apply ",
          faceToFace: "Face-to-face counseling",
          faceToFaceStatement:
            "Through communication with your counselor, you will be able to face your worries and anxieties, and together we will work towards a solution. PEACEMIND offers counseling rooms with convenient access and a relaxed atmosphere that is easy for anyone to use.",
          faceToFaceOnline: "Video Phone counseling",
          faceToFaceOnlineStatement:
            "You can receive counseling at a time and place that is convenient for you by using the video phone service (Zoom). Despite being online, you can see the counselor's face in an atmosphere similar to face-to-face counseling.",
          telephone: "Telephone counseling",
          telephoneStatement:
            "You can receive a counseling session at a time and place that is convenient for you, using your home phone, cell phone, or any other phone you usually use. There is no need to use any special equipment.",
          faceToFaceWellness: "Face-to-face",
          faceToFaceStatementWellness:
            "This is face-to-face session with a counselor. PEACEMIND offers counseling rooms with convenient access and a relaxed atmosphere that is easy for anyone to use.",
          faceToFaceOnlineWellness: "Video Phone",
          faceToFaceOnlineStatementWellness:
            "You can receive coaching session at a time and place that is convenient for you by using the video phone service (Zoom). Despite being online, you can see the counselor's face in an atmosphere similar to face-to-face session.",
          telephoneWellness: "Telephone",
          telephoneStatementWellness:
            "You can receive a coaching session at a time and place that is convenient for you, using your home phone, cell phone, or any other phone you usually use. There is no need to use any special equipment.",
          telephoneCounseling: "Reservation by phone",
          telephoneCounselingNow:
            "If you want to use counseling service right now",
          telephoneCounselingTime:
            "Mon - Sat：9:00 - 21:00（Except New Year holidays and public holiday）",
          telephoneCounselingTimeNow:
            "Mon - Fri：9:00 - 18:00（Except New Year holidays and public holiday）",
          aboutCounselor: "About our counselors",
          aboutCounselorStatement: `We have experienced and highly specialized counselors who can help you with a variety of issues.
                                      <br />
                                      Our counselors, mainly clinical psychologists, mental health workers, and senior industrial counselors, have clinical experience as well as experience in corporate management and administration, and are well versed in the issues and concerns faced by corporate employees.
                                      <br />
                                      Of course, we are available for consultations not only on workplace issues, but also on family issues, educational issues, and any other issues that may arise in your personal life.
                                      <br />
                                      In addition, we have established our own quality standards, and by constantly checking the performance level, we are continuing our efforts to maintain and improve quality. Please be assured.
                                      <br />
                                      Our professional team will assign a counselor who is suitable for your needs.`,
          counselingRoom: "Our Locations",
          ginzaRoom: "Ginza",
          ginzaRoomAccess: `Access<br />
                            ・1-minute walk from Toei Asakusa Line Higashi-Ginza Station Exit A8<br />
                            ・3-minute walk from Tokyo Metro Hibiya Line Higashi-Ginza Station Exit A2<br />
                            ・3-minute walk from Tokyo Metro Hibiya Line & Marunouchi Line Ginza Station Exit A12<br />
                            ・3-minute walk from Tokyo Metro Yurakucho Line "Ginza-Itchome Station" Exit 11<br />
                            ・7-minute walk from JR Yurakucho Station`,
          ginzaRoomAddress: `Address<br />
                            ・Maruito-Ginza #3 Bldg. 8F, 3-10-6, Ginza, Chuo-ku, Tokyo<br />
                            ・104-0061`,
          osakaOffice: "Osaka",
          osakaOfficeAccess: `Access<br />
                              ・5-minute walk from Subway Midosuji Line Yodoyabashi Station Exit 13<br />
                              ・5-minute walk from Subway Midosuji Line Honmachi Station Exit 2`,
          osakaOfficeAddress: `Address<br />
                              ・803 Urbanex-Awajimachi Bldg. 4-4-11, Awajimachi, Chuo-ku, Osaka<br />
                              ・541-0047 `,
          affiliatedHospital:
            "Medical institutions and specialized organizations",
          affiliatedHospitalStatement:
            "We provide information on medical institutions and specialized organizations nationwide in accordance with the contents of the consultation. Please contact the Support Center (0120-741-489) for details.",
          back: "Back",
        },
        application: {
          hierarchyFaceToFace: "Face-to-face counseling",
          hierarchyFaceToFaceOnline: "Face-to-face counseling",
          hierarchyTelephone: "Telephone counseling",
          title: "Apply ",
          triggerLevel1: "Reason for using the service",
          triggerLevel1Placeholder: "Please select",
          otherTriggerLevel1Placeholder: "Please enter for other reasons",
          triggerLevel2: "Types of referrals (details)",
          triggerLevel2Placeholder: "Please select",
          otherTriggerLevel2Placeholder: "Please write about other referal.",
          preferredDate:
            "Preferred day of the week and time of the day for appointment",
          monday: "Monday",
          tuseday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          "10_14": "10:00 ~ 14:00",
          "14_18": "14:00 ~ 18:00",
          "18_21": "18:00 ~ 21:00",
          preferredPlace:
            "Preferred place for counseling session ※multiple answers allowed",
          ginza: "Ginza",
          nishishinjuku: "Nishi Shinjuku",
          tachikawa: "Tachikawa",
          kannai: "Kannai(Yokohama)",
          osaka: "Osaka(Yodoyabashi)",
          otherPlace: "Other place",
          otherPlacePlaceholder: "Please write other preferredd place.",
          description: "Outline of your primary concern",
          descriptionPlaceholder:
            "Briefly, please outline your primary consern(s). This information will help us assign a counselor.",
          attendClinicAndMedicine: "Hospital visits and medication",
          attendClinicAndMedicinePlaceholder:
            "If you are in medication, please notify name(s) of the medicine(s).",
          caution:
            "If you regularly see a psychiatrist, please have permission for counseling from the psychiatrist.",
          userPolicyStatement: {
            1: "I read, understood and agree with the ",
            2: ".",
          },
          userPolicy: {
            1: "Terms of Service",
            2: "Privacy Policy of PEACEMIND Inc",
          },
          confirm: "Confirm",
          back: "Back",
          confirmSave: "The content editing is not saved, is it all right?",
        },
        applicationConfirm: {
          hierarchy: "Comfirmation",
          title: "Comfirmation",
          send: "Send",
          back: "Back",
          confirmSave: "The content editing is not saved, is it all right?",
        },
        applicationComplete: {
          hierarchy: "Applying completed",
          title: "Applying completed",
          applicationCompleteStatement: {
            1: "Consultation application has been completed.",
            2: "Our support center will contact you shortly.",
          },
          backToTop: "Top Page",
        },
        online: {
          list: {
            hierarchy: "Text counseling",
            title1: "Text counseling",
            title2: "Start Text counseling",
            label1: `
              You can consult with a counselor online via text.
              <br />
              Depending on the content of your concern, we will assign a counselor who is best suited for you. The counselor will reply to you.
              <br />
              ※One response is allowed per new consultation.
            `,
            noChoiceStatement:
              "You can’t apply because the number of consultations has reached the upper limit.",
            hasCaseInProgressStatement: `
              Another session is still is still ongoing.
              <br />
              Only one online counseling can be used at the same time.
              <br />
              Please call the number below in case of hurry.
              <br />
              <br />
              0120-741-489
              <br />
              No-charge call
              <br />
              Mon - Sat：9:00 - 21:00（Except New Year holidays and public holiday）
            `,
            new: "Start Counseling",
            title3: "Text Counseling history",
            label2: "Display Records",
            listItem1: "Reply",
            listItem2: "Title of consultation",
            listItem3: "Date and time",
            listItem4: "Contents",
            listItem5: "Details",
            value1: "Not Replied",
            value2: "Replied",
            value3: "Details",
            label3: "",
            label4: "records in total",
            back: "Back",
          },
          detail: {
            hierarchy: "Counseling history",
            title1: "Your concerns.",
            label1: "Date of counseling",
            time1: "(JST)",
            title2: "Counselor's Answer",
            title3: "Counselor in charge",
            label2: "Date and of response",
            back: "Back",
          },
          new: {
            hierarchy: "Start text counseling",
            title1: "Start text counseling",
            item1: "Title",
            placeholder1:
              "Please enter a brief description of the your concerns.",
            item2: "Details of your concerns.",
            placeholder2: "Please enter the details of your concerns.",
            remaining: "Characters remaining：",
            character: "",
            send: "Send",
            back: "Back",
            modal: {
              confirm: {
                title: "Confirmation",
                label:
                  "Your request will be submitted with the information you have entered. Is it OK to proceed?",
                ok: "OK",
                cancel: "Cancel",
              },
              complete: {
                title1: "Completed",
                label1: "Your request has been submitted.",
                label2:
                  "A counselor will be assigned and respond to your concerns.",
                label3:
                  "Our support center will select the most suitable counselor based on the details of your concerns within 2 business days after submission.Please wait for a while, as you will receive an email with information after your contact person is selected.",
                back: "Back",
              },
            },
          },
          course: {
            hierarchy: "Questions",
            title1: "Online Counseling",
            title2: "Questions before starting online counseling",
            label1:
              "As part of using the service, please answer the following questions. Click either“Yes” or “No.”",
            label2: "*The response in these questions is required to proceed.",
            item1: "Are you having suicidal thoughts?",
            item2:
              "Are you thinking of carrying out aggressive behavior, or desire to do so?",
            item3:
              "Are you consulting with us about   someone showing aggressive   behavior (domestic violence,   harassment,etc.) towards you?",
            item4:
              "Are you consulting with us about issues related toalcohol or gambling?",
            item5:
              "If you are consulting with us about raising children, haveyou ever carried out aggressive behavior towards your child/children?",
            value1: "YES",
            value2: "NO",
            confirm: "Confirm",
            back: "Back",
          },
          guide: {
            hierarchy: "Recommendation",
            title1: "Text Counseling",
            title2: "Recommendation",
            label1:
              "Based on the answer of the “Questions before starting online counseling”, we would like to ask about your concern carefully. Please call the number below and let us know outline of your primary concern.",
            label2: "No-charge call.",
            label3:
              "Mon - Sat：9:00 - 21:00（Except New Year holidays and public holiday）",
            label4:
              "If there is a possibility of life crisis, please contact urgent medical institutions, fire stations or police stations nearby.",
            back: "Back",
          },
        },
      },
    },
    inquiry: {
      conplete: {
        title: "Completed",
        message: "Inquiry submission completed",
        backToTop: "TOP",
      },
      confirm: {
        title: "Confirmation",
        fullName: "Full Name",
        fullNameKana: "Name (katakana)",
        inquiryMethod: "Preferred contact method",
        mailAddress: "Email",
        phone: "Phone",
        other: "Other",
        requestForContact: "Contact Preferences",
        contents: "Category of inquiry",
        contentsDetail: "Specifics of your inquiry",
        userPolicyDescription: {
          1: "I agree on handling of our",
          2: "",
        },
        userPolicy: "personal information",
        sendButton: "Send",
        backButton: "Cancel",
        confirmSave: "The content editing is not saved, is it all right?",
      },
      new: {
        title: "Inquiry Form",
        fullName: "Name",
        lastName: "Family name",
        firstName: "Given name",
        fullNameKana: "Name (katakana)",
        lastNameKana: "Family name",
        firstNameKana: "Given name",
        inquiryMethod: "Preferred contact method",
        mailAddress: "Email",
        phone: "Phone",
        other: "Other",
        requestForContact: "Contact Preferences",
        contents: "Inquiry contents",
        contentsDetail: "Details of your inquiry",
        userPolicyDescription: {
          1: "I agree to ",
          2: ".",
        },
        userPolicy: "Pravacy Policy.",
        confirmButton: "Next",
        confirmSave: "The content editing is not saved, is it all right?",
      },
    },
    passwordReminder: {
      passwordReminder: {
        completeModal: {
          messageRow: {
            1: "An email has been sent to the entered email address with URL for resetting the password.",
            2: "Please check the email and access the URL.",
            3: "Please note that URL is valid for 24 hours after published.",
          },
          okButton: "OK",
        },
        title: "Password Reminder",
        useService: "Service options",
        stressCheck: {
          name: "Stress Check",
          loginId: "Individual ID",
        },
        eap: {
          name: "EAP",
          loginId: "E-mail address",
        },
        invalidLoginId: "Your Individual ID is invalid.",
        notExistsSecretQuestionAnswer: {
          messageRow: {
            1: "The function is not available until the first login steps are completed.",
            2: "Please login with your initial password which is provided by your organization/company via email etc.",
          },
        },
        invalidMailAddress: "Entered email address is invalid.",
        descriptionStressCheck:
          "※Please use the Individual ID that your organization provided to you.",
        descriptionEap:
          "※Please use the email address that your company provided.",
        okButton: "OK",
      },
      passwordReminderEmail: {
        completeModal: {
          messageRow: {
            1: "An email has been sent to the entered email address with URL for resetting the password.",
            2: "Please check the email and access the URL.",
            3: "Please note that URL is valid for 24 hours after published.",
          },
          okButton: "OK",
        },
        title: "Enter your email address",
        mailAddress: "Email address",
        invalidMailAddress: "The entered email address is invalid.",
        description: "※Please use the mail address that your company provided.",
        okButton: "OK",
      },
      passwordReminderPassword: {
        completeModal: {
          message: "Your password has changed successfully",
          loginButton: "OK",
        },
        title: "Password Setting",
        newPassword: "New Password",
        description: {
          messageRow: {
            1: "Password must be at least 12 characters long and uses characters from 3 of the 4 following groups:",
            2: "1) Uppercase letters（A to Z）",
            3: "2) Lowercase letters（a to z）",
            4: "3) Numbers（0 to 9）",
            5: '4) Symbols（hyphen"-", underscore"_" only）',
          },
        },
        updateButton: "OK",
        invalidCharactorType:
          'Please use at least three of the following: uppercase letters, lowercase letters, numbers, and symbols（hyphen"-", underscore"_" only）.',
        invalidLengthPassword: "Characters must be between 12 and 255.",
        differentPassword:
          'Please set a password different from the "current password".',
      },
      passwordReminderSecretQuestion: {
        title: "Answer your secret question",
        secretQuestion: "Secret question",
        secretQuestionAnswer: {
          row: {
            1: "Answer your",
            2: "secret question",
          },
          placeholder: "Answer your Secret Question",
        },
        invalidAnswer: "The answer is incorrect.",
        okButton: "OK",
      },
    },
    user: {
      account: {
        password: {
          completeModal: {
            title: "Record updated",
            message: "Completed",
            okButton: "OK",
          },
          title: "Password Setting",
          currentPassword: "Current Password",
          newPassword: "New Password",
          invalidCharactorType:
            'Please use at least three of the following: uppercase letters, lowercase letters, numbers, and symbols（hyphen"-", underscore"_" only）.',
          invalidLengthPassword: "Characters must be between 12 and 255.",
          invalidCurrentPassword:
            "The entered password does not match with the registered password.",
          differentPassword:
            'Please set a password different from the "current password".',
          okButton: "OK",
          description: {
            messageRow: {
              1: "Password must be at least 12 characters long and uses characters from 3 of the 4 following groups:",
              2: "1) Uppercase letters（A to Z）",
              3: "2) Lowercase letters（a to z）",
              4: "3) Numbers（0 to 9）",
              5: '4) Symbols（hyphen"-", underscore"_" only）',
            },
          },
        },
        twoFactorAuthentication: {
          title: "Two-factor authentication",
          reset: "Reset",
          sms: {
            statement: {
              1: "Two-factor authentication via SMS enabled.",
            },
          },
          totp: {
            statement: {
              1: "Two-factor authentication via authentication app enabled.",
              2: "If you are unable to access (via a smartphone with an authentication app installed, or otherwise), you can authenticate with a recovery code.",
              3: "Store these text codes in a secure location.",
            },
            openCodeLink: "Show recovery code",
            used: "Used",
          },
        },
      },
      mailAddress: {
        completeModal: {
          messageRow: {
            1: "URL for setting new email address was sent.",
            2: "Please check your email.",
            3: "The URL link will expire in 30 minutes.",
          },
          okButton: "OK",
        },
        title: "Change email address",
        currentMailAddress: "Current email address",
        newMailAddress: "New email address",
        newMailAddressPlaceholder: "sample{'@'}mail.com",
        errorMessage:
          "The email address you entered is not available because it has already been registered or the email is invalid.",
        okButton: "OK",
        reSendButton: "Resend",
        description: {
          messageRow: {
            1: "Depending on the mail software, there is a possibility that an email from our system will be categorized as spam. If you can't find the email, please check your spam folder.",
          },
        },
      },
      mailAddressComplete: {
        complete: {
          title: "Change email address completed",
          statement: {
            1: "The email address has been changed.",
            2: "Please login again with new email address.",
          },
          movePage: "Login page",
        },
        error: {
          title: "Change email address",
          statement: {
            1: "Email change is failed.",
            2: "Due to the expiration of the URL.",
            3: "Please try the email change process again.",
          },
          okButton: "OK",
        },
      },
      secretQuestion: {
        title: "Register your Secret Question",
        secretQuestion: "Secret Question",
        answer: "Answer",
        secretQuestionAnswer: "Answer your Secret Question",
        okButton: "OK",
        description: {
          messageRow: {
            1: "※Your secret question will be useful when you forget your password.",
            2: "If you enter more than one questions, then the last one you entered will be registered.",
            3: "When you answer the survey, your secret question will not be needed. Both Your Individual ID and your password verify you.",
          },
        },
        completeModal: {
          title: "Record updated",
          message: "Completed",
          okButton: "OK",
        },
      },
      userTop: {
        troubleUp: "Are you experiencing",
        troubleUnder: "any problems right now?",
        consultationApplication: "Click here to apply for counseling",
        noticeList: "News",
        monthlyTopics: "Monthly Topics",
        workplaceSupplement: "Resouces",
      },
      mailAddressAuthentication: {
        title: "Register with email address",
        statement: {
          1: "You will be registered as a member of the counseling service. Please note that this is not a Stress Check membership registration.",
          2: "Please enter the email address to be registered and submit the form.",
        },
        note: {
          title: "*Please note:",
          1: "A cell phone number is required for completing the registration. If you do not have a cell phone, we are unable to provide you with access to this EAP service website.",
          2: "The URL in the email we sent you expires in 30 minutes. if you have not registered as a member after 30 minutes, it will be invalid.",
          3: "Depending on your carrier's email software, the notification emails may be classified as spam, or may be set to be rejected. If you do not receive the email, please check your spam folder and settings.",
        },
        mailAddress: "Email address",
        mailAddressConfirm: "Email address (for confirmation)",
        invalidMailAddress:
          "The email address you have entered has already been used.",
        unusableMailAddress: "The email address entered is not available.",
        send: "Send",
      },
      mailAddressAuthenticationComplete: {
        title: "Email has been sent.",
        statement: {
          1: {
            1: "A confirmation email with the URL for registration has been sent to your registered email address (",
            2: ").",
          },
          2: "Please confirm the contents and register. It may take a few minutes to receive email for registration.",
          3: "If you are not receiving the email, please click below to resend it.",
        },
        resend: "Resend email",
        resendMessage: "The email with the registration URL has been resent.",
        backToTop: "Top Page",
      },
      new: {
        title: "Registration",
        statement: "Please fill in all fields below.",
        caution: `
          Notice: If you have used our service, some input items is filled with the information you registered at that time.<br />
          If there are any changes with them, please edit them and go to confirm.
        `,
        password: "Password",
        passwordConfirm: "Password (confirm)",
        passwordPlaceholder: "Password",
        lastName: "Family name",
        lastNamePlaceholder: "Family name",
        firstName: "Given name",
        firstNamePlaceholder: "Given name",
        lastNameKana: "Family name (katakana)",
        lastNameKanaPlaceholder: "セイ",
        firstNameKana: "Given name  (katakana)",
        firstNameKanaPlaceholder: "メイ",
        sex: "Gender",
        otherSexPlaceholder:
          "Please enter if you would like to add comments about gender.",
        birthday: "Date of birth",
        birthdayPlaceholder: "1990/01/01",
        birthdayYearPlaceholder: "1990",
        birthdayMonthPlaceholder: "1",
        birthdayDayPlaceholder: "1",
        mobilePhoneNumber: "Telephone number",
        countryCodePlaceholder: "Please select.",
        mobilePhoneNumberPlaceholder: "Telephone number with no hyphens.",
        prefecture: "Prefecture you live in",
        prefecturePlaceholder: "Please select.",
        address: "City",
        addressPlaceholder: "Please enter the city you live in.",
        employeeFamily: "Individuals approved by the organization",
        sendMailMagazine: "Email newsletter",
        sendMailMagazineStatement: "Would you like to receive our newsletter?",
        userPolicyStatement: {
          1: "I agree to the ",
          2: ".",
        },
        userPolicy: {
          1: "Terms of Use",
          2: " and ",
          3: "Privacy Policy",
        },
        useDataAgreementStatement: {
          1: "To improve our services, we utilize the personal information data you provide with anonymization and share it with the third party.",
          2: "I agree to the data ulitization policy.",
        },
        useDataAgreement: "Data ulitization policy",
        description: {
          messageRow: {
            1: "Password must be at least 12 characters long and uses characters from 3 of the 4 following groups:",
            2: "1) Uppercase letters（A to Z）",
            3: "2) Lowercase letters (a to z)",
            4: "3) Numbers (0 to 9)",
            5: '4) Symbols（hyphen"-", underscore"_" only）',
          },
        },
        confirm: "Confirm",
        confirmSave: "The content editing is not saved, is it all right?",
      },
      newConfirm: {
        title: "Confirmation",
        send: "Confirm",
        back: "Back",
        confirmSave: "The content editing is not saved, is it all right?",
        invalidCharactorType:
          'Please use at least three of the following: uppercase letters, lowercase letters, numbers, and symbols（hyphen"-", underscore"_" only）.',
        invalidLengthPassword: "Characters must be between 12 and 255.",
      },
      newComplete: {
        title: "Resistration Completed",
        statement: {
          1: "Resistration Completed",
          2: "Please login from URL below.",
        },
        login: "Login",
      },
      twoFactorAuthenticationMethod: {
        title: "Setting of two-factor authentication",
        statement:
          "In addition to your ID and password setting, the use of SMS and authenticator apps can enhance the security of your account.",
        sms: {
          label: "I choose an SMS authentication.",
          description: {
            1: "A code is to be sent via SMS to your cell phone to enter for authentication.",
            2: "※If you are using a mobile phone number outside Japan, you may not be able to receive SMS in some countries.",
          },
        },
        totp: {
          label: "I choose an authentication app.",
          description:
            "A code is to be sent to an authentication app on your smartphone to enter for authentication.",
        },
        application: "The following authentication apps are recommended.",
        nextButton: "Next",
      },
      twoFactorAuthentication: {
        title: "Setting for SMS-based two-factor authentication",
        send: "Send SMS",
        cancel: "Cancel",
        statement: {
          0: "SMS has been sent to +",
          1: ".",
          2: `Please enter the 6 figures listed in the message within 5 minutes.
                          <br />
                          If you do not receive an SMS, the cell phone number you entered may be incorrect.
                          <br />
                          Please enter your cell phone number again by clicking the 'Resend' button.`,
          3: `We will use SMS (short message service) on your cell phone for authentication.
                          <br />
                          Your cell phone number will not be disclosed to any third parties.`,
        },
        twoFactorAuthenticationNumberPlaceholder: "6 figures",
        countryCodePlaceholder: "Please select.",
        mobilePhoneNumberPlaceholder: "Telephone number with no hyphens.",
        invalidSmsNumber: `Authentification failed due to invalid number.
                        <br />
                        You may have the wrong cell phone number, or the entered code may have expired.`,
        authenticate: "Authenticate",
        changeMobilePhoneNumber: "Resend",
        totpLink: {
          1: `※If you are using a mobile phone number outside Japan, you may not be able to receive SMS in some countries. If you do not receive the SMS, please use `,
          2: "two-factor authentication with the app.",
          3: "",
        },
      },
      twoFactorAuthenticationComplete: {
        title: "Two-factor Authentication Completed",
        statement: "Two-factor authentication completed.",
        ok: "OK",
      },
      twoFactorAuthenticationTotp: {
        title:
          "Setting for two-factor authentication using an authentication app",
        statement1: {
          1: "Please read the image below using the two-factor authentication app on your smartphone. If the QR code cannot be scanned, please enter .",
          2: "this text code",
          3: " instead",
        },
        statement2:
          "The app will display a code after scanning the QR code. Then please enter it below.",
        invalidOneTimePassword: "Invalid number. Unable to authenticate.",
        nextButton: "Next",
        cancelButton: "Cancel",
        textCodeModal: {
          title: "Code",
          statement: {
            1: "Please enter the code below onto the authentication app.",
            2: "Do not disclose the code.",
          },
          closeButton: "Close",
        },
      },
      twoFactorAuthenticationTotpComplete: {
        title: "Save recovery code",
        statement: {
          1: "If you are unable to access (via a smartphone with an authentication app installed, or otherwise), you can authenticate with a recovery code. ",
          2: "Store the below text codes in a secure location.",
        },
        completeButton: "Done",
      },
      stressCheckConnection: {
        title: "Information linkage with Stress Check",
        statement: {
          1: "Do you want to link stress check information?",
          2: `If you do not wish to be linked, or if you have never taken the stress check before, please click the "Not now" button.`,
          3: `Please enter your Stress Check ID and password, and click the "Submit" button.`,
          4: `Please login to Stress Check.`,
        },
        merit: {
          1: `After clicking the "Link" button, both the stress check and counseling menus will appear on the screen after logging in and will be available for use.`,
          2: `In addition, the next time you log in, both menus will be displayed as described above, regardless of whether you choose "Stress Check" or "Counseling" on the login screen.`,
        },
        scLoginId: "Stress Check Indivisual ID",
        scLoginIdPlaceholder: "Stress Check Indivisual ID",
        scLoginPassword: "Stress Check Password",
        scLoginPasswordPlaceholder: "Stress-check Password",
        invalidScUser: `Linkage failed
                          <br />
                          Your Stress Check login ID or password may be incorrect.`,
        noScHistory: `Your Stress Check has not been conducted yet. Please login to Stress Check from the login page and link again after taking the assessment.`,
        duplicatedScUser: `Cannot be connected because the user has already connected with another account.`,
        agree: "Link",
        disagree: "Not now",
        send: "Submit",
        back: "Back",
      },
      stressCheckConnectionComplete: {
        title: "Linkage Completed",
        statement: "Linkage Completed.",
        ok: "OK",
      },
      edit: {
        title: "Edit Profile",
        statement:
          "You will not be able to edit your profile until the ongoing consultation is complete.",
        migrationUserStatement:
          "Some information required to use the service has changed. Please fill in all fields below.",
        mailAddress: "Email",
        mailAddressPlaceholder: "Email",
        lastName: "Family name",
        lastNamePlaceholder: "Family name",
        firstName: "Given name",
        firstNamePlaceholder: "Given name",
        lastNameKana: "Family name (katakana)",
        lastNameKanaPlaceholder: "セイ",
        firstNameKana: "Given name  (katakana)",
        firstNameKanaPlaceholder: "メイ",
        sex: "Gender",
        otherSexPlaceholder: "What's your gender?",
        birthday: "Date of birth",
        birthdayPlaceholder: "1990/01/01",
        birthdayYearPlaceholder: "1990",
        birthdayMonthPlaceholder: "1",
        birthdayDayPlaceholder: "1",
        mobilePhoneNumber: "Telephone number",
        countryCodePlaceholder: "Please select.",
        mobilePhoneNumberPlaceholder: "Telephone number with no hyphens.",
        prefecture: "Prefecture you live in",
        prefecturePlaceholder: "Please select.",
        address: "City",
        addressPlaceholder: "City",
        employeeFamily: "Individuals approved by the organization",
        sendMailMagazine: "Email newsletter",
        sendMailMagazineStatement: "Would you like to receive our newsletter?",
        userPolicyStatement: {
          1: "I agree to the ",
          2: ".",
        },
        userPolicy: {
          1: "Terms of Use",
          2: " and ",
          3: "Privacy Policy",
        },
        useDataAgreementStatement: {
          1: "To improve our services, we utilize the personal information data you provide with anonymization and share it with the third party.",
          2: "I agree to the data ulitization policy.",
        },
        useDataAgreement: "Data ulitization policy",
        update: "Update",
      },
      editComplete: {
        title: "Edit Completed",
        statement: "Edit Completed",
        ok: "OK",
      },
      mailReceivingChange: {
        title: "Mail Receiving Settings",
        useMail: {
          subject: "Email to use the service",
          description: {
            1: "This email is required to use the service.",
            2: "You cannot stop receiving this email.",
          },
        },
        mailMagazine: {
          subject: "Email magazine",
          description: "This email is for topics related to mental health.",
        },
        questionnaireMail: {
          subject: "Survey Email",
          description:
            "This is an email regarding a survey for research purposes at our company.",
        },
        effectMeasurementMail: {
          subject: "Counseling Effectiveness Measurement Email",
          description:
            "This is an email to measure the condition before and after the use of counseling.",
        },
        saveButton: "Save",
        completedModal: {
          title: "Update completed",
          message: "Email receipt settings have been updated.",
          button: "OK",
        },
      },
    },
    logout: {
      logoutMessage: "Logged out successfully.",
      sendTopPageButton: "Client TOP Page",
    },
    howToUseThisPlatform: {
      tabName: {
        sc: "Stress Check",
        eap: "Counseling",
      },
      counseling: {
        tabName: "Counseling",
        title: "Counseling service guides",
        statement: {
          1: "You can see how to use our counseling service.",
          2: "FAQ",
          3: "might also help you.",
        },
      },
    },
    qa: {
      modal: {
        title: "FAQ",
        closeButton: "close",
      },
      title: "FAQ",
      category: "Category",
      keyword: "Keyword",
      searchButton: "Search",
      searchCancelButton: "Search cancellation",
      stressCheck: "Stress Check",
      eap: "EAP",
      hasDataError: "There is no object",
      hierarchy: {
        display: "FAQ",
      },
    },
    wos: {
      questionnaire: {
        title: "Please take this survey",
        inputError: "",
        statement: {
          top: {
            1: "This survey is conducted to measure the effectiveness of EAP services for individuals who are currently employed and not on long-term leave.",
            pre: "Please provide information about your condition before receiving counseling.",
            post: "Please provide information about your condition after receiving counseling.",
          },
          bottom: "Thank you for your cooperation.",
        },
        nextButton: "Next",
        backButton: "Back",
        sendButton: "Confirm",
        question: {
          q: "Q",
          workingStatusC:
            ". Did you work any part of your normal work schedule in the past 30 days?",
          problemSolvingLevelC:
            ". If your original concern was 10, how much of a concern is it now?",
          satisfactionC: ". Are you satisfied with the consultant's response?",
          recentStatus:
            ". Please tell us about your condition during the past month. Please choose the one that best describes your condition.",
          lifeSatisfactionC: ": So far,my life seems to be going very well.",
          approachToWorkC:
            ": I am often eager to get to the work site to start the day.",
          presentismC:
            ": My personal problems kept me from concentrating on my work.",
          painAtWorkC: ": I dread going in to work",
          absentismC:
            ". How much time did your personal concern cause you to miss work during the past 30 days?   Include complete work days and partial days when you came in late or left early.  Please choose the category that best represents the total hours of absence you experienced (if any):",
          jobPerformanceC:
            ". On a scale of 0 to 10, where 0 is the worst job performance anyone could have at your job and 10 is the performance of a top worker, how would you rate your overall job performance on the days you worked during the past 30 days?",
        },
        answer: {
          workingStatusC: {
            yes: "Yes",
            no: "No, did not work at all",
          },
          problemSolvingLevelC: {
            lightened: "Lightened",
            same: "Same",
          },
          jobPerformanceC: {
            worst: "Worst Performance",
            top: "Top Performance",
          },
        },
        errors: {
          1: "There are fields that have not been entered.",
          2: " is not entered.",
        },
        complete: {
          title: "Survey Completed",
          statement: "Thank you for cooperating in completing the survey.",
          closeButton: "Close",
        },
        periodEnd: {
          statement: "The deadline for answering the survey has passed.",
          closeButton: "Close",
        },
        answered: {
          statement: "This survey has been answered.",
          closeButton: "Close",
        },
      },
      mailStop: {
        title: "Mail Receiving Settings",
        settingItem: "Counseling Effectiveness Measurement Email",
        description:
          "This is an email to measure the condition before and after the use of counseling.",
        saveButton: "Save",
        complete: {
          title: "Email receipt setting change completed.",
          statement: {
            on: `Counseling survey emails have been changed to "Receive".`,
            off: `Counseling survey emails have been changed to "Not receive".`,
          },
          closeButton: "Close",
        },
      },
    },
  },
};
