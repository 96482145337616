export default {
  params: ["key", "itemList"],
  validate(value, [ key, itemList ]) {
    if (!value || !itemList) {
      return true;
    }
    // 半角数値変換処理
    var toInteger = function (value) {
      if (value == null) {
        return -1;
      }
      // もともと数値の場合があるためString変換
      value = value.toString();
      // 全角数字を半角に変換
      var num = value.replace(/[０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 65248);
      });
      // 整数に変換
      num = parseInt(num, 10);
      // 整数にパース出来なかったときは-1を返す(ユニットコードはマイナスの値はありえない)
      return isNaN(num) ? -1 : num;
    };

    // 第２階層以降はリストのリストのため、flat化
    // IE11でflatが使用不可のためreduce、concatで代用 2021/7/7
    // 自分以外で重複があればNG
    return !Object.values(itemList)
      .reduce((acc, val) => acc.concat(val), [])
      .some(
        (item) =>
          item.id != key && toInteger(item.reportUnitCode) === toInteger(value),
      );
  },
};
