<template>
  <header class="user-header" :class="{ 'is-active': isActiveUserInfo }">
    <router-link v-if="isShowTitleLogo" class="user-header__logo" to="/">
      SERVICE LOGO by PEACEMIND
    </router-link>
    <router-link v-else class="user-header__text" to="/">
      {{ loginUser.siteTitle }}
    </router-link>
    <div class="user-info" :class="{ 'is-active': isActiveUserInfo }">
      <div class="user-language">
        <a :style="userLanguageStyleJp" @click="onClickChangeLanguage('ja')">
          日本語
        </a>
        &nbsp;|&nbsp;
        <a :style="userLanguageStyleEn" @click="onClickChangeLanguage('en')">
          English
        </a>
        &nbsp;|&nbsp;
        <a :style="userLanguageStyleZh" @click="onClickChangeLanguage('zh')">
          中文
        </a>
      </div>
      <div v-if="isAccountLoggedIn" class="menu-button">
        <div v-if="isAccountLoginPage" />
        <div
          v-else-if="isNotNarrowAndNotUserLoggedIn && isEapContract"
          ref="userInfoName"
        >
          <span>
            <atom-button
              class="button--circle-text2"
              @click="onClickMemberToRegister"
            >
              {{ $t("components.organisms.userHeader.memberToRegister") }}
            </atom-button>
            &nbsp;
            <atom-button class="button--circle-text2" @click="onClickLogin">
              {{ $t("components.organisms.userHeader.login") }}
            </atom-button>
          </span>
        </div>
        <div v-else-if="isNotNarrowAndNotUserLoggedIn" ref="userInfoName">
          <span>
            <atom-button class="button--circle-text2" @click="onClickLogin">
              {{ $t("components.organisms.userHeader.login") }}
            </atom-button>
          </span>
        </div>
        <div
          v-else
          ref="userInfoName"
          class="user-info__name"
          @click="onClickUserInfo"
        >
          <span v-if="isUserLoggedIn">{{ userName }}</span>
          <span v-else-if="isEapContract">
            <router-link to="/user/mail_address_authentication">
              {{ $t("components.organisms.userHeader.memberToRegister") }}
            </router-link>
            <router-link to="/login">
              {{ $t("components.organisms.userHeader.login") }}
            </router-link>
          </span>
          <span v-else>
            <router-link to="/login">
              {{ $t("components.organisms.userHeader.login") }}
            </router-link>
          </span>
        </div>

        <div class="user-info__nav">
          <user-navigation
            v-if="isNarrowLayout"
            ref="navigation"
            :enable-accordion="true"
            @on-click-user-info="onClickUserInfo()"
          />
          <div v-if="isUserLoggedIn" class="user-menu">
            <div class="user-menu__client-name">{{ userName }}</div>
            <ul class="user-menu-list">
              <li v-if="loginUser.eapUserTable">
                <router-link to="/user/edit">
                  <div>
                    {{ $t("components.organisms.userHeader.changeProfile") }}
                  </div>
                </router-link>
              </li>
              <li v-if="!(isUseSsoLogin && !loginUser.hasPmRole)">
                <router-link to="/user/password/edit">
                  <div>
                    {{ $t("components.organisms.userHeader.changePassword") }}
                  </div>
                </router-link>
              </li>
              <li
                v-if="
                  loginUser.scUserTable &&
                  !(isUseSsoLogin && !loginUser.hasPmRole)
                "
              >
                <router-link to="/user/secret_question/edit">
                  <div>
                    {{
                      $t(
                        "components.organisms.userHeader.registerSecretQuestion",
                      )
                    }}
                  </div>
                </router-link>
              </li>
              <li v-if="loginUser.eapUserTable">
                <router-link to="/user/mail_address/change">
                  <div>
                    {{
                      $t("components.organisms.userHeader.changeMailAddress")
                    }}
                  </div>
                </router-link>
              </li>
              <li v-if="loginUser.eapUserTable">
                <router-link to="/user/mail_receiving/change">
                  <div>
                    {{
                      $t("components.organisms.userHeader.changeMailRecieving")
                    }}
                  </div>
                </router-link>
              </li>
            </ul>
            <atom-button class="button--circle-text2" @click="onClickLogout">
              {{ $t("components.organisms.userHeader.logout") }}
            </atom-button>
          </div>
          <div v-else-if="isEapContract" class="user-menu">
            <atom-button
              class="button--circle-text2"
              @click="onClickMemberToRegister"
            >
              {{ $t("components.organisms.userHeader.memberToRegister") }}
            </atom-button>
            &nbsp;
            <atom-button class="button--circle-text2" @click="onClickLogin">
              {{ $t("components.organisms.userHeader.login") }}
            </atom-button>
          </div>
          <div v-else class="user-menu">
            <atom-button class="button--circle-text2" @click="onClickLogin">
              {{ $t("components.organisms.userHeader.login") }}
            </atom-button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { hasAncestorNode } from "@/helpers/DOMHelper";
import { isNarrowLayout } from "@/helpers/DeviceHelper";
import { isAccountLoginPage } from "@/helpers/PageAuthority";
import { isUseSsoLogin } from "@/helpers/LoginRoute";

export default {
  name: "UserHeader",
  data() {
    return {
      isActiveUserInfo: false,
      isNarrowLayout: false,
      userLanguageStyleJp: {
        textDecoration: undefined,
        cursor: undefined,
      },
      userLanguageStyleEn: {
        textDecoration: undefined,
        cursor: undefined,
      },
      userLanguageStyleZh: {
        textDecoration: undefined,
        cursor: undefined,
      },
    };
  },
  computed: {
    ...mapState("auth", ["loginUser", "loading"]),
    ...mapGetters("auth", ["isAccountLoggedIn", "isUserLoggedIn"]),
    userName() {
      return this.isUserLoggedIn ? this.loginUser.displayName : "-";
    },
    isNotNarrowAndNotUserLoggedIn() {
      return !this.isNarrowLayout && !this.isUserLoggedIn;
    },
    isShowTitleLogo() {
      if (this.loginUser && this.loginUser.siteTitle) {
        return false;
      }
      return true;
    },
    isAccountLoginPage() {
      return isAccountLoginPage(this.$route.path);
    },
    isEapContract() {
      return this.isAccountLoggedIn && this.loginUser.eapContentsEnable;
    },
    isUseSsoLogin() {
      return isUseSsoLogin();
    },
  },
  mounted() {
    if (typeof this.$i18n.userHeaderLocale !== "undefined") {
      this.$i18n.locale = this.$i18n.userHeaderLocale;
    }
    this.changeActiveLanguageLink();
    this.onWindowResize();
    document.addEventListener("click", this.onClickDocument, true);
    window.addEventListener("resize", this.onWindowResize);
  },
  unmounted() {
    document.removeEventListener("click", this.onClickDocument, true);
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onClickChangeLanguage(lang) {
      if (this.$i18n.locale == lang) {
        return;
      }
      this.$i18n.locale = lang;
      this.changeActiveLanguageLink();
    },
    changeActiveLanguageLink() {
      switch (this.$i18n.locale) {
        case "ja":
          this.userLanguageStyleJp.textDecoration = "none";
          this.userLanguageStyleJp.cursor = "default";
          this.userLanguageStyleEn.textDecoration = "underline";
          this.userLanguageStyleEn.cursor = "pointer";
          this.userLanguageStyleZh.textDecoration = "underline";
          this.userLanguageStyleZh.cursor = "pointer";
          break;
        case "en":
          this.userLanguageStyleJp.textDecoration = "underline";
          this.userLanguageStyleJp.cursor = "pointer";
          this.userLanguageStyleEn.textDecoration = "none";
          this.userLanguageStyleEn.cursor = "default";
          this.userLanguageStyleZh.textDecoration = "underline";
          this.userLanguageStyleZh.cursor = "pointer";
          break;
        case "zh":
          this.userLanguageStyleJp.textDecoration = "underline";
          this.userLanguageStyleJp.cursor = "pointer";
          this.userLanguageStyleEn.textDecoration = "underline";
          this.userLanguageStyleEn.cursor = "pointer";
          this.userLanguageStyleZh.textDecoration = "none";
          this.userLanguageStyleZh.cursor = "default";
          break;
        default:
          this.userLanguageStyleJp.textDecoration = "none";
          this.userLanguageStyleJp.cursor = "default";
          this.userLanguageStyleEn.textDecoration = "underline";
          this.userLanguageStyleEn.cursor = "pointer";
          this.userLanguageStyleZh.textDecoration = "underline";
          this.userLanguageStyleZh.cursor = "pointer";
          break;
      }
    },
    onWindowResize() {
      this.isNarrowLayout = isNarrowLayout();
    },
    onClickMemberToRegister() {
      this.$router.push("/user/mail_address_authentication");
    },
    onClickLogin() {
      this.$router.push("/login");
    },
    onClickUserInfo() {
      this.isActiveUserInfo = !this.isActiveUserInfo;
    },
    onClickLogout() {
      const accountHash = this.loginUser.accountHash;
      this.$api.post("logout").then(() =>
        this.$router.push({
          name: "logout",
          params: {
            hashedCode: accountHash,
          },
        }),
      );
    },
    onClickDocument(event) {
      if (
        hasAncestorNode(event.target, this.$refs.userInfoName) ||
        (this.$refs.navigation &&
          hasAncestorNode(event.target, this.$refs.navigation.$el))
      ) {
        return;
      }
      this.isActiveUserInfo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.user-info {
  width: auto;
}
.user-language {
  width: 140px;
}
@media only screen and (max-width: 767px) {
  .menu-button {
    vertical-align: middle;
  }
}
@media only screen and (min-width: 768px) {
  .menu-button {
    position: relative;
  }
}
.user-language > a {
  color: #5d5d5d;
  font-size: 0.75rem;
}
.link-inactive a {
  text-decoration: none;
  cursor: default;
}
.user-info > div {
  display: inline-block;
}
.button--circle-text2 {
  width: 150px;
  height: 35px;
  line-height: 100%;
}
</style>
