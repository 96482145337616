import Vue from "vue";
import { createApp } from 'vue'

import App from "./App.vue";
import router from "./router";
import store from "./store/index";

Vue.config.productionTip = false;

// Load plugins
import "./plugins";

// Global stylesheet
require("./assets/css/main.css");
require("./assets/scss/style.scss");

// Language
import i18n from "@/assets/i18n";

const app = createApp({
  render: h => h(App),
})
.use(router)
.use(store)
.use(i18n)
app.provide('app', app)
app.mount('#app')

