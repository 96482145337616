import find from "lodash/find";
import api from "@/api";

export default {
  namespaced: true,
  state: {
    loginUser: undefined,
    loading: false,
    location: undefined,
    transited: false,
  },
  getters: {
    isAccountLoggedIn: (state) => {
      return !!(state.loginUser && state.loginUser.accountCode);
    },
    isUserLoggedIn: (state) => {
      if (state.loginUser && state.loginUser.scUserTable == true) {
        return !!(state.loginUser && state.loginUser.id);
      } else if (state.loginUser && state.loginUser.eapUserTable == true) {
        return !!(state.loginUser && state.loginUser.eapUserId);
      } else {
        return !!(state.loginUser && state.loginUser.id);
      }
    },
    isAdmin: (state) => {
      return (
        state.loginUser &&
        find(
          state.loginUser.authorities,
          (authority) => authority.authority === "ROLE_ADMIN",
        )
      );
    },
  },
  mutations: {
    setLoginUser: (state, payload) => {
      state.loginUser = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setTransited: (state, payload) => {
      state.transited = payload;
    },
    setLocation: (state, payload) => {
      state.location = payload;
    },
  },
  actions: {
    load: async (context) => {
      context.commit("setLoading", true);
      try {
        const response = await api.get("/user");
        const payload = response.data;
        sessionStorage.setItem("account", payload.accountHash);
        context.commit("setLoginUser", payload);
      } catch (error) {
        context.commit("setLoginUser", undefined);
        return Promise.reject(error);
      } finally {
        context.commit("setLoading", false);
      }
    },
    clear: (context) => {
      context.commit("setLoginUser", undefined);
      context.commit("setLoading", false);
    },
    location: ({ commit, state }, href) => {
      const fullPath = href.pathname + href.search + href.hash;
      commit("setTransited", !state.location || state.location == fullPath);
      commit("setLocation", fullPath);
    },
  },
};
