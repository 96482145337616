<template>
  <validation-wrapper
    v-slot="{ validationContext }"
    :has-focus="hasFocus"
    :rules="rules"
    :messages="messages"
    :name="name || placeholder || 'AtomInput'"
    :value="value"
    :is-mounted-validation="isMountedValidation"
  >
    <input
      :class="{
        'invalid-field': validationContext.errors.length > 0,
      }"
      :value="value"
      :type="type"
      :disabled="disabled"
      :placeholder="placeholder"
      :autofocus="autoFocus"
      style="width: 100%"
      @input="
        (e) => {
          validationContext.handleChange(e);
          onInput(e);
        }
      "
      @focus="onFocus($event, validationContext)"
      @blur="onBlur($event, validationContext)"
    />
  </validation-wrapper>
</template>

<script>
export default {
  name: "AtomInput",
  props: {
    value: {
      type: [String, Number, Date],
      default: undefined,
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    rules: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Object,
      default: undefined,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: undefined,
    },
    isMountedValidation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  data() {
    return {
      hasFocus: false,
      testval: 0,
    };
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    },
    onFocus(event, validation) {
      // フォーカスが当たったときのイベント
      this.hasFocus = true;
      // 任意項目のメールアドレス以外は検証する
      if (!("email" in this.rules) || "required" in this.rules) {
        // 変更と同時にfocusのバリデーションが走った際、
        // 変更前値でevent.srcElement.valueが作成されることがあるため、
        // コンポーネントの値で検証をする
        validation.handleChange(this.value);
      }
    },
    onBlur(event, validation) {
      // フォーカスが外れたときのイベント
      this.hasFocus = false;
      if (
        "email" in this.rules &&
        !("required" in this.rules) &&
        !event.target._value
      ) {
        // 任意項目のメールアドレスで値が無い場合は検証をリセットする
        validation.handleReset(event);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
