<template>
  <!-- veeValidateのFormにおいてsubmit処理が走り意図しない画面遷移が発生するため、
       as="" を指定してレンダリングレスフォームとする -->
  <VeeValidateForm v-slot="{ handleSubmit, meta }" ref="form" as="">
    <slot :handleSubmit="handleSubmit" :invalid="!(meta.valid && meta.dirty)" />
  </VeeValidateForm>
</template>

<script>
export default {
  name: "ValidationObserver",
  props: {
    tag: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    resetForm() {
      return this.$refs.form.resetForm();
    },
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>
