<template>
  <footer class="footer">
    <small class="copyright">&copy; PEACEMIND Inc. All rights reserved.</small>
  </footer>
</template>

<script>
export default {
  name: "AdminFooter",
};
</script>
