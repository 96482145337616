<template>
  <validation-provider
    v-slot="validationContext"
    :rules="rules"
    :bails="false"
    tag="div"
    style="width: 100%"
    :name="name"
    :value="value"
    ref="provider"
    :isMountedValidation="isMountedValidation"
  >
    <div v-if="hasRules" class="validation-tooltip" style="width: 100%">
      <slot :validation-context="validationContext" />
      <div v-show="hasFocus" class="tooltip-text">
        <div class="validation-result">
          <div
            v-for="(message, key) in messages"
            :key="key"
            :class="
              validationContext.errors.includes(key)
                ? 'invalid-message'
                : 'valid-message'
            "
          >
            <font-awesome-icon
              style="width: 1rem"
              :icon="
                validationContext.errors.includes(key)
                  ? 'times'
                  : 'check'"
            />
            {{ message }}
          </div>
        </div>
      </div>
    </div>
    <slot v-else :validation-context="validationContext" />
  </validation-provider>
</template>

<script>
import isEmpty from "lodash/isEmpty";

export default {
  name: "ValidationWrapper",
  props: {
    hasFocus: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    messages: {
      type: Object,
      default() {
        return {};
      },
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Date],
      default: undefined,
    },
    isMountedValidation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasRules() {
      return !isEmpty(this.rules);
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-result {
  font-size: 0.625rem;
  .invalid-message {
    color: red;
  }
  .valid-message {
    color: green;
  }
}

.validation-tooltip {
  position: relative;

  .tooltip-text {
    visibility: visible;
    background-color: #dddddd;
    color: #fff;
    text-align: left;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 100;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    white-space: normal;

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #dddddd transparent transparent transparent;
    }
  }

  &:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}
</style>
