import axios from "axios";
import status from "http-status";
import router from "@/router";
import store from "@/store";

const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

const api = axios.create({
  baseURL: apiBaseUrl || "/api/v1.0",
  validateStatus: function (status) {
    return status >= 200 && status < 400;
  },
});
api.defaults.headers.common["Content-Type"] = "application/json;charset=UTF-8";

api.interceptors.request.use((request) => {
  store.commit("loading/increment");
  return request;
});

function responseHandler(response) {
  store.commit("loading/decrement");
  redirectHandler(response);
  return response;
}

function errorHandler(error) {
  store.commit("loading/decrement");
  const response = error.response;
  if (response.status === status.UNAUTHORIZED) {
    response.data.loginPage =
      response.config.url == "/user"
        ? "/account/login"
        : "/login";
  } else if (response.status === status.FORBIDDEN) {
    if (response.data.redirectURL) {
      router.push(response.data.redirectURL);
    } else {
      router.push("/forbidden");
    }
  } else if (response.status === status.NOT_FOUND) {
    router.push("/not_found");
  } else if (response.status === status.CONFLICT) {
    router.push("/conflict");
  } else if (response.status >= status.INTERNAL_SERVER_ERROR) {
    router.replace("/error");
  }
  return Promise.reject(error);
}

function redirectHandler(response) {
  if (response.headers["content-type"] == "text/html") {
    const location = response.request["responseURL"];
    if (location) {
      window.location.href = location;
    }
  }
}
api.interceptors.response.use(responseHandler, errorHandler);

export default api;
