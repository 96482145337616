<template>
  <div id="message-wrapper">
    <div class="message-body">
      <ul>
        <li v-for="message in messages" :key="message" :class="'text-red'">
          {{ message }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "AdminProjectReportSettingPreviousLoadMessagesPanel",
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style lang="scss" scoped>
#message-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  overflow: hidden;
  margin: 1rem 0;

  .mx-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .ml-50px {
    margin-left: 50px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 50px;
  }

  .text-red {
    color: red !important;
  }
}
</style>
