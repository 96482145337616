<template>
  <div id="message-wrapper">
    <div class="message-header">
      <button
        v-if="messages && messages.length > 0"
        class="btn btn-link btn-block text-left collapsed"
        type="button"
        @click="collapsed = !collapsed"
      >
        <font-awesome-icon :icon="!collapsed ? 'angle-up' : 'angle-down'" />
      </button>
      <h4
        class="mb-0"
        :class="{
          'text-red': type === 'ERROR',
          'text-success': type !== 'ERROR',
          'ml-50px': !(messages && messages.length > 0),
        }"
      >
        {{ title }}
      </h4>
    </div>

    <div v-if="!collapsed" class="collapse">
      <div class="message-body">
        <ul>
          <li
            v-for="message in messages"
            :key="message"
            :class="{
              'text-red': type === 'ERROR',
              'text-success': type !== 'ERROR',
            }"
          >
            {{ message }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import includes from "lodash/includes";

export default {
  name: "AdminProjectReportSettingMessagesPanel",
  props: {
    title: {
      type: String,
      default: "エラーが発生しました。",
    },
    messages: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "ERROR",
      validator(value) {
        return includes(["SUCCESS", "ERROR"], value);
      },
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
};
</script>

<style lang="scss" scoped>
#message-wrapper {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  overflow: hidden;
  margin: 1rem 0;

  .mx-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .ml-50px {
    margin-left: 50px;
  }

  .message-header {
    display: flex;

    button,
    button:focus,
    button:hover {
      outline: 0;
      border: none;
      background: inherit;
    }

    button {
      cursor: pointer;
      width: 50px;
      height: 50px;
      font-size: 1.5rem;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 50px;
  }

  .text-red {
    color: red !important;
  }

  .text-success {
    color: #28a745 !important;
  }
}
</style>
