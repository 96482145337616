<template>
  <a v-if="!hideButton" @click="onClick">
    <i class="icon__download"></i>
    {{ buttonText }}
  </a>
</template>
<script>
import { saveAs } from "file-saver";
import { CsvDownloadHelper } from "@/helpers/CsvDownloadHelper";

export default {
  name: "CsvDownloadButton",
  props: {
    loadCsv: {
      type: Function,
      required: true,
    },
    buttonText: {
      type: String,
      default: "CSVファイルをダウンロード",
    },
    encode: {
      type: String,
      default: "UTF8",
    },
    getFileName: {
      type: Function,
      required: true,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async onClick() {
      const csv = await this.loadCsv();
      const blob = CsvDownloadHelper.convertToBlobWithBom(csv);
      saveAs(blob, this.getFileName());
    },
  },
};
</script>

<style lang="scss" scoped>
.button--circle {
  width: 175px;
}
</style>
