<template>
  <div class="panel" style="width: 100%">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Panel",
};
</script>

<style lang="scss" scoped>
.panel {
  font-size: 0.75rem;
  display: flex;
  box-sizing: border-box;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  flex-direction: column;
  min-width: 250px;
  margin-top: 16px;
  padding: 15px 18px;
  border-radius: 4px;
  background-color: #ffffff;
}
</style>
