<template>
  <div class="board">
    <h4
      v-for="(stressCheckName, i) in stressCheckNames"
      :key="`stress-check-name-${i}`"
      class="heading--secondary-noborder--green"
    >
      {{ stressCheckName }}
    </h4>
    <div class="board__body">
      <div v-for="(examPeriod, i) in examPeriods" :key="`exam-period-${i}`">
        {{ examPeriod }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "StressCheckBoard",
  props: {
    stressCheckNames: {
      type: Array,
      required: true,
    },
    examPeriods: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.board__body {
  font-size: 0.75rem;
}
.board__body :deep(ul) {
  margin-left: 17px;
}
.board__body :deep(ol) {
  margin-left: 13px;
}
</style>
