<template>
  <transition v-if="isVisible" appear name="loading">
    <div class="loading-mask">
      <div class="loading-spinner">
        <scale-loader color="white" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "LoadingMask",
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-mask {
  position: fixed;
  z-index: 99998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.loading-enter,
.loading-leave-active {
  opacity: 0;
}

.loading-enter .loading-container,
.loading-leave-active .loading-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
