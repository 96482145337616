<template>
  <ul class="breadcrumb">
    <li
      v-for="(value, name, index) in linkedHierarchy"
      :key="index"
      class="breadcrumb__item"
    >
      <template v-if="value.path === undefined">
        {{ value.display }}
      </template>
      <template v-else>
        <router-link :to="{ path: value.path }">
          {{ value.display }}
        </router-link>
      </template>
    </li>
  </ul>
</template>

<script>
import every from "lodash/every";
import has from "lodash/has";
import union from "lodash/union";

export default {
  name: "UserBreadcrumb",
  props: {
    hierarchy: {
      type: Array,
      default: () => [],
      validator(value) {
        return every(value, (element) => has(element, "display"));
      },
    },
  },
  computed: {
    linkedHierarchy() {
      if (this.hierarchy.length == 0) {
        return [
          {
            display: "TOP",
          },
        ];
      }
      return union(
        [
          {
            path: "/",
            display: "TOP",
          },
        ],
        this.hierarchy,
      );
    },
  },
};
</script>
