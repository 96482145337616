<template>
  <li v-if="!useDropdown">
    <span @click="menuProjectOpen = !menuProjectOpen">
      <i class="icon__note"></i>
      {{ omitProjectName }}
    </span>
    <atom-tooltip v-show="menuProjectOpen">{{ projectName }}</atom-tooltip>
    <div class="child__menu child__menu1">
      <ul v-show="menuProjectOpen">
        <li>
          <span @click="menuStep1Open = !menuStep1Open">
            ステップ１ ： 実施前設定
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep1Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep1Open">
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project)) &&
                  project.usingUsersBulkNew
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/users/bulk_new`"
                >
                  ストレスチェック対象者リスト登録
                </router-link>
              </li>
              <li
                v-if="
                  (isOnymous(project) || isAnonymous(project)) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/admin_users`"
                >
                  権限者と担当範囲設定
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link :to="`/admin/projects/${project.code}/users`">
                  対象者リスト一覧
                </router-link>
              </li>
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/anonymous_project_analytic_hierarchies/bulk_new`"
                >
                  無記名式分析階層設定
                </router-link>
              </li>
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/anonymous_project_analytic_hierarchies/reorder`"
                >
                  無記名式分析階層並び替え
                </router-link>
              </li>
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/anonymous_project_analytic_hierarchies`"
                >
                  無記名式分析階層一覧
                </router-link>
              </li>
              <li
                v-if="
                  (isOnymous(project) || isAnonymous(project)) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/project_labels/edit`"
                >
                  対象者リスト項目名設定
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep2Open = !menuStep2Open">
            ステップ2 ： ストレスチェック開始連絡及び受検勧奨
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep2Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep2Open">
              <li
                v-if="
                  project.usingMailForStatingExam &&
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/email/new?type=SC_START_NOTICE_MAIL`"
                >
                  ストレスチェック開始連絡メール送信
                </router-link>
              </li>
              <li
                v-if="
                  project.usingMailForStatingExam &&
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=SC_START_NOTICE_MAIL`"
                >
                  ストレスチェック開始連絡メール送信(個別送信)
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/email/new?type=EXAM_RECOMMENDATION_MAIL`"
                >
                  受検勧奨メール送信予約
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=EXAM_RECOMMENDATION_MAIL`"
                >
                  受検勧奨メール送信(個別送信)
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep3Open = !menuStep3Open">
            ステップ3 ： 高ストレス者に対する面接指導の要否判定
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep3Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep3Open">
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/users_by_anonymous_result`"
                >
                  無記名式ストレスチェック結果検索
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/users_for_interview_necessity_decision`"
                >
                  高ストレス者の面接指導要否確認
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=EXAM_RESULT_NOTICE_MAIL`"
                >
                  面接指導の要否判定完了通知メール送信(個別送信)
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep4Open = !menuStep4Open">
            ステップ4 ： 面接指導申し出管理
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep4Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep4Open">
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) || isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/email/new?type=INTERVIEW_RECOMMENDATION_MAIL`"
                >
                  面接指導申し出勧奨メール送信
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) || isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=INTERVIEW_RECOMMENDATION_MAIL`"
                >
                  面接指導申し出勧奨メール送信(個別送信)
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep5Open = !menuStep5Open">
            集団分析レポート
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep5Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep5Open">
              <li
                v-if="
                  isRoleBusinessOwner(project) ||
                  isRoleBusinessOwnerGeneral(project) ||
                  isRoleProjectOwner(project) ||
                  isRoleProjectOwnerGeneral(project) ||
                  isRolePm(project)
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link :to="`/admin/projects/${project.code}/report`">
                  詳細設定
                </router-link>
              </li>
              <li
                v-if="
                  isRoleBusinessOwner(project) ||
                  isRoleBusinessOwnerGeneral(project) ||
                  isRoleProjectOwner(project) ||
                  isRoleProjectOwnerGeneral(project) ||
                  isRolePm(project)
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/report/cross_analytics`"
                >
                  クロス集計設定
                </router-link>
              </li>
              <li
                v-if="
                  isReportReference(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/report/download`"
                >
                  ダウンロード
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuOptionOpen = !menuOptionOpen">
            オプション
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuOptionOpen ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuOptionOpen">
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/reserved_emails`"
                >
                  送信予約済みメール一覧
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
                @click="$emit('onClickUserInfo')"
              >
                <router-link
                  :to="`/admin/projects/${project.code}/setting/email_splitting/edit`"
                >
                  メール分割送信設定
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li
          v-if="
            isOnymous(project) &&
            (isRoleBusinessOwner(project) ||
              isRoleBusinessOwnerGeneral(project) ||
              isRoleProjectOwner(project) ||
              isRoleProjectOwnerGeneral(project) ||
              isRolePm(project))
          "
          @click="$emit('onClickUserInfo')"
        >
          <router-link :to="`/admin/projects/${project.code}/stress_checks`">
            対象者検索
          </router-link>
        </li>
        <li
          v-if="isRolePm(project) && isDemoProject(project)"
          @click="$emit('onClickUserInfo')"
        >
          <router-link :to="`/admin/projects/${project.code}/demouser`">
            デモユーザ作成
          </router-link>
        </li>
        <li
          v-if="isRolePm(project) && isDemoProject(project)"
          @click.prevent.stop="onClickDeleteDemo"
        >
          <a href="#">デモユーザ削除</a>
        </li>
      </ul>
    </div>
    <confirm-modal
      v-model:shows="showsConfirmDeleteDemoUserModal"
      :title="confirmTitle"
      :ok-button-label="confirmOkButtonLabel"
      :cancel-button-label="confirmCancelButtonLabel"
      @click-ok-button="onClickConfirmDeleteDemoUserModalOkButton(project)"
      @click-cancel-button="onClickConfirmDeleteDemoUserModalCancelButton"
    >
      デモユーザを削除してもよろしいでしょうか？
    </confirm-modal>
  </li>
  <dropdown-menu
    v-else
    v-model="show"
    :hover="true"
    :hover-time="0"
    :menu-state="isMenuChangeState"
  >
    <span class="dropdown-toggle">
      <i class="icon__note"></i>
      {{ omitProjectName }}
    </span>
    <atom-tooltip>{{ projectName }}</atom-tooltip>
    <template #dropdown>
      <ul>
        <li>
          <span @click="menuStep1Open = !menuStep1Open">
            ステップ１ ： 実施前設定
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep1Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep1Open">
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project)) &&
                  project.usingUsersBulkNew
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/users/bulk_new`"
                >
                  ストレスチェック対象者リスト登録
                </router-link>
              </li>
              <li
                v-if="
                  (isOnymous(project) || isAnonymous(project)) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/admin_users`"
                >
                  権限者と担当範囲設定
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link :to="`/admin/projects/${project.code}/users`">
                  対象者リスト一覧
                </router-link>
              </li>
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/anonymous_project_analytic_hierarchies/bulk_new`"
                >
                  無記名式分析階層設定
                </router-link>
              </li>
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/anonymous_project_analytic_hierarchies/reorder`"
                >
                  無記名式分析階層並び替え
                </router-link>
              </li>
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/anonymous_project_analytic_hierarchies`"
                >
                  無記名式分析階層一覧
                </router-link>
              </li>
              <li
                v-if="
                  (isOnymous(project) || isAnonymous(project)) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/project_labels/edit`"
                >
                  対象者リスト項目名設定
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep2Open = !menuStep2Open">
            ステップ2 ： ストレスチェック開始連絡及び受検勧奨
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep2Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep2Open">
              <li
                v-if="
                  project.usingMailForStatingExam &&
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/email/new?type=SC_START_NOTICE_MAIL`"
                >
                  ストレスチェック開始連絡メール送信
                </router-link>
              </li>
              <li
                v-if="
                  project.usingMailForStatingExam &&
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=SC_START_NOTICE_MAIL`"
                >
                  ストレスチェック開始連絡メール送信(個別送信)
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/email/new?type=EXAM_RECOMMENDATION_MAIL`"
                >
                  受検勧奨メール送信予約
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=EXAM_RECOMMENDATION_MAIL`"
                >
                  受検勧奨メール送信(個別送信)
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep3Open = !menuStep3Open">
            ステップ3 ： 高ストレス者に対する面接指導の要否判定
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep3Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep3Open">
              <li
                v-if="
                  isAnonymous(project) &&
                  (isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/users_by_anonymous_result`"
                >
                  無記名式ストレスチェック結果検索
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/users_for_interview_necessity_decision`"
                >
                  高ストレス者の面接指導要否確認
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=EXAM_RESULT_NOTICE_MAIL`"
                >
                  面接指導の要否判定完了通知メール送信(個別送信)
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep4Open = !menuStep4Open">
            ステップ4 ： 面接指導申し出管理
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep4Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep4Open">
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) || isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/email/new?type=INTERVIEW_RECOMMENDATION_MAIL`"
                >
                  面接指導申し出勧奨メール送信
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleProjectOwner(project) || isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/individual_email?type=INTERVIEW_RECOMMENDATION_MAIL`"
                >
                  面接指導申し出勧奨メール送信(個別送信)
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuStep5Open = !menuStep5Open">
            集団分析レポート
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuStep5Open ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuStep5Open">
              <li
                v-if="
                  isRoleBusinessOwner(project) ||
                  isRoleBusinessOwnerGeneral(project) ||
                  isRoleProjectOwner(project) ||
                  isRoleProjectOwnerGeneral(project) ||
                  isRolePm(project)
                "
              >
                <router-link :to="`/admin/projects/${project.code}/report`">
                  詳細設定
                </router-link>
              </li>
              <li
                v-if="
                  isRoleBusinessOwner(project) ||
                  isRoleBusinessOwnerGeneral(project) ||
                  isRoleProjectOwner(project) ||
                  isRoleProjectOwnerGeneral(project) ||
                  isRolePm(project)
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/report/cross_analytics`"
                >
                  クロス集計設定
                </router-link>
              </li>
              <li
                v-if="
                  isReportReference(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/report/download`"
                >
                  ダウンロード
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span @click="menuOptionOpen = !menuOptionOpen">
            オプション
            <i class="pull-right">
              <font-awesome-icon
                :icon="menuOptionOpen ? 'chevron-down' : 'chevron-up'"
              />
            </i>
          </span>
          <transition name="slide">
            <ul v-show="menuOptionOpen">
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleProjectOwner(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/reserved_emails`"
                >
                  送信予約済みメール一覧
                </router-link>
              </li>
              <li
                v-if="
                  isOnymous(project) &&
                  (isRoleBusinessOwner(project) ||
                    isRoleBusinessOwnerGeneral(project) ||
                    isRoleProjectOwner(project) ||
                    isRoleProjectOwnerGeneral(project) ||
                    isRolePm(project))
                "
              >
                <router-link
                  :to="`/admin/projects/${project.code}/setting/email_splitting/edit`"
                >
                  メール分割送信設定
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li
          v-if="
            isOnymous(project) &&
            (isRoleBusinessOwner(project) ||
              isRoleBusinessOwnerGeneral(project) ||
              isRoleProjectOwner(project) ||
              isRoleProjectOwnerGeneral(project) ||
              isRolePm(project))
          "
        >
          <router-link :to="`/admin/projects/${project.code}/stress_checks`">
            対象者検索
          </router-link>
        </li>
        <li v-if="isRolePm(project) && isDemoProject(project)">
          <router-link :to="`/admin/projects/${project.code}/demouser`">
            デモユーザ作成
          </router-link>
        </li>
        <li
          v-if="isRolePm(project) && isDemoProject(project)"
          @click.prevent.stop="onClickDeleteDemo"
        >
          <a href="#">デモユーザ削除</a>
        </li>
      </ul>
    </template>
    <confirm-modal
      v-model:shows="showsConfirmDeleteDemoUserModal"
      :title="confirmTitle"
      :ok-button-label="confirmOkButtonLabel"
      :cancel-button-label="confirmCancelButtonLabel"
      @click-ok-button="onClickConfirmDeleteDemoUserModalOkButton(project)"
      @click-cancel-button="onClickConfirmDeleteDemoUserModalCancelButton"
    >
      デモユーザを削除してもよろしいでしょうか？
    </confirm-modal>
  </dropdown-menu>
</template>

<script>
import include from "lodash/includes";
import DropdownMenu from "./DropdownMenu.vue";

export default {
  name: "AdminNavigationProjectLink",
  components: {
    DropdownMenu,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    useDropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClickUserInfo"],
  data: function () {
    return {
      menuProjectOpen: false,
      menuStep1Open: false,
      menuStep2Open: false,
      menuStep3Open: false,
      menuStep4Open: false,
      menuStep5Open: false,
      menuOptionOpen: false,
      show: false,
      isMenuChangeState: undefined,
      showsConfirmDeleteDemoUserModal: false,
      confirmTitle: "",
      confirmOkButtonLabel: "OK",
      confirmCancelButtonLabel: "キャンセル",
    };
  },
  computed: {
    projectName() {
      return (
        this.project.examYear +
        "年 " +
        this.project.accountName +
        " " +
        this.project.name
      );
    },
    omitProjectName() {
      return this.projectName.length < 40
        ? this.projectName
        : this.projectName.substr(0, 39) + "...";
    },
  },
  watch: {
    menuStep1Open(v) {
      if (this.useDropdown) {
        this.isMenuChangeState = "menuStep1Open-" + v;
      }
    },
    menuStep2Open(v) {
      if (this.useDropdown) {
        this.isMenuChangeState = "menuStep2Open-" + v;
      }
    },
    menuStep3Open(v) {
      if (this.useDropdown) {
        this.isMenuChangeState = "menuStep3Open-" + v;
      }
    },
    menuStep4Open(v) {
      if (this.useDropdown) {
        this.isMenuChangeState = "menuStep4Open-" + v;
      }
    },
    menuStep5Open(v) {
      if (this.useDropdown) {
        this.isMenuChangeState = "menuStep5Open-" + v;
      }
    },
    menuOptionOpen(v) {
      if (this.useDropdown) {
        this.isMenuChangeState = "menuOptionOpen-" + v;
      }
    },
  },
  methods: {
    isOnymous(project) {
      return include(project.namingMethods, "ONYMOUS");
    },
    isAnonymous(project) {
      return include(project.namingMethods, "ANONYMOUS");
    },
    isRolePm(project) {
      return project.roleCode === "PM";
    },
    isRoleProjectOwner(project) {
      return project.roleCode === "PROJECT_OWNER";
    },
    isRoleProjectOwnerGeneral(project) {
      return project.roleCode === "PROJECT_OWNER_GENERAL";
    },
    isRoleBusinessOwner(project) {
      return project.roleCode === "BUSINESS_OWNER";
    },
    isRoleBusinessOwnerGeneral(project) {
      return project.roleCode === "BUSINESS_OWNER_GENERAL";
    },
    isDemoProject(project) {
      return project.demoFlag;
    },
    isReportReference(project) {
      if (
        project.reportReference === "COMPANY_DETAIL_ONLY" ||
        project.reportReference === "ALL"
      ) {
        return true;
      }
      return false;
    },
    onClickDeleteDemo() {
      this.showsConfirmDeleteDemoUserModal = true;
    },
    onClickConfirmDeleteDemoUserModalOkButton(project) {
      this.deleteDemoUser(project)
        .then(() => {
          this.showsConfirmDeleteDemoUserModal = false;
          this.$router.push({
            name: "projects_demo_users_delete_complete",
            params: { projectCode: project.code },
          });
        })
        .catch(() => {
          this.$router.replace("/error");
        });
    },
    onClickConfirmDeleteDemoUserModalCancelButton() {
      this.showsConfirmDeleteDemoUserModal = false;
    },
    deleteDemoUser(project) {
      return this.$api.delete(`/admin/projects/${project.code}/demo_users`);
    },
  },
};
</script>

<style lang="scss" scoped>
.child__menu1 {
  width: 385px;
}

.slide-enter-active {
  transition: all 0.8s ease-in;
}

.slide-leave-active {
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 500px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.tooltip {
  min-width: 410px;
}

.tooltip:after {
  left: 25%;
}
</style>
