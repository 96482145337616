// veeValidate version3 と同挙動のmax_valueを定義
export default {
  validate(value, [ max ]) {
    if (value === null || value === undefined || value === '') {
      return false;
    }

    return Number(value) <= Number(max);
  }
}
