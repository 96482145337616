import { format as formatDate, parseISO } from "date-fns";
import ja from "date-fns/locale/ja";
import en from "date-fns/locale/en-US";
import zh from "date-fns/locale/zh-CN";

export default {
  computed: {
    date(value, format) {
      if (!value || !format) return null;

      return formatDate(parseISO(value), format, {
        locale: ja,
      });
    },
  },
  methods: {
    formatDateByLanguage(value, format, language) {
      if (!value || !format) return null;
      switch (language) {
        case "ENGLISH":
          return formatDate(parseISO(value), format, {
            locale: en,
          });
        case "CHINESE":
          return formatDate(parseISO(value), format, {
            locale: zh,
          });
        default:
          return formatDate(parseISO(value), format, {
            locale: ja,
          });
      }
    },
  },
};
