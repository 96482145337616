import cloneDeep from "lodash/cloneDeep";

export default {
  namespaced: true,
  state: {},
  mutations: {
    setSearch: (state, payload) => {
      const { routeName, searchCondition, tableCondition } = payload;
      state[routeName] = {
        searchCondition: cloneDeep(searchCondition),
        tableCondition: cloneDeep(tableCondition),
      };
    },
    setSearchPersonalReport: (state, payload) => {
      const { routeName, tableConditionPersonalReport } = payload;
      state[routeName + "personalReport"] = {
        tableConditionPersonalReport: cloneDeep(tableConditionPersonalReport),
      };
    },
  },
  getters: {
    conditionExists: (state) => (routeName) => !!state[routeName],
    searchCondition: (state) => (routeName) => state[routeName].searchCondition,
    tableCondition: (state) => (routeName) => state[routeName].tableCondition,
    tableConditionPersonalReport: (state) => (routeName) =>
      state[routeName + "personalReport"].tableConditionPersonalReport,
  },
};
