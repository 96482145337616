/**
 * targetNodeの祖先ノードにancestorNodeが含まれているか。
 * targetNode自身がancestorNodeの場合もtrue。
 *
 * @param {*} targetNode 祖先ノードを探索する対象のノード
 * @param {*} ancestorNode 祖先ノード
 */
export function hasAncestorNode(targetNode, ancestorNode) {
  if (targetNode === ancestorNode) {
    return true;
  }
  const parentNode = targetNode.parentNode || targetNode.host;
  if (!parentNode || parentNode === document) {
    return false;
  }
  if (parentNode === ancestorNode) {
    return true;
  }
  return hasAncestorNode(parentNode, ancestorNode);
}
