<template>
  <div class="user-navigation">
    <ul>
      <li
        v-if="
          isUserLoggedIn &&
          !loading &&
          loginUser &&
          loginUser.eapContentsEnable &&
          loginUser.eapUserId
        "
        :class="{ 'is-active': isOpenAccordion.eap }"
      >
        <span @click="isOpenAccordion.eap = !isOpenAccordion.eap">
          <i class="icon__heart--green"></i>
          {{ $t("components.organisms.userNavigation.eap") }}
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.eap,
          }"
        >
          <li @click="$emit('onClickUserInfo')">
            <router-link to="/counseling/apply/Counseling_type">
              <i class="icon__note--green" />
              {{ $t("components.organisms.userNavigation.cunselingApply") }}
            </router-link>
          </li>
          <li @click="$emit('onClickUserInfo')">
            <router-link to="/counseling/history">
              <i class="icon__note--green" />
              {{ $t("components.organisms.userNavigation.counselingHistory") }}
            </router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="
          isUserLoggedIn &&
          !loading &&
          loginUser &&
          loginUser.eapContentsEnable &&
          loginUser.eapUserId &&
          loginUser.showSelfCare
        "
        :class="{ 'is-active': isOpenAccordion.eapSelfCare }"
      >
        <span
          @click="isOpenAccordion.eapSelfCare = !isOpenAccordion.eapSelfCare"
        >
          <font-awesome-icon :icon="'child'" />
          {{ $t("components.organisms.userNavigation.eapSelfCare") }}
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.eapSelfCare,
          }"
        >
          <li @click="$emit('onClickUserInfo')">
            <router-link to="/eap_self_care/self_check">
              <font-awesome-icon :icon="'tasks'" :class="'svg_icon'" />
              {{ $t("components.organisms.userNavigation.eapSelfCheck") }}
            </router-link>
          </li>
        </ul>
      </li>
      <li
        v-if="
          isUserLoggedIn &&
          !isEmpty(userStressCheck.relatedUser) &&
          !loading &&
          loginUser &&
          loginUser.scContentsEnable
        "
        :class="{ 'is-active': isOpenAccordion.stressCheck }"
      >
        <span
          @click="isOpenAccordion.stressCheck = !isOpenAccordion.stressCheck"
        >
          <i class="icon__chart--green"></i>
          {{ $t("components.organisms.userNavigation.stressCheck") }}
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.stressCheck,
          }"
        >
          <li
            v-if="!isEmpty(userStressCheck.examined) && !isPasswordReset"
            @click="$emit('onClickUserInfo')"
          >
            <router-link to="/stress_checks">
              <i class="icon__note--green" />
              {{
                $t("components.organisms.userNavigation.stressCheckTestHistory")
              }}
            </router-link>
          </li>
          <li @click="$emit('onClickUserInfo')">
            <router-link to="/user/language/edit">
              <i class="icon__note--green" />
              {{ $t("components.organisms.userNavigation.languageSetting") }}
            </router-link>
          </li>
          <li
            v-if="!isEmpty(userStressCheck.onymousSelfCare)"
            @click="$emit('onClickUserInfo')"
          >
            <router-link
              :to="`/projects/${userStressCheck.onymousSelfCare.projectCode}/stress_checks/0/exam_intention/new?selfcare=true`"
            >
              <i class="icon__note--green" />
              {{ $t("components.organisms.userNavigation.selfCareCheck") }}
            </router-link>
          </li>
          <li v-if="!isEmpty(userStressCheck.onymousSelfLearning)">
            <a href="/public/selfleanings/ezmentre_top.html" target="_blank">
              <i class="icon__note--green" />
              {{ $t("components.organisms.userNavigation.webLearning") }}
            </a>
          </li>
        </ul>
      </li>
      <li
        v-if="
          isAccountReady &&
          loginUser &&
          loginUser.eapContentsEnable &&
          loginUser.showColumn
        "
        :class="{ 'is-active': isOpenAccordion.column }"
      >
        <span @click="isOpenAccordion.column = !isOpenAccordion.column">
          <font-awesome-icon :icon="'book'" />
          {{ $t("components.organisms.userNavigation.column") }}
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.column,
            clickable: true,
          }"
        >
          <li>
            <span
              @click="
                isOpenAccordion.monthlyTopics = !isOpenAccordion.monthlyTopics
              "
            >
              <font-awesome-icon :icon="'newspaper'" :class="'svg_icon'" />
              {{ $t("components.organisms.userNavigation.monthlyTopics") }}
            </span>
            <ul
              v-for="(contents, index) in topicsContents"
              :key="`-${index}`"
              :class="{
                accordion: true,
                'accordion-visible': isOpenAccordion.monthlyTopics,
              }"
            >
              <li
                class="accordion-column"
                @click="transitionToMonthlyTopics(contents.code)"
              >
                <span class="clickable">・{{ contents.name }}</span>
              </li>
            </ul>
          </li>
          <li>
            <span
              @click="
                isOpenAccordion.mySupplement = !isOpenAccordion.mySupplement
              "
            >
              <font-awesome-icon :icon="'newspaper'" :class="'svg_icon'" />
              {{ $t("components.organisms.userNavigation.mySupplement") }}
            </span>
            <ul
              v-for="(contents, index) in mySupplementContents"
              :key="`-${index}`"
              :class="{
                accordion: true,
                'accordion-visible': isOpenAccordion.mySupplement,
              }"
            >
              <li
                class="accordion-column"
                @click="transitionToMySupplement(contents.code)"
              >
                <span class="clickable">・{{ contents.name }}</span>
              </li>
            </ul>
          </li>
          <li>
            <span
              @click="
                isOpenAccordion.workplaceSupplement =
                  !isOpenAccordion.workplaceSupplement
              "
            >
              <font-awesome-icon :icon="'newspaper'" :class="'svg_icon'" />
              {{
                $t("components.organisms.userNavigation.workplaceSupplement")
              }}
            </span>
            <ul
              v-for="(contents, index) in workplaceSupplementContents"
              :key="`-${index}`"
              :class="{
                accordion: true,
                'accordion-visible': isOpenAccordion.workplaceSupplement,
              }"
            >
              <li
                class="accordion-column"
                @click="transitionToWorkplaceSupplement(contents.code)"
              >
                <span class="clickable">・{{ contents.name }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li :class="{ 'is-active': isOpenAccordion.usersGuide }">
        <span @click="isOpenAccordion.usersGuide = !isOpenAccordion.usersGuide">
          <i class="icon__emoji"></i>
          {{ $t("components.organisms.userNavigation.userGuide") }}
        </span>
        <ul
          :class="{
            accordion: enableAccordion,
            'accordion-visible': isOpenAccordion.usersGuide,
          }"
        >
          <li
            v-if="
              !loading &&
              loginUser &&
              (loginUser.scContentsEnable || loginUser.eapContentsEnable)
            "
            @click="$emit('onClickUserInfo')"
          >
            <router-link to="/user_guide">
              <i class="icon__book"></i>
              {{
                $t("components.organisms.userNavigation.howToUseThisPlatform")
              }}
            </router-link>
          </li>
          <li
            v-if="
              !loading &&
              loginUser &&
              loginUser.eapContentsEnable &&
              (!isUserLoggedIn ||
                loginUser.scConnectionAgreement ||
                (!loginUser.scConnectionAgreement && loginUser.eapUserTable))
            "
            @click="$emit('onClickUserInfo')"
          >
            <router-link to="/qa">
              <i class="icon__chat"></i>
              {{ $t("components.organisms.userNavigation.faq") }}
            </router-link>
          </li>
        </ul>
      </li>
      <li v-if="isAdmin && !loading && loginUser && loginUser.scContentsEnable">
        <router-link to="/admin">
          <i class="icon__note--green" />
          {{ $t("components.organisms.userNavigation.admin") }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import map from "lodash/map";

export default {
  name: "UserNavigation",
  props: {
    enableAccordion: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onClickUserInfo"],
  data() {
    return {
      isOpenAccordion: {
        eap: false,
        eapSelfCare: false,
        stressCheck: false,
        column: false,
        monthlyTopics: false,
        mySupplement: false,
        workplaceSupplement: false,
        usersGuide: false,
      },
      userStressCheck: {
        examined: {},
        notExamined: {},
        onymousSelfCare: {},
        onymousSelfLearning: {},
        relatedUser: {},
      },
      topicsContents: [],
      mySupplementContents: [],
      workplaceSupplementContents: [],
    };
  },
  computed: {
    ...mapState("auth", ["loginUser", "loading"]),
    ...mapGetters("auth", ["isAccountLoggedIn", "isUserLoggedIn", "isAdmin"]),
    isPasswordReset() {
      return (
        !this.loading && this.loginUser && this.loginUser.passwordBulkReset
      );
    },
    isAccountReady() {
      return !this.loading && this.isAccountLoggedIn;
    },
  },
  watch: {
    "$i18n.locale"() {
      this.setContentsForLocale();
    },
    "loginUser.accountCode": function () {
      this.getContents();
    },
    "loginUser.id": function () {
      this.getUserStressCheck();
    },
  },
  mounted() {
    this.getUserStressCheck();
  },
  created() {
    this.getContents();
  },
  methods: {
    isEmpty(value) {
      return isEmpty(value);
    },
    getUserStressCheck() {
      // ユーザー情報が取れない状態(=ログアウト時)にgetAPIが動いて401エラーが発生しないようにチェック
      if (this.loginUser) {
        this.$api.get("/navigation/user").then((response) => {
          const stressChecks = response.data.stressChecks;
          this.userStressCheck.examined = head(stressChecks.examinedList);
          this.userStressCheck.notExamined = head(stressChecks.notExaminedList);
          this.userStressCheck.onymousSelfCare = head(
            stressChecks.onymousSelfCareList,
          );
          this.userStressCheck.onymousSelfLearning = head(
            stressChecks.onymousSelfLearningList,
          );
          this.userStressCheck.relatedUser = head(stressChecks.relatedUserList);
        });
      }
    },
    async getContents() {
      // ユーザー情報が取れない状態(=ログアウト時)にgetAPIが動いて401エラーが発生しないようにチェック
      if (this.loginUser) {
        const resTopicsContents = await this.$api.get("contents/topics");
        this.topicsContents = this.convertContentsForLocale(
          resTopicsContents.data,
        );
        const resMySupplementContents = await this.$api.get(
          "contents/my_supplement",
        );
        this.mySupplementContents = this.convertContentsForLocale(
          resMySupplementContents.data,
        );
        const resWorkplaceSupplementContents = await this.$api.get(
          "contents/workplace_supplement",
        );
        this.workplaceSupplementContents = this.convertContentsForLocale(
          resWorkplaceSupplementContents.data,
        );
      }
    },
    transitionToMonthlyTopics(contentsHeaderCode) {
      this.$router.push({
        name: "monthly_topics",
        params: {
          contentsHeaderCode: contentsHeaderCode,
        },
      });
      this.$emit("onClickUserInfo");
    },
    transitionToMySupplement(contentsHeaderCode) {
      this.$router.push({
        name: "my_supplement",
        params: {
          contentsHeaderCode: contentsHeaderCode,
        },
      });
      this.$emit("onClickUserInfo");
    },
    transitionToWorkplaceSupplement(contentsHeaderCode) {
      this.$router.push({
        name: "workplace_supplement",
        params: {
          contentsHeaderCode: contentsHeaderCode,
        },
      });
      this.$emit("onClickUserInfo");
    },
    convertContentsForLocale(contents) {
      return map(contents, (data) => {
        return {
          category: data.category,
          code: data.code,
          name: this.$i18n.locale === "ja" ? data.nameJa : data.nameEn,
          nameJa: data.nameJa,
          nameEn: data.nameEn,
        };
      });
    },
    setContentsForLocale() {
      // topicsContents
      var contents = this.convertContentsForLocale(this.topicsContents);
      this.topicsContents.splice(0, this.topicsContents.length);
      this.topicsContents.push(...contents);
      // mySupplementContents
      contents = this.convertContentsForLocale(this.mySupplementContents);
      this.mySupplementContents.splice(0, this.mySupplementContents.length);
      this.mySupplementContents.push(...contents);
      // workplaceSupplementContents
      contents = this.convertContentsForLocale(
        this.workplaceSupplementContents,
      );
      this.workplaceSupplementContents.splice(
        0,
        this.workplaceSupplementContents.length,
      );
      this.workplaceSupplementContents.push(...contents);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  position: relative;
  visibility: visible;
  display: block;
  overflow-y: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-in-out 0s;
}

.accordion-visible {
  max-height: 100%;
}

.accordion-column {
  font-size: 12px;
  padding: 0px 0px 0px 40px;
}

.clickable {
  cursor: pointer;
}

.svg_icon {
  position: absolute;
  top: 13px;
  left: 35px;
}

@media only screen and (max-width: 767px) {
  .svg_icon {
    top: 28px;
    left: 42px;
    width: 18px;
    height: 18px;
  }
}
</style>
