<template>
  <div>
    <div class="result-header">
      <personal-report-button
        :hide-personal-report-button="hidePersonalReportButton"
        :save-personal-report="savePersonalReport"
      />
      <div v-if="loadCsv && getCsvFileName" class="download-csv">
        <csv-download-button
          :load-csv="loadCsv"
          :get-file-name="getCsvFileName"
          :hide-button="hideCsvButton"
        />
      </div>
      <div v-if="optionPerPage" class="per-page-dropdown">
        <label for="sample-select">
          {{ $t("components.molecules.dataTable.sampleSelect") }}
        </label>
        <atom-select
          :model-value="perPage"
          :options="perPageOptions"
          :clearable="false"
          @update:model-value="onChangePerPage"
        />
      </div>
    </div>
    <div>
      <table class="table--primary">
        <tbody>
          <tr class="table__header">
            <th
              v-for="(column, i) in columns"
              :key="i"
              class="sort"
              @click="onClickSort(column)"
            >
              {{ column.headerLabel }}
              <font-awesome-icon
                v-if="column.sortable && column.dataKey === currentDataKey"
                :icon="currentDirection === 'asc' ? 'arrow-down' : 'arrow-up'"
              />
            </th>
          </tr>
          <tr
            v-for="(datum, i) in data"
            :key="datum.id ? datum.id : i"
            @click="onClickRow(datum)"
          >
            <td v-for="(column, j) in columns" :key="j">
              <template v-if="column.getData">
                {{ numeral(column.getData(datum), column.numeral) }}
              </template>
              <template
                v-else-if="column.dataKey && isFunction(column.isShowData)"
              >
                {{
                  column.isShowData(datum)
                    ? column.dataFormatter
                      ? column.dataFormatter(datum[column.dataKey])
                      : numeral(datum[column.dataKey], column.numeral)
                    : ""
                }}
              </template>
              <template v-else-if="column.dataKey">
                <div class="line-feed-code">
                  {{
                    column.dataFormatter
                      ? column.dataFormatter(datum[column.dataKey])
                      : numeral(datum[column.dataKey], column.numeral)
                  }}
                </div>
              </template>
              <template v-else-if="column.isButton">
                <div class="column-button">
                  <atom-button
                    v-if="
                      isFunction(column.showButton) && column.showButton(datum)
                    "
                    @click-stop="column.onClick(datum, $event)"
                  >
                    {{ column.buttonLabel }}
                  </atom-button>
                  <span
                    v-else-if="
                      isFunction(column.replaceButtonToText) &&
                      column.replaceButtonToText(datum)
                    "
                    class="replace-button-to-text"
                  >
                    {{ column.replaceButtonToText(datum) }}
                  </span>

                  <a
                    v-if="
                      column.isShowDownloadFilePDF &&
                      column.isShowDownloadFilePDF(datum)
                    "
                    @click.stop="column.downloadFilePDF(datum)"
                  >
                    <i class="icon__download"></i>
                  </a>
                  <a
                    v-if="
                      isFunction(column.isShowIconButton) &&
                      column.isShowIconButton(datum)
                    "
                    class="btn-icon"
                    @click.stop="column.onClick(datum, $event)"
                  >
                    <font-awesome-icon :icon="column.buttonIcon" />
                  </a>
                </div>
              </template>
              <template v-else-if="column.isRadioButton">
                <div v-for="(element, k) in column.elements(datum)" :key="k">
                  <label>
                    <input
                      type="radio"
                      :name="element.name"
                      :value="element.value"
                      :checked="element.checked"
                      :disabled="column.disabledRadio(datum)"
                      @change="column.onChange(datum, element)"
                    />
                    {{ element.label }}
                  </label>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="footnote" class="footnote">{{ footnote }}</div>
    <div class="outof-number js-outof">
      {{ $t("components.molecules.dataTable.outofNumberTotalBefore") }}
      <span class="outof-number--total">{{ totalCount }}</span>
      {{ $t("components.molecules.dataTable.outofNumberTotalAfter") }}
    </div>
    <nav class="pager">
      <paginate
        v-model="currentPage"
        :page-count="pageCount"
        :click-handler="onClickPage"
        :page-range="5"
        :active-class="'current'"
        :prev-text="''"
        :prev-class="'pagination__page top'"
        :prev-link-class="'icon__arrow-left'"
        :next-text="''"
        :next-class="'pagination__page end'"
        :next-link-class="'icon__arrow-right'"
        :hide-prev-next="true"
        :container-class="'pagination'"
        :page-class="'pagination__page'"
      />
    </nav>
  </div>
</template>

<script>
import i18n from "@/assets/i18n";
import mixinNumeral from "@/mixin/mixinNumeral";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";

export default {
  name: "DataTable",
  mixins: [mixinNumeral],
  props: {
    loadCsv: {
      type: Function,
      default: undefined,
    },
    savePersonalReport: {
      type: Function,
      default: undefined,
    },
    optionPerPage: {
      type: Boolean,
      default: true,
    },
    getCsvFileName: {
      type: Function,
      default: undefined,
    },
    hideCsvButton: {
      type: Boolean,
      default: false,
    },
    hidePersonalReportButton: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    defaultSortKeys: {
      type: Array,
      default: () => [],
    },
    defaultSortDirection: {
      type: String,
      default: "asc",
    },
    defaultDataKey: {
      type: String,
      default: undefined,
    },
    perPageOptions: {
      type: Array,
      default: () => [
        {
          value: 20,
          label:
            "20" +
            " " +
            i18n.global.tc("components.molecules.dataTable.perPageOptions"),
        },
        {
          value: 50,
          label:
            "50" +
            " " +
            i18n.global.tc("components.molecules.dataTable.perPageOptions"),
        },
        {
          value: 100,
          label:
            "100" +
            " " +
            i18n.global.tc("components.molecules.dataTable.perPageOptions"),
        },
      ],
    },
    perPage: {
      type: Number,
      default: 20,
    },
    page: {
      type: Number,
      default: 1,
    },
    orderBy: {
      type: String,
      default: "",
    },
    footnote: {
      type: String,
      default: undefined,
    },
  },
  emits: ["select-row", "change-table-condition"],
  data() {
    return {
      currentPage: 1,
      currentSort: this.defaultSortKeys,
      currentDirection: this.defaultSortDirection,
      currentDataKey: this.defaultDataKey,
      isFunction: isFunction,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalCount / this.perPage);
    },
  },
  watch: {
    page(newValue) {
      this.currentPage = newValue;
    },
    orderBy: {
      immediate: true,
      handler(orderBy) {
        if (!orderBy) return;

        this.currentSort = orderBy.replace(/:asc|:desc/g, "").split(",");
        this.currentDirection = orderBy.match(/asc|desc/)[0];
        const currentColumn = this.columns.find((column) => {
          if (!column.sortable) return;
          return (
            this.addDefaultSort(column.sortKeys).toString() ===
            this.currentSort.toString()
          );
        });
        this.currentDataKey = !isEmpty(currentColumn)
          ? currentColumn.dataKey
          : undefined;
      },
    },
  },
  methods: {
    onChangePerPage(value) {
      this.emitChangeTableCondition({
        perPage: Number(value),
        page: 1,
      });
    },
    onClickPage(page) {
      this.emitChangeTableCondition({ page });
    },
    onClickSort(column) {
      if (!column.sortable) return;

      this.currentDataKey = column.dataKey;

      const sortKeys = this.addDefaultSort(column.sortKeys);
      if (!sortKeys) return;

      const direction = this.getDirection(sortKeys);

      this.currentSort = sortKeys;
      this.currentDirection = direction;

      this.emitChangeTableCondition();
    },
    onClickRow(clickedRowData) {
      this.$emit("select-row", clickedRowData);
    },
    emitChangeTableCondition(changedTableCondition) {
      const { perPage, currentPage } = this;
      this.$emit("change-table-condition", {
        perPage,
        currentPage,
        orderBy: this.getOrderByString(this.currentSort, this.currentDirection),
        ...changedTableCondition,
      });
    },
    getDirection(newSort) {
      const isSortKeyChanged =
        this.currentSort.toString() != newSort.toString();

      if (isSortKeyChanged) {
        return "asc";
      } else {
        return this.currentDirection == "asc" ? "desc" : "asc";
      }
    },
    getOrderByString(sortKeys, direction) {
      return sortKeys.map((sortKey) => `${sortKey}:${direction}`).join(",");
    },
    changeSort(sortKeys, direction) {
      this.currentSort = sortKeys;
      this.currentDirection = direction;
      this.emitChangeTableCondition();
    },
    addDefaultSort(sortKeys) {
      return uniq([...sortKeys, ...this.defaultSortKeys]);
    },
  },
};
</script>

<style lang="scss">
.per-page-dropdown .vs__dropdown-toggle {
  min-width: 80px;
}
</style>
<style scoped>
.download-csv {
  cursor: pointer;
}
.board.large .result-header {
  cursor: auto;
}
.replace-button-to-text {
  width: 145px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.875rem;
  justify-content: center;
  cursor: default !important;
  border: none;
  background: transparent;
  box-shadow: none;
  display: inline-flex;
}
button + a {
  font-size: 24px;
  position: relative;
  width: 2rem;
}
button + a > i {
  position: absolute;
  bottom: 2px;
  background-size: 24px 32px;
  width: 40.5px;
  height: 32px;
  cursor: pointer;
}
.column-button {
  display: flex;
}
.footnote {
  float: right;
  display: inline-block;
  font-size: 10px;
  font-size: 0.625rem;
  color: #5d5d5d;
}
.btn-icon {
  font-size: 30px;
}
.table--primary tr td .line-feed-code {
  white-space: pre-line;
}
</style>
