<template>
  <modal v-model:is-visible="isVisible">
    <template #header>
      <slot v-if="$slots.title" name="title" />
      <template v-else>
        {{ title }}
      </template>
    </template>
    <template #body>
      <slot v-if="$slots.message" name="message" />
      <template v-else>
        {{ message }}
      </template>
    </template>
    <template #footer>
      <atom-button @click="$emit('click-button')">
        <slot v-if="$slots.button" name="button" />
        <template v-else>
          {{ $t("components.organisms.completedModal.backToIndex") }}
        </template>
      </atom-button>
    </template>
  </modal>
</template>

<script>
import i18n from "@/assets/i18n";

export default {
  name: "CompletedModal",
  props: {
    shows: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: i18n.global.tc("components.organisms.completedModal.modalTitle"),
    },
    message: {
      type: String,
      default: i18n.global.tc(
        "components.organisms.completedModal.modalMessage",
      ),
    },
  },
  emits: ["click-button", "update:shows", "close"],
  computed: {
    isVisible: {
      get() {
        return this.shows;
      },
      set(value) {
        this.$emit("update:shows", value);
        if (!value) {
          this.$emit("close", value);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
