<template>
  <validation-wrapper
    v-slot="{ validationContext }"
    :has-focus="hasFocus"
    :rules="rules"
    :messages="messages"
    :name="placeholder || 'AtomPassword'"
    :value="value"
    :is-mounted-validation="isMountedValidation"
  >
    <input
      :class="{
        'invalid-field': validationContext.errors.length > 0,
      }"
      :value="value"
      :type="visiblePassword ? 'text' : 'password'"
      :placeholder="placeholder"
      style="width: 100%; line-height: 15px"
      @focus="(e)=>{validationContext.handleChange(e);hasFocus = true;}"
      @blur="hasFocus = false"
      @input="(e)=>{validationContext.handleChange(e);onInputPassword(e);}"
    />
    <span class="field-icon" @click="onClickEyeIcon">
      <font-awesome-icon :icon="visiblePassword ? 'eye-slash' : 'eye'" />
    </span>
  </validation-wrapper>
</template>

<script>
export default {
  name: "AtomPassword",
  props: {
    value: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: "パスワード",
    },
    rules: {
      type: Object,
      default: undefined,
    },
    messages: {
      type: Object,
      default: undefined,
    },
    isMountedValidation: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  data: () => {
    return {
      hasFocus: false,
      visiblePassword: false,
    };
  },
  methods: {
    onClickEyeIcon() {
      this.visiblePassword = !this.visiblePassword;
    },
    onInputPassword(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.field-icon {
  margin-left: -25px;
  top: 2px;
  position: relative;
  z-index: 2;
}
</style>
