import isEmpty from "lodash/isEmpty";

export default {
  params: ["otherValue"],
  validate(value, [otherValue]) {
    if (isEmpty(value)) {
      return false;
    }
    return parseInt(value) <= parseInt(otherValue);
  },
};
