export default {
  // veeValidate version3 と同挙動のminを定義
  validate(value, [min]) {
    if (value == null) {
      // NULLの場合、バリデーションNGとする
      return false;
    }
    // 数値型の場合は文字列型に変換する
    let strValue = typeof value === "number" ? value.toString() : value;
    return strValue.length >= parseInt(min, 10);
  },
};
