<template>
  <div class="container">
    <div class="content" :class="{ 'full-width': isFullWidth }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    isFullWidth: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  width: 100% !important;
}
</style>
