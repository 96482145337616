<template>
  <div class="stress-bar" :data-stress="value">
    <div class="stress-bar__label">
      <span v-for="label of labels" :key="label">
        {{ label }}
      </span>
    </div>
    <ul class="stress-bar__level">
      <li v-for="level of levels" :key="level">
        {{ level }}
      </li>
    </ul>
  </div>
</template>

<script>
import range from "lodash/range";

export default {
  name: "StressBar",
  props: {
    value: {
      type: Number,
      default: 1,
      validator(value) {
        return 1 <= value && value <= 5;
      },
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    levels() {
      return range(1, 6);
    },
  },
};
</script>
