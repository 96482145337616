<template>
  <header class="user-header">
    <div v-if="isShowTitleLogo" class="user-header__logo">
      SERVICE LOGO by PEACEMIND
    </div>
    <div v-else class="user-header__text">
      {{ loginUser.siteTitle }}
    </div>
    <div class="user-info">
      <div v-show="isShowUserLanguage" class="user-language">
        <a :style="userLanguageStyleJp" @click="onClickChangeLanguage('ja')">
          日本語
        </a>
        &nbsp;|&nbsp;
        <a :style="userLanguageStyleEn" @click="onClickChangeLanguage('en')">
          English
        </a>
        &nbsp;|&nbsp;
        <a :style="userLanguageStyleZh" @click="onClickChangeLanguage('zh')">
          中文
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "StaticHeader",
  props: {
    isShowUserLanguage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      userLanguageStyleJp: {
        textDecoration: undefined,
        cursor: undefined,
      },
      userLanguageStyleEn: {
        textDecoration: undefined,
        cursor: undefined,
      },
      userLanguageStyleZh: {
        textDecoration: undefined,
        cursor: undefined,
      },
    };
  },
  computed: {
    ...mapState("auth", ["loginUser", "loading"]),
    isShowTitleLogo() {
      if (this.loginUser && this.loginUser.siteTitle) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.changeActiveLanguageLink();
  },
  methods: {
    onClickChangeLanguage(lang) {
      if (this.$i18n.locale == lang) {
        return;
      }
      this.$i18n.locale = lang;
      this.changeActiveLanguageLink();
    },
    changeActiveLanguageLink() {
      switch (this.$i18n.locale) {
        case "ja":
          this.userLanguageStyleJp.textDecoration = "none";
          this.userLanguageStyleJp.cursor = "default";
          this.userLanguageStyleEn.textDecoration = "underline";
          this.userLanguageStyleEn.cursor = "pointer";
          this.userLanguageStyleZh.textDecoration = "underline";
          this.userLanguageStyleZh.cursor = "pointer";
          break;
        case "en":
          this.userLanguageStyleJp.textDecoration = "underline";
          this.userLanguageStyleJp.cursor = "pointer";
          this.userLanguageStyleEn.textDecoration = "none";
          this.userLanguageStyleEn.cursor = "default";
          this.userLanguageStyleZh.textDecoration = "underline";
          this.userLanguageStyleZh.cursor = "pointer";
          break;
        case "zh":
          this.userLanguageStyleJp.textDecoration = "underline";
          this.userLanguageStyleJp.cursor = "pointer";
          this.userLanguageStyleEn.textDecoration = "underline";
          this.userLanguageStyleEn.cursor = "pointer";
          this.userLanguageStyleZh.textDecoration = "none";
          this.userLanguageStyleZh.cursor = "default";
          break;
        default:
          this.userLanguageStyleJp.textDecoration = "none";
          this.userLanguageStyleJp.cursor = "default";
          this.userLanguageStyleEn.textDecoration = "underline";
          this.userLanguageStyleEn.cursor = "pointer";
          this.userLanguageStyleZh.textDecoration = "underline";
          this.userLanguageStyleZh.cursor = "pointer";
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.user-info {
  width: auto;
}
.user-language {
  width: 140px;
  text-align: center;
}
.user-language > a {
  color: #5d5d5d;
  font-size: 0.75rem;
}
.user-info > div {
  display: inline-block;
}
</style>
