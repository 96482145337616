<template>
  <transition v-if="isVisible" appear name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="onClick">
        <div class="modal-window" :style="modalWindowStyle">
          <div class="modal-window__title">
            <slot name="header" />
          </div>
          <div class="modal-window__body">
            <slot name="body" />
          </div>
          <div class="modal-window__button">
            <slot name="footer" />
          </div>
          <div class="modal-window__close modal-close">
            <i class="icon__close" @click="onClick" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    isVisible: {
      type: Boolean,
      default: true,
    },
    modalWindowStyle: {
      type: String,
      default: undefined,
    },
  },
  emits: ["update:is-visible"],
  methods: {
    onClick() {
      this.$emit("update:is-visible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-window,
.modal-window--notice {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-window__body {
  margin: 20px 0;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 50vh;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
