const LoginPageAccount = "/account/login";
const LoginPageAccountHashed = /^\/[a-z0-9]{128}$/;
const LoginPageGroup = "/group/login";
const LoginPageGroupHashed = /^\/group\/[a-z0-9]{128}$/;
const LoginPageSuppliers = "/group/suppliers";
const LoginPageUser = /^\/login\/[a-z0-9]{128}$/;
const LoginPageEapUser = "/account/login/eap";
const LoginPageEapUserHashed = /^\/login\/eap\/[a-z0-9]{128}$/;
const StaticPage = /^\/static\/.*/;

/**
 * ログインが不要なページのリスト
 * String か RegExp で判定
 */
const PublicPages = Object.freeze([
  LoginPageAccount,
  LoginPageAccountHashed,
  LoginPageGroup,
  LoginPageGroupHashed,
  LoginPageSuppliers,
  LoginPageUser,
  LoginPageEapUser,
  LoginPageEapUserHashed,
  StaticPage,
  "/error",
  /^\/inquiry\/.*/,
  /^\/logout\/[a-z0-9]{128}$/,
  "/user/two_factor_authentication_complete",
  "/user/stress_check_connection_complete",
  "/user/edit/complete",
  /^\/wos_questionnaire\/.*/,
  /^\/wos_mail_stop\/.*/,
]);

const AccountPages = Object.freeze(["/", "/login", "/password_reminder/new"]);

/**
 * value が type で指定されたオブジェクトであるか判定する
 * @param {*} value 判定する値
 * @param {*} type  判定するオブジェクト
 */
function isType(value, type) {
  return Object.prototype.toString.call(value) === `[object ${type.name}]`;
}

/**
 * ページリストにあるパスか判定
 * @param {*} pages ページのリスト
 * @param {*} path  location.pathname の値
 */
function isExists(pages, path) {
  return pages.some((entry) => {
    if (isType(entry, String) && entry == path) {
      return true;
    } else if (entry.test && entry.test(path)) {
      return true;
    }
  });
}

/**
 * 取引先ログインページかを判定
 * @param {*} path $route.pathの値
 */
export function isAccountLoginPage(path) {
  return isExists([LoginPageAccount, LoginPageAccountHashed], path);
}

/**
 * ユーザログインページかを判定
 * @param {*} path $route.pathの値
 */
export function isUserLoginPage(path) {
  return isExists([LoginPageUser], path);
}

/**
 * ログインが不要なページかを判定
 * @param {*} path location.pathname の値
 */
export function isPublicPage(path) {
  return isExists(PublicPages, path);
}

/**
 * アカウントログインが必要なページかを判定
 * @param {*} path location.pathname の値
 */
export function isAccountPage(path) {
  return isExists(AccountPages, path);
}

/**
 * ユーザログインが必要なページかを判定
 * @param {*} path location.pathname の値
 */
export function isUserPage(path) {
  return !isPublicPage && !isAccountPage(path);
}
