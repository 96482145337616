<template>
  <ul class="breadcrumb">
    <li
      v-for="(value, name, index) in linkedHierarchy"
      :key="index"
      class="breadcrumb__item"
    >
      <template v-if="value.path === undefined">
        {{ value.display }}
      </template>
      <template v-else>
        <router-link :to="{ path: value.path }">
          {{ value.display }}
        </router-link>
      </template>
    </li>
  </ul>
</template>

<script>
import every from "lodash/every";
import has from "lodash/has";
import union from "lodash/union";
import find from "lodash/find";
import matches from "lodash/matches";
import isNil from "lodash/isNil";
import { mapState } from "vuex";

export default {
  name: "AdminBreadcrumb",
  props: {
    hierarchy: {
      type: Array,
      default: () => [],
      validator(value) {
        return every(value, (element) => has(element, "display"));
      },
    },
    projectCode: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState("navigation", ["admin"]),
    projectHierarchy() {
      if (isNil(this.projectCode)) {
        return [];
      }
      var project = find(
        union(
          this.admin.projects.currentOrFutureProjects,
          this.admin.projects.pastProjects,
        ),
        matches({ code: this.projectCode }),
      );
      return isNil(project)
        ? []
        : [
            {
              display: `${project.examYear}年 ${project.accountName} ${project.name}`,
            },
          ];
    },
    linkedHierarchy() {
      if (this.hierarchy.length == 0) {
        return [
          {
            display: "Home",
          },
        ];
      }
      return union(
        [
          {
            path: "/admin",
            display: "Home",
          },
        ],
        this.projectHierarchy,
        this.hierarchy,
      );
    },
  },
};
</script>
