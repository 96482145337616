export default {
  params: ["min", "max", "required"],
  validate(value, { min, max, required }) {
    // 入力値がempty
    if (!value) {
      // 必須項目の場合はエラー、任意項目の場合はエラーにしない
      return required ? false : true;
    }
    // 文字列型の場合は数値型に変換する
    let numValue = typeof value === "string" ? Number(value) : value;
    return min <= numValue && numValue <= max;
  },
};
