import numeral from "numeral";

var mixinNumeral = {
  methods: {
    numeral(value, format) {
      if (format && value != null) {
        switch (format) {
          case "decimal":
            return numeral(value).format("0.0");
          case "percent":
            return numeral(value).format("0.0") + "%";
          default:
            return value;
        }
      }
      return value;
    },
  },
};

export default mixinNumeral;
