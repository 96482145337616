<template>
  <div class="board stress-check-report swiper-slide">
    <h4 class="heading--yellow">
      {{ headerLabel }}
      <span v-if="stressCheckValue">【{{ stressCheckValue }}】</span>
    </h4>
    <div class="board-report">
      <div class="radar-graph" :style="bgImage">
        <radar-chart :chart-data="chartData" :chart-options="chartOptions" />
      </div>
      <stress-bar
        v-if="showStressBar"
        :value="stressCheckValue"
        :labels="stressBarLabels"
      />
      <div
        v-if="subject"
        class="description board-report__content--title"
        v-html="subject"
      ></div>
      <div class="description" v-html="content"></div>
      <a v-if="showButton" class="button--circle" :href="buttonLink">
        {{ buttonLabel }}
        <i class="icon__external--white"></i>
      </a>
    </div>
    <slot />
  </div>
</template>

<script>
import size from "lodash/size";

const chartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    r: {
      beginAtZero: true,
      max: 5,
      min: 0,
      angleLines: {
        color: "rgba(162, 162, 162, 1)",
      },
      grid: {
        circular: true,
        drawOnChartArea: true,
        drawBorder: false,
      },
      ticks: {
        stepSize: 1,
      },
    },
  },
  elements: {
    line: {
      borderWidth: 2,
      borderColor: "rgba(162, 162, 162, 1)",
      backgroundColor: "rgba(255, 99, 99, 0)",
    },
  },
};

const dataset = {
  label: "",
  fill: true,
  borderWidth: 1,
  backgroundColor: "rgba(0, 0, 0, 0)",
  borderColor: "rgba(255, 99, 132, 1)",
  pointBackgroundColor: "rgba(255, 99, 132, 1)",
  pointHitRadius: 1,
  data: [1, 2, 3, 4, 5, 6, 7, 8],
};

export default {
  name: "StressCheckReport",
  props: {
    headerLabel: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: false,
      default: undefined,
    },
    content: {
      type: String,
      required: true,
    },
    buttonLink: {
      type: String,
      default: undefined,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showStressBar: {
      type: Boolean,
      default: true,
    },
    stressBarLabels: {
      type: Array,
      required: false,
      default: undefined,
    },
    stressCheckValue: {
      type: Number,
      required: false,
      default: undefined,
      validator(value) {
        return 1 <= value && value <= 5;
      },
    },
    chartLabels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    chartColor: {
      type: String,
      required: true,
    },
    preData: {
      type: Array,
      default: () => [],
    },
    preChartColor: {
      type: String,
      default: "rgba(184,176,191,1)",
    },
    buttonLabel: {
      type: String,
      required: true,
    },
    imageUrl: {
      type: String,
      required: false,
      default: "",
    },
    chartLabelThisTime: {
      type: String,
      default: undefined,
    },
    chartLabelLastTime: {
      type: String,
      default: undefined,
    },
    language: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    bgImage() {
      if (!this.showStressBar) {
        if (this.language == "ENGLISH") {
          return {
            "background-image": "url(" + this.imageUrl + ")",
            "background-position": "right 5px bottom",
            "background-repeat": "no-repeat",
            "background-size": "24%",
            "padding-bottom": "30px",
          };
        } else if (this.language == "CHINESE") {
          return {
            "background-image": "url(" + this.imageUrl + ")",
            "background-position": "right 5px bottom",
            "background-repeat": "no-repeat",
            "background-size": "24%",
            "padding-bottom": "60px",
          };
        } else {
          return {
            "background-image": "url(" + this.imageUrl + ")",
            "background-position": "right 5px bottom",
            "background-repeat": "no-repeat",
            "background-size": "29%",
            "padding-bottom": "60px",
          };
        }
      } else {
        return {
          "background-image": "url(" + this.imageUrl + ")",
          "background-position": "right 5px bottom",
          "background-repeat": "no-repeat",
          "background-size": "16%",
        };
      }
    },
    chartData() {
      const {
        chartLabels,
        chartColor,
        data,
        preChartColor,
        preData,
        chartLabelThisTime,
        chartLabelLastTime,
      } = this;
      const dataArray = [
        {
          ...{
            ...dataset,
            label: size(preData) > 0 ? chartLabelThisTime : "",
            borderColor: chartColor,
            pointBackgroundColor: chartColor,
          },
          data,
        },
      ];
      if (size(preData) > 0) {
        dataArray.push({
          ...{
            ...dataset,
            label: chartLabelLastTime,
            borderColor: preChartColor,
            pointBackgroundColor: preChartColor,
            data: preData,
          },
        });
      }
      return {
        labels: chartLabels,
        datasets: dataArray,
      };
    },
    chartOptions() {
      const { preData } = this;
      return size(preData) > 0
        ? { ...chartOptions, plugins: { legend: { display: true } } }
        : chartOptions;
    },
  },
};
</script>
<style scoped>
.heading--yellow {
  max-width: 500px;
}
.button--circle {
  display: inline-block !important;
}
.description :deep(p) {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.5;
  font-feature-settings: normal;
}
</style>
