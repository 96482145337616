<template>
  <div>
    <confirm-modal
      :shows="showsConfirmDisclose"
      :title="confirmTitle"
      :ok-button-label="message.okButton"
      :cancel-button-label="message.cancelButton"
      @update:shows="showsConfirmDisclose = $event"
      @click-ok-button="onClickConfirmDiscloseOk"
      @click-cancel-button="onClickConfirmDiscloseCancel"
    >
      <newline-text tag="span" :text="message.discloseConfirmMessage" />
    </confirm-modal>
    <confirm-modal
      :shows="showsConfirmInterviewApply"
      :title="confirmTitle"
      :ok-button-label="message.okButton"
      :cancel-button-label="message.cancelButton"
      @update:shows="showsConfirmInterviewApply = $event"
      @click-ok-button="onClickConfirmInterviewApplyOk"
      @click-cancel-button="onClickConfirmInterviewApplyCancel"
    >
      <newline-text tag="span" :text="formattedApplyConfirmMessage" />
    </confirm-modal>
    <personal-mail-address-register-modal
      :shows="showsPersonalMailAddressRegister"
      :title="confirmTitle"
      :is-register="true"
      :belong-mail-address="stressCheck.mailAddress"
      :message="message"
      @update:shows="showsPersonalMailAddressRegister = $event"
      @click-register-button="onClickConfirmPersonalMailAddressRegisterOk"
      @click-cancel-button="onClickConfirmPersonalMailAddressRegisterCancel"
    />
    <completed-modal
      :shows="showsCompleteSendMail"
      @update:shows="showsCompleteSendMail = $event"
      @click-button="onClickCompleteSendMailOk"
    >
      <template #title>
        <div class="completed-modal-title"></div>
      </template>
      <template #message>
        <span
          v-html="
            replaceLineFeedCode(
              replaceCharacter(
                message.personalMailAddressSendMailStatement,
                '{changePersonalMailAddress}',
                stressCheck.changePersonalMailAddress,
              ),
            )
          "
        ></span>
      </template>
      <template #button>
        {{ message.personalMailAddressSendMailButtonOk }}
      </template>
    </completed-modal>
    <personal-mail-address-register-modal
      :shows="showsPersonalMailAddressChange"
      :title="confirmTitle"
      :is-register="false"
      :belong-mail-address="stressCheck.mailAddress"
      :personal-mail-address="stressCheck.personalMailAddress"
      :message="message"
      @update:shows="showsPersonalMailAddressChange = $event"
      @click-delete="onClickPersonalMailAddressDelete"
      @click-close-button="onClickPersonalMailAddressChangeClose"
    />
    <confirm-modal
      :shows="showsConfirmDelete"
      :title="confirmTitle"
      :ok-button-label="message.okButton"
      :cancel-button-label="message.cancelButton"
      @update:shows="showsConfirmDelete = $event"
      @click-ok-button="onClickConfirmPersonalMailAddressDeleteOk"
      @click-cancel-button="onClickConfirmPersonalMailAddressDeleteCancel"
    >
      {{ message.personalMailAddressChangeConfirmStatement }}
    </confirm-modal>
    <div id="main-content">
      <div class="container">
        <div class="content">
          <container>
            <user-breadcrumb :hierarchy="hierarchy" />
            <div class="widget">
              <div class="title--image">{{ message.breadcrumb }}</div>
              <!-- タイトルパネル -->
              <div class="board stress-check-disclosure">
                <div class="stress-check-content-header">
                  <h4 class="heading--secondary">{{ stressCheckName }}</h4>
                </div>
                <p class="stress-check-exam">
                  <span>{{ titleUserId }}</span>
                  <span>
                    <span class="nowrap">
                      <span class="definition">{{
                        titleExaminedAtByLang
                      }}</span>
                      <span>{{ examinedAtByLang }}</span>
                    </span>
                    <span class="nowrap">
                      <span class="definition">No:</span>
                      <span>{{ stressCheckValue.stressCheckId }}</span>
                    </span>
                  </span>
                </p>

                <p class="description" v-html="message.description"></p>
                <div class="board__button">
                  <atom-button
                    v-if="previousStressCheckId"
                    :is-primary="false"
                    icon="arrow"
                    icon-position="left"
                    class="button-style"
                    @click="onClickPreviousStressCheck"
                  >
                    {{ previousExamYear }}
                  </atom-button>
                  <atom-button
                    v-if="nextStressCheckId"
                    :is-primary="false"
                    icon="arrow"
                    icon-position="right"
                    class="button-style"
                    @click="onClickNextStressCheck"
                  >
                    {{ nextExamYear }}
                  </atom-button>
                </div>
              </div>

              <!-- 面接指導パネル -->
              <div
                v-if="
                  !isAdmin &&
                  (showsDiscloseResultToAdminButton ||
                    interviewDescriptionTemplate ||
                    showsInterviewApplicationButton ||
                    showsDisclosureButtonForWithdrawal ||
                    showPersonalMailAddressRegisterButton)
                "
                class="board stress-check-disclosure"
              >
                <div v-if="showsDiscloseResultToAdminButton">
                  <p>
                    {{ message.discloseButtonDescription }}
                  </p>
                </div>
                <div
                  v-if="showsDiscloseResultToAdminButton"
                  class="board__button"
                >
                  <atom-button
                    class="button-style"
                    :disabled="
                      stressCheck.disclosingResult === true ||
                      disabledDiscloseButton
                    "
                    @click="onClickDisclose"
                  >
                    {{ message.discloseButton }}
                  </atom-button>
                  <atom-button
                    :is-primary="false"
                    class="button-style"
                    :disabled="
                      stressCheck.disclosingResult === false ||
                      disabledCloseButton
                    "
                    @click="onClickDiscloseOrCloseButton(false)"
                  >
                    {{ message.closeButton }}
                  </atom-button>
                </div>

                <div v-if="interviewDescriptionTemplate">
                  <p
                    class="description"
                    v-html="interviewDescriptionTemplate.content"
                  ></p>
                </div>
                <div v-if="showsInterviewApplicationButton">
                  <p
                    class="description"
                    v-html="interviewApplicationDescriptionTemplate.content"
                  ></p>
                </div>

                <div
                  v-if="showsInterviewApplicationButton"
                  class="board__button"
                >
                  <atom-button
                    class="button--circle"
                    :disabled="!activeInterviewApplicationButton"
                    @click="onClickInterviewApply"
                    >{{ message.applyButton }}
                  </atom-button>
                  <atom-button
                    :is-primary="false"
                    class="button--circle"
                    :disabled="!activeInterviewDeclineButton"
                    @click="onClickInterviewOrDeclineApplyButton('DECLINED')"
                  >
                    {{ message.declineButton }}
                  </atom-button>
                </div>

                <div v-if="showsDisclosureButtonForWithdrawal">
                  <p
                    class="description"
                    v-html="discloseDescriptionTemplate.content"
                  ></p>
                </div>
                <div
                  v-if="showsDisclosureButtonForWithdrawal"
                  class="board__button"
                >
                  <atom-button
                    class="button--circle"
                    :disabled="
                      stressCheck.disclosingResult === true ||
                      disabledDiscloseButton
                    "
                    @click="onClickDisclose"
                  >
                    {{ message.discloseButton }}
                  </atom-button>
                  <atom-button
                    :is-primary="false"
                    class="button--circle"
                    :disabled="
                      stressCheck.disclosingResult === false ||
                      disabledCloseButton
                    "
                    @click="onClickDiscloseOrCloseButton(false)"
                  >
                    {{ message.closeButton }}
                  </atom-button>
                </div>
                <div
                  v-if="showPersonalMailAddressRegisterButton"
                  class="board__button"
                >
                  <div v-if="stressCheck.personalMailAddress == null">
                    <attom-button
                      class="button--circle"
                      @click="onClickPersonalMailAddressRegister"
                    >
                      {{ message.personalMailAddressRegisterButton }}
                    </attom-button>
                  </div>
                  <div v-if="stressCheck.personalMailAddress != null">
                    <attom-button
                      class="button--circle"
                      @click="onClickPersonalMailAddressChange"
                    >
                      {{ message.personalMailAddressChangeButton }}
                    </attom-button>
                  </div>
                </div>
              </div>

              <div
                v-if="stressCheckResultTemplate"
                class="board stress-check-decision"
              >
                <!-- templateテーブルの値を表示する -->
                <!-- 信頼できる値と判断し、v-htmlを使用する -->
                <!-- eslint-disable-next-line -->
                <p class="description" v-html="stressCheckResultTemplate.content"></p>
              </div>
              <div
                v-else-if="hasMarkSheetError"
                class="board stress-check-decision"
              >
                <!-- マークシート回答にエラーがある（高ストレスがNULL）場合 -->
                <p class="description">
                  {{ message.markSheetError }}
                </p>
              </div>
              <div class="swiper-container">
                <div class="swiper-wrapper">
                  <stress-check-report
                    :header-label="message.category1Title"
                    :subject="
                      category1ResultTemplate && category1ResultTemplate.subject
                    "
                    :content="
                      category1ResultTemplate && category1ResultTemplate.content
                    "
                    :button-link="message.category1Link"
                    :show-button="project.usingWorkaroundButton"
                    :stress-bar-labels="stressBarLabels"
                    :stress-check-value="stressCheckValue.stressProfileValue1"
                    :chart-labels="[
                      split(message.category1ChartLabel1),
                      split(message.category1ChartLabel2),
                      split(message.category1ChartLabel3),
                      split(message.category1ChartLabel4),
                      split(message.category1ChartLabel5),
                      split(message.category1ChartLabel6),
                      split(message.category1ChartLabel7),
                      split(message.category1ChartLabel8),
                      split(message.category1ChartLabel9),
                    ]"
                    :data="category1ChartData"
                    :pre-data="preCategory1ChartData"
                    :chart-color="'rgba(255, 186, 73, 1)'"
                    :button-label="message.category1Button"
                    :image-url="category1ImageUrl"
                    :chart-label-this-time="message.chartLabelThisTime"
                    :chart-label-last-time="message.chartLabelLastTime"
                  />
                  <stress-check-report
                    :header-label="message.category2Title"
                    :subject="
                      category2ResultTemplate && category2ResultTemplate.subject
                    "
                    :content="
                      category2ResultTemplate && category2ResultTemplate.content
                    "
                    :button-link="message.category2Link"
                    :show-button="project.usingWorkaroundButton"
                    :stress-bar-labels="stressBarLabels"
                    :stress-check-value="stressCheckValue.stressProfileValue2"
                    :chart-labels="[
                      split(message.category2ChartLabel1),
                      split(message.category2ChartLabel2),
                      split(message.category2ChartLabel3),
                      split(message.category2ChartLabel4),
                      split(message.category2ChartLabel5),
                      split(message.category2ChartLabel6),
                    ]"
                    :data="category2ChartData"
                    :pre-data="preCategory2ChartData"
                    :chart-color="'rgba(147, 229, 184, 1)'"
                    :button-label="message.category2Button"
                    :image-url="category2ImageUrl"
                    :chart-label-this-time="message.chartLabelThisTime"
                    :chart-label-last-time="message.chartLabelLastTime"
                  />
                  <stress-check-report
                    :header-label="message.category3Title"
                    :subject="
                      category3ResultTemplate && category3ResultTemplate.subject
                    "
                    :content="
                      category3ResultTemplate && category3ResultTemplate.content
                    "
                    :button-link="message.category3Link"
                    :show-button="project.usingWorkaroundButton"
                    :stress-bar-labels="stressBarLabels"
                    :stress-check-value="stressCheckValue.stressProfileValue3"
                    :chart-labels="[
                      split(message.category3ChartLabel1),
                      split(message.category3ChartLabel2),
                      split(message.category3ChartLabel3),
                      split(message.category3ChartLabel4),
                    ]"
                    :data="category3ChartData"
                    :pre-data="preCategory3ChartData"
                    :chart-color="'rgba(196, 119, 244, 1)'"
                    :button-label="message.category3Button"
                    :image-url="category3ImageUrl"
                    :chart-label-this-time="message.chartLabelThisTime"
                    :chart-label-last-time="message.chartLabelLastTime"
                  />
                  <stress-check-report
                    v-if="message.category4Title && showsCoping"
                    :header-label="message.category4Title"
                    :content="message.category4Description"
                    :button-link="message.category4Link"
                    :show-button="project.usingWorkaroundButton"
                    :show-stress-bar="false"
                    :chart-labels="[
                      split(message.category4ChartLabel1),
                      split(message.category4ChartLabel2),
                      split(message.category4ChartLabel3),
                      split(message.category4ChartLabel4),
                      split(message.category4ChartLabel5),
                      split(message.category4ChartLabel6),
                    ]"
                    :data="category4ChartData"
                    :pre-data="preCategory4ChartData"
                    :chart-color="'rgba(196, 119, 244, 1)'"
                    :button-label="message.category4Button"
                    :image-url="category4ImageUrl"
                    :chart-label-this-time="message.chartLabelThisTime"
                    :chart-label-last-time="message.chartLabelLastTime"
                    :language="language"
                  >
                    <table
                      class="table--graphical js-wrapper-scroll"
                      data-width="768"
                    >
                      <tr>
                        <th>{{ message.category4TableHeaderLabel }}</th>
                        <th>{{ message.category4TableHeaderDescription }}</th>
                      </tr>
                      <tr>
                        <th>{{ message.category4ChartLabel1 }}</th>
                        <td>{{ message.category4TableDescription1 }}</td>
                      </tr>
                      <tr>
                        <th>{{ message.category4ChartLabel2 }}</th>
                        <td>{{ message.category4TableDescription2 }}</td>
                      </tr>
                      <tr>
                        <th>{{ message.category4ChartLabel3 }}</th>
                        <td>{{ message.category4TableDescription3 }}</td>
                      </tr>
                      <tr>
                        <th>{{ message.category4ChartLabel4 }}</th>
                        <td>{{ message.category4TableDescription4 }}</td>
                      </tr>
                      <tr>
                        <th>{{ message.category4ChartLabel5 }}</th>
                        <td>{{ message.category4TableDescription5 }}</td>
                      </tr>
                      <tr>
                        <th>{{ message.category4ChartLabel6 }}</th>
                        <td>{{ message.category4TableDescription6 }}</td>
                      </tr>
                    </table>
                  </stress-check-report>
                </div>
                <div class="swiper-pagination"></div>
              </div>
              <div class="board stress-check-result">
                <p v-if="message.resultDescription">
                  {{ message.resultDescription }}
                </p>
              </div>
              <div
                v-if="showsStressCheckScore"
                class="board stress-check-scoring"
              >
                <p>
                  {{ message.categoryResultA }}：{{
                    stressCheckValue.basicAggregateValue1
                  }}
                  / {{ message.categoryResultB }}：{{
                    stressCheckValue.basicAggregateValue2
                  }}
                  / {{ message.categoryResultC }}：{{
                    stressCheckValue.basicAggregateValue3
                  }}
                </p>
                <div
                  v-if="paragraphMethods != null && paragraphMethods.length > 0"
                  class="paragraph-method"
                >
                  <p>{{ paragraphMethods[0] }}</p>
                  <p>{{ paragraphMethods[1] }}</p>
                  <p>{{ paragraphMethods[2] }}</p>
                </div>
                <p></p>
              </div>
            </div>
          </container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinDate from "@/mixin/mixinDate";
import status from "http-status";
import filter from "lodash/filter";
import split from "lodash/split";

export default {
  name: "StressCheckResult",
  mixins: [mixinDate],
  props: {
    stressCheckId: {
      type: Number,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    apiEndpointBase: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data: () => {
    return {
      message: {},
      stressCheck: {
        stressCheckVersion: undefined,
        disclosingResult: undefined,
        mailAddress: undefined,
        personalMailAddress: undefined,
        changePersonalMailAddress: undefined,
      },
      user: {
        loginId: undefined,
        lastName: undefined,
        firstName: undefined,
      },
      project: {
        projectName: undefined,
        examYear: undefined,
        usingWorkaroundButton: false,
      },
      stressCheckName: undefined,
      formattedApplyConfirmMessage: undefined,
      stressCheckValue: {
        stressCheckId: undefined,
        examinedAt: undefined,
      },
      preStressCheckValue: {},
      previousStressCheckId: undefined,
      previousExamYear: undefined,
      nextStressCheckId: undefined,
      language: undefined,
      nextExamYear: undefined,
      stressCheckResultTemplate: undefined,
      interviewDescriptionTemplate: undefined,
      interviewApplicationDescriptionTemplate: undefined,
      discloseDescriptionTemplate: undefined,
      category1ResultTemplate: undefined,
      category2ResultTemplate: undefined,
      category3ResultTemplate: undefined,
      stressBarLabels: [],
      category1ChartData: [],
      category2ChartData: [],
      category3ChartData: [],
      category4ChartData: [],
      preCategory1ChartData: [],
      preCategory2ChartData: [],
      preCategory3ChartData: [],
      preCategory4ChartData: [],
      hasMarkSheetError: false,
      showsDiscloseResultToAdminButton: false,
      disabledDiscloseButton: false,
      disabledCloseButton: false,
      showsInterviewApplicationButton: false,
      activeInterviewApplicationButton: false,
      activeInterviewDeclineButton: false,
      showsDisclosureButtonForWithdrawal: false,
      showPersonalMailAddressRegisterButton: false,
      showsStressCheckScore: false,
      showsCoping: false,
      showsConfirmDisclose: false,
      showsConfirmInterviewApply: false,
      showsPersonalMailAddressRegister: false,
      showsPersonalMailAddressChange: false,
      showsConfirmDelete: false,
      showsCompleteSendMail: false,
      confirmTitle: "",
      hierarchy: [
        {
          display: "",
        },
      ],
      paragraphMethods: [],
      category1ImageUrl: undefined,
      category2ImageUrl: undefined,
      category3ImageUrl: undefined,
      category4ImageUrl: undefined,
    };
  },
  computed: {
    examinedAtByLang() {
      let formatdate = "yyyy/MM/dd（E）HH:mm";
      if (this.language == "ENGLISH") {
        formatdate = "yyyy/MM/dd";
      } else if (this.language == "CHINESE") {
        formatdate = "yyyy/MM/dd（E）";
      }
      return this.formatDateByLanguage(
        this.stressCheckValue.examinedAt,
        formatdate,
        this.language,
      );
    },
    titleExaminedAtByLang() {
      switch (this.language) {
        case "ENGLISH":
          return "Date of Stress Check test";
        case "CHINESE":
          return "测试时间：";
        default:
          return "受検日時：";
      }
    },
    titleUserId() {
      if (this.user.loginId != null) {
        switch (this.language) {
          case "ENGLISH":
            return (
              this.user.loginId +
              "　" +
              this.join(["Your name:", this.user.firstName, this.user.lastName])
            );
          case "CHINESE":
            return (
              this.user.loginId +
              "　" +
              this.join([this.user.lastName, this.user.firstName])
            );
          default:
            return (
              this.user.loginId +
              "　" +
              this.join([this.user.lastName, this.user.firstName]) +
              "様"
            );
        }
      }
      return "";
    },
  },
  watch: {
    $route: function () {
      this.loadData();
    },
  },
  async created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const response = await this.$api.get(
          `${this.apiEndpointBase}/${this.$route.params.stressCheckId}`,
          {
            params: {
              lang: this.$route.query.lang,
            },
          },
        );

        this.mapStrtessCheckResultData(response);
      } catch (error) {
        if (error.response.status === status.BAD_REQUEST) {
          this.$router.push("/not_published");
        }
      }
    },
    mapStrtessCheckResultData({ data }) {
      this.message = data.message;
      this.hierarchy[0].display = this.message.breadcrumb;
      this.stressCheck = data.stressCheck;
      this.user = data.user;
      this.project = data.project;
      this.stressCheckName = data.stressCheckName;
      this.language = data.language;
      this.stressCheckValue = data.stressCheckValue;
      this.stressCheckResultTemplate = data.stressCheckResultTemplate;
      this.preStressCheckValue = data.preStressCheckValue;
      this.formattedApplyConfirmMessage = data.formattedApplyConfirmMessage;
      if (data.previousStressCheck) {
        this.previousStressCheckId = data.previousStressCheck.stressCheckId;
        this.previousExamYear = data.previousStressCheck.examYear;
      } else {
        this.previousStressCheckId = undefined;
        this.previousExamYear = undefined;
      }
      if (data.nextStressCheck) {
        this.nextStressCheckId = data.nextStressCheck.stressCheckId;
        this.nextExamYear = data.nextStressCheck.examYear;
      } else {
        this.nextStressCheckId = undefined;
        this.nextExamYear = undefined;
      }
      this.interviewDescriptionTemplate = data.interviewDescriptionTemplate;
      this.interviewApplicationDescriptionTemplate =
        data.interviewApplicationDescriptionTemplate;
      this.discloseDescriptionTemplate = data.discloseDescriptionTemplate;
      this.category1ResultTemplate = data.category1ResultTemplate;
      this.category2ResultTemplate = data.category2ResultTemplate;
      this.category3ResultTemplate = data.category3ResultTemplate;
      this.hasMarkSheetError = data.hasMarkSheetError;
      this.showsDiscloseResultToAdminButton =
        data.showsDiscloseResultToAdminButton;
      this.disabledDiscloseButton = data.disabledDiscloseButton;
      this.disabledCloseButton = data.disabledCloseButton;
      this.showsInterviewApplicationButton =
        data.showsInterviewApplicationButton;
      this.activeInterviewApplicationButton =
        data.activeInterviewApplicationButton;
      this.activeInterviewDeclineButton = data.activeInterviewDeclineButton;
      this.showsDisclosureButtonForWithdrawal =
        data.showsDisclosureButtonForWithdrawal;
      this.showPersonalMailAddressRegisterButton =
        data.showPersonalMailAddressRegisterButton;
      this.showsStressCheckScore = data.showsStressCheckScore;
      this.showsCoping = data.showsCoping;
      this.paragraphMethods = data.paragraphMethods;

      this.stressBarLabels = [
        this.message.stressLevelHigh,
        this.message.stressLevel,
        this.message.stressLevelLow,
      ];
      this.category1ChartData = [
        this.formatNull(this.stressCheckValue.stressProfileValue4),
        this.formatNull(this.stressCheckValue.stressProfileValue5),
        this.formatNull(this.stressCheckValue.stressProfileValue6),
        this.formatNull(this.stressCheckValue.stressProfileValue7),
        this.formatNull(this.stressCheckValue.stressProfileValue8),
        this.formatNull(this.stressCheckValue.stressProfileValue9),
        this.formatNull(this.stressCheckValue.stressProfileValue10),
        this.formatNull(this.stressCheckValue.stressProfileValue11),
        this.formatNull(this.stressCheckValue.stressProfileValue12),
      ];

      this.category2ChartData = [
        this.formatNull(this.stressCheckValue.stressProfileValue13),
        this.formatNull(this.stressCheckValue.stressProfileValue14),
        this.formatNull(this.stressCheckValue.stressProfileValue15),
        this.formatNull(this.stressCheckValue.stressProfileValue16),
        this.formatNull(this.stressCheckValue.stressProfileValue17),
        this.formatNull(this.stressCheckValue.stressProfileValue18),
      ];

      this.category3ChartData = [
        this.formatNull(this.stressCheckValue.stressProfileValue19),
        this.formatNull(this.stressCheckValue.stressProfileValue20),
        this.formatNull(this.stressCheckValue.stressProfileValue21),
        this.formatNull(this.stressCheckValue.stressProfileValue22),
      ];

      this.category4ChartData = [
        this.formatNull(this.stressCheckValue.scoreAggregateValue52),
        this.formatNull(this.stressCheckValue.scoreAggregateValue53),
        this.formatNull(this.stressCheckValue.scoreAggregateValue54),
        this.formatNull(this.stressCheckValue.scoreAggregateValue55),
        this.formatNull(this.stressCheckValue.scoreAggregateValue56),
        this.formatNull(this.stressCheckValue.scoreAggregateValue57),
      ];

      this.preCategory1ChartData = this.preStressCheckValue
        ? [
            this.preStressCheckValue.stressProfileValue4,
            this.preStressCheckValue.stressProfileValue5,
            this.preStressCheckValue.stressProfileValue6,
            this.preStressCheckValue.stressProfileValue7,
            this.preStressCheckValue.stressProfileValue8,
            this.preStressCheckValue.stressProfileValue9,
            this.preStressCheckValue.stressProfileValue10,
            this.preStressCheckValue.stressProfileValue11,
            this.preStressCheckValue.stressProfileValue12,
          ]
        : [];

      this.preCategory2ChartData = this.preStressCheckValue
        ? [
            this.preStressCheckValue.stressProfileValue13,
            this.preStressCheckValue.stressProfileValue14,
            this.preStressCheckValue.stressProfileValue15,
            this.preStressCheckValue.stressProfileValue16,
            this.preStressCheckValue.stressProfileValue17,
            this.preStressCheckValue.stressProfileValue18,
          ]
        : [];

      this.preCategory3ChartData = this.preStressCheckValue
        ? [
            this.preStressCheckValue.stressProfileValue19,
            this.preStressCheckValue.stressProfileValue20,
            this.preStressCheckValue.stressProfileValue21,
            this.preStressCheckValue.stressProfileValue22,
          ]
        : [];

      this.preCategory4ChartData = this.preStressCheckValue
        ? [
            this.preStressCheckValue.scoreAggregateValue52,
            this.preStressCheckValue.scoreAggregateValue53,
            this.preStressCheckValue.scoreAggregateValue54,
            this.preStressCheckValue.scoreAggregateValue55,
            this.preStressCheckValue.scoreAggregateValue56,
            this.preStressCheckValue.scoreAggregateValue57,
          ]
        : [];

      switch (this.language) {
        case "ENGLISH":
          this.category1ImageUrl = require("@/assets/img/pc/stress_value_arrow_en.png");
          this.category2ImageUrl = require("@/assets/img/pc/stress_value_arrow_en.png");
          this.category3ImageUrl = require("@/assets/img/pc/support_value_arrow_en.png");
          this.category4ImageUrl = require("@/assets/img/pc/copeable_value_arrow_en.png");
          break;
        case "CHINESE":
          this.category1ImageUrl = require("@/assets/img/pc/stress_value_arrow_zh.png");
          this.category2ImageUrl = require("@/assets/img/pc/stress_value_arrow_zh.png");
          this.category3ImageUrl = require("@/assets/img/pc/support_value_arrow_zh.png");
          this.category4ImageUrl = require("@/assets/img/pc/copeable_value_arrow_zh.png");
          break;
        default:
          this.category1ImageUrl = require("@/assets/img/pc/stress_value_arrow.png");
          this.category2ImageUrl = require("@/assets/img/pc/stress_value_arrow.png");
          this.category3ImageUrl = require("@/assets/img/pc/support_value_arrow.png");
          this.category4ImageUrl = require("@/assets/img/pc/copeable_value_arrow.png");
      }
    },
    onClickPreviousStressCheck() {
      if (!this.previousStressCheckId) return;

      this.$router.push(
        `${this.apiEndpointBase}/${this.previousStressCheckId}`,
      );
    },
    onClickNextStressCheck() {
      if (!this.nextStressCheckId) return;

      this.$router.push(`${this.apiEndpointBase}/${this.nextStressCheckId}`);
    },
    onClickDisclose() {
      this.showsConfirmDisclose = true;
    },
    onClickConfirmDiscloseOk() {
      this.showsConfirmDisclose = false;
      this.onClickDiscloseOrCloseButton(true);
    },
    onClickConfirmDiscloseCancel() {
      this.showsConfirmDisclose = false;
    },
    onClickDiscloseOrCloseButton(disclosingResult) {
      const { stressCheckId } = this.$route.params;
      return this.$api
        .put(`${this.apiEndpointBase}/${stressCheckId}/disclosing_result`, {
          disclosingResult,
          stressCheckVersion: this.stressCheck.stressCheckVersion,
          lang: this.$route.query.lang,
        })
        .then((response) => {
          this.stressCheck = { ...this.stressCheck, ...response.data };
          this.discloseDescriptionTemplate =
            response.data.discloseDescriptionTemplate;
          this.disabledCloseButton = !response.data.disclosingResult;
          // ボタンの活性状態の再読み込みを実施
          this.loadData();
        });
    },
    onClickInterviewApply() {
      this.showsConfirmInterviewApply = true;
    },
    onClickConfirmInterviewApplyOk() {
      this.showsConfirmInterviewApply = false;
      this.onClickInterviewOrDeclineApplyButton("APPLIED");
    },
    onClickConfirmInterviewApplyCancel() {
      this.showsConfirmInterviewApply = false;
    },
    onClickInterviewOrDeclineApplyButton(interviewApplicationStatus) {
      const { stressCheckId } = this.$route.params;
      const { stressCheck, project } = this;

      return this.$api
        .put(
          `${this.apiEndpointBase}/${stressCheckId}/interview_application_status`,
          {
            interviewApplicationStatus,
            interviewApplicationStatusTemp: interviewApplicationStatus,
            stressCheckVersion: stressCheck.stressCheckVersion,
            projectCode: project.projectCode,
          },
        )
        .then((response) => {
          this.stressCheck.stressCheckVersion =
            response.data.stressCheckVersion;
          this.activeInterviewApplicationButton =
            response.data.activeInterviewApplicationButton;
          this.activeInterviewDeclineButton =
            response.data.activeInterviewDeclineButton;
          // Refresh data to update the messages
          this.loadData();
        });
    },
    onClickPersonalMailAddressRegister() {
      this.showsPersonalMailAddressRegister = true;
    },
    onClickPersonalMailAddressChange() {
      this.showsPersonalMailAddressChange = true;
    },
    onClickConfirmPersonalMailAddressRegisterOk(saveAddress) {
      const { stressCheckId } = this.$route.params;
      this.showsPersonalMailAddressRegister = false;
      this.stressCheck.changePersonalMailAddress = saveAddress;
      this.showsCompleteSendMail = true;
      return this.$api.post(
        `${this.apiEndpointBase}/${stressCheckId}/personal_mail_address/change`,
        {
          changePersonalMailAddress: saveAddress,
          lang: this.$route.query.lang,
        },
      );
    },
    onClickConfirmPersonalMailAddressRegisterCancel() {
      this.showsPersonalMailAddressRegister = false;
    },
    onClickPersonalMailAddressDelete() {
      this.showsPersonalMailAddressChange = false;
      this.showsConfirmDelete = true;
    },
    onClickPersonalMailAddressChangeClose() {
      this.showsPersonalMailAddressChange = false;
    },
    onClickCompleteSendMailOk() {
      this.showsCompleteSendMail = false;
    },
    onClickConfirmPersonalMailAddressDeleteOk() {
      const { stressCheckId } = this.$route.params;
      this.showsConfirmDelete = false;
      this.showsPersonalMailAddressRegister = true;
      return this.$api
        .post(
          `${this.apiEndpointBase}/${stressCheckId}/personal_mail_address/delete`,
          {
            changePersonalMailAddress: this.stressCheck.personalMailAddress,
          },
        )
        .then(() => {
          this.loadData();
        });
    },
    onClickConfirmPersonalMailAddressDeleteCancel() {
      this.showsConfirmDelete = false;
    },
    join(values) {
      return filter(values).join(" ");
    },
    formatNull(value) {
      return value ? value : 0;
    },
    split(value) {
      return split(value, "\n");
    },
    replaceLineFeedCode(targetData) {
      if (targetData) {
        return targetData.replace(/\n/g, "<br>");
      }
      return targetData;
    },
    replaceCharacter(targetData, character, newCaracter) {
      if (targetData) {
        return targetData.replace(character, newCaracter);
      }
      return targetData;
    },
  },
};
</script>

<style scoped>
.swiper-wrapper {
  flex-wrap: wrap;
}
.stress-check-exam {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.stress-check-exam .nowrap {
  margin-right: 10px;
  white-space: nowrap;
  display: inline-flex;
}
.stress-check-exam .definition {
  font-weight: bold;
  margin-right: 10px;
}
.button-style {
  width: 210px;
}
.button--circle {
  width: auto;
  min-width: 210px;
}
.description ::v-deep(p) {
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 1.5;
  font-feature-settings: normal;
}
.board.stress-check-decision {
  padding: 24px;
}
.board.stress-check-decision p {
  max-width: 100%;
}
.stress-check-content-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 800px;
  align-items: center;
}
.stress-check-content-header > p {
  margin: 0px;
}
.stress-check-content-header > p > span:last-child {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .stress-check-content-header {
    flex-wrap: wrap;
  }
  .board.stress-check-disclosure .stress-check-content-header > p {
    text-align: left;
  }
}
.paragraph-method {
  margin-top: 10px;
}
</style>
