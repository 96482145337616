<template>
  <img
    v-if="type == 'PROCESSING'"
    :key="type"
    src="@/assets/img/file_queue_symbol/processing.gif"
  />
  <img
    v-else-if="type == 'PENDING'"
    :key="type"
    src="@/assets/img/file_queue_symbol/pending.gif"
  />
</template>
<script>
export default {
  name: "FileQueueSymbol",
  props: {
    type: {
      type: String,
      default: "NONE",
    },
  },
};
</script>
